const getUrlParams = () => {
  const params = {};
  let url = window.location.search.substring(1);
  url = decodeURIComponent(url);
  if (url.length > 0) {
    url.split('&').forEach(i => {
      const tmp = i.split('=');

      params[tmp[0]] = tmp[1];
    });
  }

  return params;
};

export default getUrlParams;
