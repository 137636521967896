/* eslint-disable */

import React, { useEffect, useRef } from 'react';
import './index.scss';

let tvScriptLoadingPromise;

export default function TradingViewWidget({symbol, height, width = '100%'}) {
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById(symbol) && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: width,
                        height: height,
                        symbol: symbol,
                        interval: "D",
                        timezone: "Etc/UTC",
                        theme: "light",
                        style: "1",
                        locale: "ru",
                        enable_publishing: false,
                        allow_symbol_change: true,
                        container_id: symbol
                    });
                }
            }
        },
        []
    );

    return (
        <div className='tradingview-widget-container'>
            <div id={symbol} />
        </div>
    );
}
