import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Icon } from '../../../../common/icon';

export const Page404 = () => {
  return (
    <Container
      fluid
      className="flex flex-col min-h-full items-center justify-center mt-16"
    >
      <div className="text-5xl mb-5">Ошибка #404</div>
      <p>Страница не найдена или перемещна по другому адресу</p>

      <Row className="mt-10">
        <Col>
          <a
            href="https://www.instagram.com/rsi.capital/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="instagramFill" size={24} />
          </a>
        </Col>
        <Col>
          <a href="https://t.me/rsi_capital" target="_blank" rel="noreferrer">
            <Icon name="telegramFill" size={24} />
          </a>
        </Col>
        <Col>
          <a href="https://vk.com/rsi_capital" target="_blank" rel="noreferrer">
            <Icon name="vkontakteFill" size={24} />
          </a>
        </Col>
        <Col>
          <a
            href="https://www.youtube.com/channel/UCXNraS_3Zd6z-FUpcGYTZ9g"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="youtubeFill" size={24} />
          </a>
        </Col>
      </Row>
    </Container>
  );
};
