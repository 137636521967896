/* eslint-disable */
import {createChart, ColorType, PriceScaleOptions, PriceScaleMode} from 'lightweight-charts';
import React, {useEffect, useRef, useState} from 'react';

export const ChartComponent = (props) => {

    const [graphPage, setGraphPage] = useState(null)
    const [timeGraph, setTimeGraph] = useState(null)

    const {
        timeSwitcher,
        setTimeSwitcher,
        setVisibleButton,
        data,
        isUpdate,
        updateValue,
        updateTime
    } = props;
    const backgroundColor = 'white';
    const lineColor = '#2962FF';
    const textColor = 'black';
    const areaTopColor = '#adbff8';
    const areaBottomColor = 'rgb(221,229,246, 0.3)';

    const chartContainerRef = useRef();
    const lineSeries = useRef(null);

    useEffect(() => {
        if (lineSeries.current === null) {
            return;
        }

        if (updateValue === null) {
            return;
        }

        let next = {
            time: updateTime,
            value: updateValue,
        };

        console.log(next);
        lineSeries.current.update(next);

    },[updateTime, updateValue])


    const myVisibleLogicalRangeChangeHandler = (newVisibleLogicalRange, chart) => {

        if (newVisibleLogicalRange === null) {
            return
        }

        setVisibleButton(chart.timeScale().getVisibleLogicalRange())
        setGraphPage(chart.timeScale().getVisibleLogicalRange())
        setTimeGraph(chart.timeScale().getVisibleRange())
        // if(newVisibleLogicalRange.from === 0) return
            // if (newVisibleLogicalRange.from < 0.5) {
                // getPriceGraph()
        // }
    }

    useEffect(() =>  {
            const handleResize = () => {
                chart.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                });
            };
            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {type: ColorType.Solid, color: backgroundColor},
                    textColor,
                },
                width: chartContainerRef.current.clientWidth,
                height: 300,
            });

            // chart.timeScale().scrollToRealTime()

            chart.timeScale()
            chart.timeScale()
                .applyOptions({
                    fixLeftEdge: true,
                    fixRightEdge: true,
                    lockVisibleTimeRangeOnResize: false,
                    shiftVisibleRangeOnNewBar: false,
                    // barSpacing: 20,
                    timeVisible: true})

            // chart.timeScale().unsubscribeVisibleLogicalRangeChange(myVisibleLogicalRangeChangeHandler);

            // addAreaSeries
            // addLineSeries
            lineSeries.current = chart.addAreaSeries({
                lineColor,
                topColor: areaTopColor,
                bottomColor: areaBottomColor,
                lineWidth: 2,
                // below: 40,
                lastValueVisible: false,
                priceFormat: {
                    precision: 4,
                    minMove: 0.0001,
                }
            });

            lineSeries.current.setData(data)
            chart.timeScale().subscribeVisibleLogicalRangeChange((e) => myVisibleLogicalRangeChangeHandler(e, chart));


        // при догрузке данных возвращается к началу
            if(!timeSwitcher){
            if(graphPage !== null){
                // chart.timeScale().setVisibleLogicalRange({from: graphPage.to + newDataArray, to: data.length-1})
                chart.timeScale().setVisibleRange({from: data[parseInt((data.length-1)/2)].time, to: data[data.length-1].time})
            }
            }else {
                chart.timeScale().setVisibleRange({from: timeGraph.from, to: timeGraph.to})
            //     chart.timeScale().setVisibleLogicalRange({from: (data.length-1)/2, to: (data.length-1)})
            //

                setGraphPage(null)
                setTimeSwitcher(false)
            }

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        },
        [isUpdate]
        // [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div ref={chartContainerRef}/>
    );
};
