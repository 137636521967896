import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import partnersImg from '../../../assets/images/315.jpg';

export const WelcomeBlock = () => {
  return (
    <Card>
      <CardBody className="card-body">
        <Row className="xl:flex-none xl:flex-row flex flex-col-reverse items-center">
          <Col lg={12} xl={6}>
            <p className="sm:text-xl text-md ">Уважаемые друзья!</p>
            <br />
            <p className="sm:text-xl text-md ">
              Добро пожаловать в ваш личный кабинет - сердце платформы RSI
              Capital.
              <br /> <b>Важно:</b> мы создали подробные видеоинструкции для
              того, чтобы вы пользовались кабинетом максимально эффективно.
            </p>

            <p className="sm:text-lg text-sm mt-8">
              Пожалуйста,{' '}
              <b>
                внимательно изучите материал перед покупкой или инвестированием.
              </b>
            </p>
          </Col>

          <Col sm={6} xl={6}>
            <img src={partnersImg} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
WelcomeBlock.propTypes = {};
