import React from 'react';
import { Container } from 'reactstrap';
import { Icon } from '../icon';
import './index.scss';

export const PageFooter = () => {
  return (
    <div id="page-footer" className="py-3 md:px-4">
      <Container fluid>
        <div className="row items-center justify-between">
          <div className="col-auto">
            {/* <div>Copyright 2020 © RSI</div> */}
          </div>

          <div className="mt-2 sm:mt-0 col-sm-auto order-last sm:order-none">
            <div className="social-links row -mx-2">
              <div className="px-2 col-auto">
                <a
                  className="icon link sk flex items-center justify-center icon tg"
                  href="https://t.me/rsi_capital"
                >
                  <Icon color="#fff" size={16} name="telegramFill" />
                </a>
              </div>

              <div className="px-2 col-auto">
                <a
                  className="icon link sk flex items-center justify-center icon vk"
                  href="https://vk.com/rsi_capital"
                >
                  <Icon color="#fff" size={16} name="vkontakteFill" />
                </a>
              </div>

              <div className="px-2 col-auto">
                <a
                  className="icon link inst flex items-center justify-center icon vk"
                  href="https://www.instagram.com/rsi.capital/"
                >
                  <Icon color="#fff" size={16} name="instagramFill" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-auto">
            {/* Сделано в{' '}
            <a href="https://wocom.biz/" rel="noreferrer" target="_blank">
              <u>Wocom</u>
            </a> */}
          </div>
        </div>
      </Container>
    </div>
  );
};
