import React from 'react';
import { Container, Row } from 'reactstrap';
import { PageHeader } from '../../../common/pageHeader';
import { WelcomeBlock } from '../../../common/welcome';
import { InstructionMoney } from './instructionMoney';
import { ReviewLkVideo } from './reviewLkVideo';
import './styles.scss';
// import { PromoTurkey } from '../../../common/Promo/Turkey';

export const HomePage = () => {
  return (
    <Container fluid>
      <Row>
        <PageHeader title="Главная" />

        <div className="col-12">
          <WelcomeBlock />
        </div>

        {/*<div className="col-12">*/}
        {/*  <PromoTurkey />*/}
        {/*</div>*/}

        <div className="col-12 col-md-6">
          <ReviewLkVideo />
        </div>

        <div className="col-12 col-md-6">
          <InstructionMoney />
        </div>
      </Row>
    </Container>
  );
};
