/* eslint-disable */
import React, {useEffect} from 'react';
import useUrl from "../../../../hooks/useUrl";
import {apiGet} from "../../../../api";
import {updateProfile} from "../../../../store/user/actions";
import {appLogin} from "../../../../store/app/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

export const LoginByOneTimeToken = () => {
    const { token } = useUrl();

    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        apiGet({url: `/auth/login-by-one-time-token?token=${token}`}).then(res => {
            if (res?.error) {
                history.push('/login');
            } else {
                dispatch(updateProfile(res.data.profile));
                dispatch(appLogin(res.data.access_token));
                history.push('/');
            }
        });
    }, []);

    return (<></>);
}