import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import active from '../../../assets/images/course/active.jpg';
import portfel from '../../../assets/images/course/portfel.jpg';
import reasonable from '../../../assets/images/course/reasonable.jpg';
import { Icon } from '../icon';

const IMGS = {
  1: reasonable,
  2: active,
  3: portfel,
};

export const LessonBlock = ({ data, open }) => {
  const { name, des, video, files } = data;
  const { id } = useParams();

  const openVideo = () => {
    open(video);
  };

  const renderFile = file => {
    return (
      <div className="col-sm-6 px-2" key={file.name}>
        <a
          download
          href={file.path}
          className="mb-0 mt-4 flex items-center card p-3 bg-purpleDark text-white"
        >
          <Icon name="pdfIcon" size={26} className="mr-2" color="#fff" />

          <span className="text-base">{file.name}</span>
        </a>
      </div>
    );
  };

  return (
    <Card className="p-4">
      <div className="row">
        <div className="col-xl-5">
          <div className="sm:text-2xl text-lg font-medium">{name}</div>

          <div
            className="content mt-2"
            dangerouslySetInnerHTML={{ __html: des }}
          />

          {files?.length > 0 && (
            <div className="row -mx-2">{files.map(renderFile)}</div>
          )}
        </div>

        <div className="mt-2 xl:mt-0 col-xl-7">
          <button onClick={openVideo} className="relative ">
            <img className="rounded-md" alt="" src={IMGS[+id] || null} />

            <span className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark w-24 h-16 md:w-40 md:h-24 rounded-xl flex items-center justify-center">
              <Icon name="playButton" size={40} color="#fff" />
            </span>
          </button>
        </div>
      </div>
    </Card>
  );
};

LessonBlock.propTypes = {
  data: PropTypes.object,
  open: PropTypes.func,
};
