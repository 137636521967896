import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LESSON_CATEGORY_LIST } from '../../../../global/lessonCategory';
import { CustomCard } from '../../../common/customCard';

export const TrainingPage = () => {
  const renderEducationView = data => {
    return (
      <Col key={data?.id} sm={12} md={6} lg={4} className="">
        <CustomCard
          name={data.name}
          img={data?.img}
          des={data?.text}
          id={String(data?.id)}
        />
      </Col>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="sm:text-2xl text-lg font-medium my-8">Обучение</div>
        </Col>
      </Row>

      <Row>{LESSON_CATEGORY_LIST.map(renderEducationView)}</Row>
    </Container>
  );
};
