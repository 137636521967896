/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { apiGet, apiPost } from '../../../../../api';
import passportImg from '../../../../../assets/images/passport.jpg';
import { updateProfile } from '../../../../../store/user/actions';
import { Button } from '../../../../common/ui/button';
import './index.scss';

export const ProfileVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [file1, setFile1] = useState(null);
  const [preview1, setPreview1] = useState('');
  const [file2, setFile2] = useState(null);
  const [preview2, setPreview2] = useState('');
  const [file3, setFile3] = useState(null);
  const [preview3, setPreview3] = useState('');
  const [errText, setErrText] = useState('');
  const [alreadySend, setAlreadySend] = useState(false);

  const handleExtractUrl = () => {
    if (file1) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview1(reader.result);
      };
      reader.readAsDataURL(file1);
    }
    if (file2) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview2(reader.result);
      };
      reader.readAsDataURL(file2);
    }
    if (file3) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview3(reader.result);
      };
      reader.readAsDataURL(file3);
    } else {
      setPreview1('');
      setPreview2('');
      setPreview3('');
    }
  };

  const sumbitForReview = () => {
    let formData = new FormData();
    if (file1) {
      formData.append('passport1', file1);
    }

    if (file2) {
      formData.append('passport2', file2);
    }

    if (file3) {
      formData.append('selfie', file3);
    }

    apiPost({
      url: '/profile/upload-passport',
      postData: formData,
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrText(res.data);
        }
        if (!res.error) {
          apiGet({
            url: '/profile',
          }).then(res => {
            if (res) {
              dispatch(updateProfile(res.data));
              history.push('/profile');
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    handleExtractUrl();
  }, [file1, file2, file3]);

  useEffect(() => {
    apiGet({
      url: '/profile',
    }).then(res => {
      if (res.error === false) {
        setAlreadySend(res.data.verifyStatus == 3 || res.data.verifyStatus == 4);
      }
    });
  }, []);

  const goToProfile = () => {
    history.push('/profile');
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <Card className="mt-7">
            <CardHeader>
              <div className="sm:text-2xl text-lg font-medium">
                Верификация личности
              </div>
            </CardHeader>

            {alreadySend
                ?
            <CardHeader style={{marginTop: '10px'}}>
              <div className="sm:text-2xl text-lg font-medium" style={{color: 'green'}}>Вы уже отправили данные на проверку</div>
              <div style={{marginTop: '10px'}}>
                <Button color="primary" onClick={goToProfile}>Перейти в профиль</Button>
              </div>
            </CardHeader>
                :
            <CardBody>
              <Row>
                <Col lg={6}>
                  <img className="lozad" src={passportImg}/>
                </Col>
                <Col lg={6}>
                  <form onSubmit={handleSubmit(sumbitForReview)}>
                    <label
                        className="flex items-center justify-between border-2 border-dashed block mb-6 rounded-lg p-2 pl-3 cursor-pointer relative">
                      <span className="text-lg">Скан первой страницы</span>
                      <span className="w-12 h-12 bg-purple rounded-lg block">
                        <img
                            src={preview1 || null}
                            className="image-cover rounded-lg"
                        />
                      </span>
                      <input
                          accept="image/*"
                          type="file"
                          onChange={e => setFile1(e.target.files[0])}
                          name="file1"
                          className="hidden"
                      />
                    </label>

                    <label
                        className="flex items-center justify-between border-2 border-dashed block mb-6 rounded-lg p-2 pl-3 cursor-pointer relative">
                      <span className="text-lg">Скан страницы прописки</span>

                      <span className="w-12 h-12 bg-purple rounded-lg block">
                        <img
                            src={preview2 || null}
                            className="image-cover rounded-lg"
                        />
                      </span>

                      <input
                          accept="image/*"
                          type="file"
                          onChange={e => setFile2(e.target.files[0])}
                          name="file2"
                          className="hidden"
                      />
                    </label>

                    <label
                        className="flex items-center justify-between border-2 border-dashed block mb-6 rounded-lg p-2 pl-3 cursor-pointer relative">
                      <span className="text-lg">
                        Селфи с раскрытым паспортом
                      </span>

                      <span className="w-12 h-12 bg-purple rounded-lg block">
                        <img
                            src={preview3 || null}
                            className="image-cover rounded-lg"
                        />
                      </span>

                      <input
                          accept="image/*"
                          onChange={e => setFile3(e.target.files[0])}
                          type="file"
                          name="file3"
                          className="hidden"
                      />
                    </label>

                    <Button color="primary">Отправить на проверку</Button>
                    <br/><br/>
                    {errText && <div className="error">{errText}</div>}
                  </form>
                </Col>
              </Row>
            </CardBody>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
