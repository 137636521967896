import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { appReducer } from './app/reducer';
import { userReducer } from './user/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = createStore(
  combineReducers({
    app: appReducer,
    user: userReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);
