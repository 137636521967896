import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { Icon } from '../../../common/icon';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { AuthLayout } from '../../../layouts/auth';

export const PassRecoverPage = ({ history }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = () => {};

  const goBack = () => {
    history.goBack();
  };

  return (
    <AuthLayout>
      <Container>
        <Row className="justify-center">
          <Col md="5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className="mt-20">
                <CardHeader className="py-3 text-2xl text-center font-medium">
                  <Row className="items-center">
                    <Col xs="auto" className="pr-2">
                      <button className="transform rotate-90" onClick={goBack}>
                        <Icon name="chevronDown" />
                      </button>
                    </Col>
                    <Col xs="auto">Восстановить пароль</Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Input
                    name="email"
                    register={register}
                    label="E-Mail"
                    type="mail"
                  />
                </CardBody>

                <CardFooter>
                  <Button color="primary">Отправить письмо</Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </AuthLayout>
  );
};
PassRecoverPage.propTypes = {
  history: PropTypes.object,
};
