/* eslint-disable */
import React from 'react';
import Countdown, {zeroPad} from 'react-countdown';
import './index.scss';

export const Timer = ({millisecondsCount, completeText = 'Время вышло', fontSize = 20}) => {
    // Random component
    const Completionist = () => {
        return <span className='completionist' style={{fontSize: fontSize}}>{completeText}</span>
    };

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span style={{fontSize: fontSize}}>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    return (
        <Countdown
            date={Date.now() + millisecondsCount}
            renderer={renderer}
        />
    );
};