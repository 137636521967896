import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col } from 'reactstrap';
import { apiGet, apiPost } from '../../../api';
import avatarLogo from '../../../assets/images/avatar.svg';
import useClickOutside from '../../../hooks/useClickOutside';
import { useFetch } from '../../../hooks/useFetch';
import { appLogout } from '../../../store/app/actions';
import { updateWalletCount } from '../../../store/user/actions';
import Spinner from '../../common/spinner';
import { Icon } from '../icon';
import { Button } from '../ui/button';
import { Modal } from '../ui/modal';
import './index.scss';

export const PageHeader = ({ onPress }) => {
  const profile = useSelector(s => s.user.profile);
  const userProfile = useSelector(s => s.user.profile);
  const walletCount = useSelector(s => s.user.wallet);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(!walletCount);

  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');

  const { response: getUnreadNotifications } = useFetch({
    url: '/notification/not-viewed-count',
  });

  const { response: getUnreadNotificationsData } = useFetch({
    url: '/notification?onlyNotViewed=1',
  });

  const profileRef = useRef(null);
  const profileDropdownRef = useRef(null);

  const notificationRef = useRef(null);
  const notificationDropdownRef = useRef(null);

  const location = useLocation();
  const history = useHistory();

  useClickOutside(profileRef, () => {
    profileDropdownRef.current.classList.remove('_visible');
  });

  useClickOutside(notificationRef, () => {
    notificationDropdownRef.current.classList.remove('_visible');
  });

  const notificationMenuToggle = () => {
    notificationDropdownRef.current.classList.toggle('_visible');
    profileDropdownRef.current.classList.remove('_visible');
  };

  const profileMenuToggle = () => {
    profileDropdownRef.current.classList.toggle('_visible');
    notificationDropdownRef.current.classList.remove('_visible');
  };

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const handleCopy = () => {
    let url = process.env.REACT_APP_BASE_URL;
    copy(`${url}/fast-launch/step-1?referral=${profile.ref}`);
    handleAlertOpen('Реферальная ссылка успешно скопирована');
  };

  const readAllNotifications = () => {
    apiPost({
      url: '/notification/set-as-viewed',
    }).then(res => {
      if (res.error) {
        console.error('Чтение всех уведомлений');
      }
    });
  };

  const goToWallet = () => {
    history.push('/wallet', '', '');
  };

  const logout = () => {
    dispatch(appLogout());
    history.push('/login');
  };

  useEffect(() => {
    profileDropdownRef.current.classList.remove('_visible');
    notificationDropdownRef.current.classList.remove('_visible');
  }, [location]);

  const getBalanceWallet = () => {
    apiGet({
      url: '/wallet/usdr',
    })
      .then(res => {
        if (res.error)
          return console.error('Получение кошелька в header/index.jsx');
        dispatch(
          updateWalletCount(
            +res.data.balance
            // +res.data.filter(i => i.name === 'USDR')[0]?.balance
          )
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getBalanceWallet();
  }, []);

  return (
    <>
      <div id="page-header" className="md:px-4">
        <div className="container-fluid h-full">
          <div className="row h-full">
            <div className="col-auto xl:hidden block">
              <div onClick={onPress} className="flex items-center h-full">
                <Icon name="menu" size={30} />
              </div>
            </div>

            <div className="col">
              <div className="flex flex-1 items-stretch justify-end h-full">
                <div
                  className="flex justify-center items-start mr-2 md:mr-10 cursor-pointer m-auto"
                  onClick={goToWallet}
                >
                  <div className="m-auto mr-2 hidden md:block">
                    <Icon name="wallet" size={24} />
                  </div>
                  <div className="flex flex-col justify-center items-start">
                    {/* <div className="text-xs">Общий баланс</div> */}
                    <span className="text-lg">
                      {loading ? (
                        <Spinner styles={{ margin: 'auto' }} size={30} />
                      ) : (
                        walletCount?.toFixed(2)
                      )}{' '}
                      USDR
                    </span>
                  </div>
                </div>

                <div className="flex items-center mr-2 md:mr-10">
                  <Link className="text-center" to="/appeal">
                    <Icon size={24} name="support" />
                  </Link>
                </div>

                <div className="flex items-center mr-2 md:mr-10">
                  <button
                    onClick={handleCopy}
                    className="flex text-center items-center"
                  >
                    <Icon size={24} name="referalLink" />
                  </button>
                </div>

                <div className="flex items-center mr-2 md:mr-10 relative">
                  <button
                    className="relative flex"
                    onClick={notificationMenuToggle}
                  >
                    <Icon name="bell" size={24} />
                    <div className="num absolute">
                      {getUnreadNotifications?.data != 0 &&
                        getUnreadNotifications?.data}
                    </div>
                  </button>

                  <div
                    ref={notificationDropdownRef}
                    className="dropdown-list flex flex-col _notification p-4 custom-scroll"
                  >
                    <div className="scroll-y board-show">
                      <table className="table " style={{ minWidth: 300 }}>
                        <tbody>
                          {getUnreadNotificationsData?.data?.models?.length <
                            1 && (
                            <tr>
                              <td>Новых уведомлений нет</td>
                            </tr>
                          )}

                          {getUnreadNotificationsData?.data?.models?.map(
                            (i, index) => (
                              <tr key={index}>
                                <td>{i.text}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    <Link onClick={readAllNotifications} to="/notifications">
                      <Button
                        color="primary"
                        size="sm"
                        className="mt-3 self-start"
                      >
                        Все уведомления
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="relative flex items-center" ref={profileRef}>
                  <div className="cursor-pointer" onClick={profileMenuToggle}>
                    <img
                      className="avatar w-9 h-9 rounded-md object-cover"
                      src={userProfile.avatar || avatarLogo}
                      alt=""
                    />
                  </div>

                  <div
                    ref={profileDropdownRef}
                    className="dropdown-list flex flex-col _profile"
                  >
                    <div className="p-3 text-sm font-medium">
                      {userProfile.first_name} {userProfile.last_name}
                      <div className="text-xs text-textFade font-light">
                        ID: {userProfile.user_id}
                      </div>
                    </div>

                    <div className="px-3 pb-3 link-wrap flex flex-col">
                      <Link className="link flex items-center" to="/profile">
                        <Icon name="userOutline" size={16} />
                        Профиль
                      </Link>

                      <button
                        className="link flex items-center"
                        onClick={logout}
                      >
                        <Icon name="offOutline" size={16} />
                        Выход
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};

PageHeader.propTypes = {
  onPress: PropTypes.any,
};
