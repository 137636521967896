import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

export const Badge = ({ color, children }) => {
  return <div className={`cmp-badge _${color}`}>{children}</div>;
};
Badge.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
};
