/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Badge} from '../../../common/badge';
import {Icon} from '../../../common/icon';
import {apiGet} from '../../../../api';
import {useHistory, useParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {Col, Row} from "reactstrap";
import {Input} from "../../../common/ui/input";
import {Select} from "../../../common/ui/select";
import {useForm} from "react-hook-form";
import {Button} from "../../../common/ui/button";
import {Modal} from "../../../common/ui/modal";
import {CopyToClipboard} from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import Spinner from "../../../common/spinner";

export const EachWalletPage = () => {
    const [historyWalletList, setHistoryWalletList] = useState(null);
    const [page, setPage] = useState(1);
    const {register, setValue, watch, reset} = useForm();

    // Поиск по таблице
    const [searchPurpose, setSearchPurpose] = useState('');
    const [searchFrom, setSearchFrom] = useState('');
    const [searchTo, setSearchTo] = useState('');
    const [transactionType, setTransactionType] = useState('');

    // Список всех кошельков
    const [walletsData, setWalletsData] = useState([]);

    // Open/close modal
    const [alertView, setAlertView] = useState(false);
    const [balanceSetModal, setBalanceSetModal] = useState(false);
    const [balanceOutModal, setBalanceOutModal] = useState(false);
    // Текст для уведомления модалки
    const [alertText, setAlertText] = useState('');

    const [commissionSend, setCommissionSend] = useState(null);

    const history = useHistory()

    const nameWallet = useParams();

    const pageChanging = (data) => {
        setPage(data.selected + 1);
    };

    const getCommission = (value = '') => {

        apiGet({
            url: `/commissions?to=${value}`,
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            setCommissionSend(res.data);
        }).catch(e => {
            console.log(e);
        });
    };

    const findHistory = () => {
        setSearchPurpose(watch('searchPurpose'))
        setSearchFrom(watch('searchFrom'))
        setSearchTo(watch('searchTo'))
        setTransactionType(watch('transactionType'))

        // setFilterType(watch('filterType'));
        // setGen(!randomgen);
        // setsSearched(true);
        // history.replace('/wallet?page=1');
        // getAddressHistory();
    };

    const clearSearchedInputs = () => {
        reset({
            searchPurpose: '',
            searchFrom: '',
            searchTo: '',
            transactionType: '',
        });
        setSearchPurpose('');
        setSearchFrom('');
        setSearchTo('');
        setTransactionType('');
    }

    const showOuterLink = (data) => {
        // console.log(data);
        apiGet({
          url: `/wallet/transaction-link?name=${nameWallet.nameWallet}&id=${data.id}`
        }).then(res => {
          if(res.error)
            return console.log(res.data);
          window.open(res.data, '_blank', 'noopener,noreferrer');
        });
    };

    useEffect(() => {
        apiGet({
            url: `/wallet/history?name=${nameWallet.nameWallet}&limit=10&page=${page}&comment=${searchPurpose}&type=${transactionType}&periodStart=${searchFrom}&periodEnd=${searchTo}`
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            setHistoryWalletList(res.data);
        });
    }, [page, searchPurpose, searchFrom, searchTo, transactionType]);

    const backToWallet = () => {
        history.push("/wallet")
    }

    const closeModal = () => {
        setAlertView(false)
        setBalanceSetModal(false)
    }

    useEffect(() => {

        if (commissionSend === null)
            getCommission();

        // Получить список всех кошельков
        apiGet({
            url: `/wallet/crypto`
        }).then(res => {
            if (res.error) return
            res.data.map(item => {
                if(item.name === nameWallet.nameWallet)
                    setWalletsData(item)
            })
        })
    },[])

    const handleAlertOpen = (text) => {
        setAlertText(text)
        setAlertView(true)
    }

    const openModalBalanceOut = () => {
        setBalanceOutModal(true);

    }

    const openModalBalanceIncome = () => {
        setBalanceSetModal(true)
        console.log(walletsData)
    }

    const maxSendValueSum = () => {
        setValue('recipientSum', walletsData.balance - commissionSend[walletsData.name]['TRANSACTION_COMMISSION'].value);
        setValue(
            'recipientCommissionSum',
            walletsData.balance
        );
    }

    return (
        <div className="m-5">
            <div>
                <Button
                    onClick={backToWallet}
                    color="primary"
                    // style={{marginBottom: 15, marginLeft: 10}}
                >Назад к кошельку</Button>


                {/*<div>*/}
                {/*<button*/}
                {/*    data-for="setBalance"*/}
                {/*    data-tip={'Пополнить'}*/}
                {/*    onClick={() => openModalBalanceIncome(historyWalletList)}*/}
                {/*    className="items-center">*/}
                {/*    <div className='sm:hidden'>*/}
                {/*        <Icon name="walletIn" size={15}/>*/}
                {/*    </div>*/}
                {/*    <div className='hidden sm:block'>*/}
                {/*        <Icon name="walletIn" size={22}/>*/}
                {/*    </div>*/}
                {/*</button>*/}

                {/*    <button*/}
                {/*        data-for="setBalance"*/}
                {/*        data-tip={'Отправить'}*/}
                {/*        onClick={openModalBalanceOut}*/}
                {/*        className="items-center  sm:text-text text-sm">*/}
                {/*        <div className='sm:hidden'>*/}
                {/*            <Icon name="walletSend" size={15}/>*/}
                {/*        </div>*/}
                {/*        <div className='hidden sm:block'>*/}
                {/*            <Icon name="walletSend" size={22}/>*/}
                {/*        </div>*/}
                {/*    </button>*/}



                {/*</div>*/}



            </div>
            <div className={'sm:text-2xl text-lg font-medium my-5'}>
                История операций по кошельку {nameWallet.nameWallet}
            </div>

            <Row className="items-end justify-start mt-5">
                <Col xs="auto">
                    <Input
                        label="Назначение"
                        name="searchPurpose"
                        register={register}/>
                </Col>
                <div className={'flex flex-wrap'}>

                <Col xs="auto">
                    <Input
                        // value={'2019-01-01'}
                        inputClass={'min-h-[2.5rem] min-w-[8rem]'}
                        // fieldClass={'min-h-[2.5rem] min-w-[8rem]'}
                        label="Искать с"
                        type="date"
                        name="searchFrom"
                        register={register}/>
                </Col>
                <Col xs="auto">
                    <Input
                        // value={'2023-01-01'}
                        inputClass={'min-h-[2.5rem] min-w-[8rem]'}
                        // fieldClass={'min-h-[2.5rem] min-w-[8rem]'}
                        label="Искать до"
                        type="date"
                        name="searchTo"
                        register={register}/>

                </Col>
                </div>
                <Col xs="auto">
                    <Select
                        label="Тип транзакции"
                        list={[
                            {label: 'Все', value: ''},
                            {label: 'Поступление', value: '1'},
                            {label: 'Списание', value: '2'},
                        ]}
                        name="transactionType"
                        register={register}/>
                </Col>
            </Row>

            <Button
                onClick={findHistory}
                color="primary"
                style={{marginBottom: 15}}>Поиск</Button>
            <Button
                onClick={clearSearchedInputs}
                color="primary"
                style={{marginBottom: 15, marginLeft: 10}}>Очистить</Button>

            {historyWalletList === null ?
                <div className='w-full flex m-auto'><Spinner styles={{margin: 'auto', height: 50}}/></div> :
                <>
                <div className={'overflow-x-auto'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>НАЗНАЧЕНИЕ</th>
                            <th className="whitespace-nowrap">ВРЕМЯ ОПЕРАЦИИ</th>
                            <th>ПОДТВЕРЖДЕНИЕ</th>
                            <th className="whitespace-nowrap">СУММА</th>
                            <th className="whitespace-nowrap"></th>
                        </tr>
                        </thead>

                        <tbody>
                        {historyWalletList?.data?.map((i) => (
                            <tr key={i.id}>
                                <td>{i.id}</td>
                                <td>{i.comment}</td>
                                <td className="whitespace-nowrap">{i.createdAt}</td>
                                <td>
                                    <Badge color={i.isConfirmed ? 'primary' : 'danger'}>
                                        {i.isConfirmed ? 'Подтверждено' : 'Не подтверждено'}
                                    </Badge>
                                </td>
                                <td className="whitespace-nowrap">{i.amount}</td>
                                <td className="whitespace-nowrap">
                                    {i.isExternal ?
                                        <button onClick={() => showOuterLink(i)}>
                                            <Icon size={24} name="referalLink"/>
                                        </button> : <></>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            <div className="text-center mt-2">
                <ReactPaginate
                    pageCount={historyWalletList?.lastPage === 1 ? 0 : historyWalletList?.lastPage}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={3}
                    onPageChange={pageChanging}
                    activeClassName={'_active'}
                    containerClassName={'cmp-pagination inline-flex'}
                    pageLinkClassName={'flex items-center justify-center'}
                    pageClassName={'flex items-center justify-center'}
                    previousLabel={false}
                    nextLabel={false}
                    forcePage={page ? +page - 1 : 0}/>
            </div>
                </>
            }




            {/*Start-Modal ------------Пополнение кошелька*/}
            <Modal
                title={'Пополнить ' + walletsData.name}
                onClose={closeModal}
                isOpen={balanceSetModal}
                footer={
                    <CopyToClipboard
                        onCopy={() => handleAlertOpen('Счет успешно скопирован')}
                        text={walletsData.address}>
                        <Button color="primary">Скопировать номер счета</Button>
                    </CopyToClipboard>
                }>
                <div className={'font-bold'}>Номер счета</div>
                <div className={'mb-3 flex flex-wrap'}>{walletsData.address}</div>
                {walletsData?.address && <QRCode value={walletsData?.address}/>}
            </Modal>
            {/*End-Modal ------------Пополнение кошелька*/}

            {/*Start ------- Открыть окно уведомления*/}
            <Modal
                title={alertText}
                onClose={closeModal}
                isOpen={alertView}
                headerStyles={{border: 'none', color: '#000000'}}
                footerStyles={{border: 'none', padding: '10px'}}
                settings="alert"
                bodyStyles={{padding: '0px'}}
                modalStyles={{margin: 'auto'}}
                footer={
                    <div className="flex justify-center">
                        <Col xs={'auto'}>
                            <Button onClick={closeModal} color="primary">
                                Ок
                            </Button>
                        </Col>
                    </div>
                }/>
            {/*End ------- Открыть окно уведомления*/}

            {/*Start-Modal ------------Отправить с <Крипта>*/}
            {/*<Modal*/}
            {/*    title={`Отправить с ${walletsData.name}`}*/}
            {/*    onClose={closeModal}*/}
            {/*    isOpen={balanceOutModal}*/}
            {/*    footer={!isButtonEnable ?*/}
            {/*        <Button onClick={sendOutFromWallet} color="primary">Вывести</Button> : <></>}>*/}
            {/*    <Input*/}
            {/*        label="Адрес получателя"*/}
            {/*        name="recipientAddress"*/}
            {/*        onChange={recipientAddressOnChange}*/}
            {/*        register={register}/>*/}

                {/*<div className='error mb-3'>*/}
                {/*    {error?.to && error.to[0]}*/}
                {/*</div>*/}

                {/*{showInputs && recipientAddress.length >= 15 &&*/}
                {/*    <>*/}
                {/*        <div className={'flex'}>*/}
                {/*            <Input*/}
                {/*                fieldClass={'w-full mt-auto mb-0'}*/}
                {/*                label="Сумма отправки"*/}
                {/*                name="recipientSum"*/}
                {/*                onChange={howMuchSumOnChange}*/}
                {/*                register={register}/>*/}

                {/*            <div className={'mt-auto mb-0'}>*/}
                {/*                <Button*/}
                {/*                    className={'ml-2 h-10'}*/}
                {/*                    onClick={maxSendValueSum}*/}
                {/*                    color="primary">Все</Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                        {/*<div className='error mt-1 mb-3'>*/}
                        {/*    {error?.amount && error.amount[0]}*/}
                        {/*</div>*/}
                        {/*<div className={(watch('recipientSum') < 0) ? 'mb-3 mt-1 text-red' : 'mb-3 mt-1'}>*/}
                        {/*    <strong>На счете:</strong> {eachWalletName?.balance}*/}
                        {/*</div>*/}

                        {/*<Input*/}
                        {/*    label="С учетом комиссии"*/}
                        {/*    name="recipientCommissionSum"*/}
                        {/*    register={register}/>*/}

                        {/*{showInputs &&*/}
                        {/*    <div className='mb-2'>*/}
                        {/*        Приблизительная*/}
                        {/*        комиссия: {commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value} {eachWalletName.name}*/}
                        {/*    </div>}*/}
                        {/*<div className="error mt-1 mb-3">*/}
                        {/*    Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от*/}
                        {/*    цены и обьема "газа". Окончательная цена будет скорректирована*/}
                        {/*    после совершения транзакции.*/}
                        {/*</div>*/}

                        {/*{validateSendWallet.emailCode &&*/}
                        {/*    <Input*/}
                        {/*        label='Email - код'*/}
                        {/*        name="email_Code2"*/}
                        {/*        register={register}/>}*/}

                        {/*{validateSendWallet.smsCode &&*/}
                        {/*    <Input*/}
                        {/*        label='Sms - код'*/}
                        {/*        name="sms_Code2"*/}
                        {/*        register={register}/>}*/}

                        {/*{validateSendWallet.gaCode &&*/}
                        {/*    <Input*/}
                        {/*        label='Google authenticator - код'*/}
                        {/*        name="ga_Code2"*/}
                        {/*        register={register}/>}*/}

                        {/*{errorFromHandler === null ? <></> :*/}
                        {/*    <>*/}
                        {/*        {errorFromHandler.map((item, i) =>*/}
                        {/*            <div className='error mb-2' key={i}>*/}
                        {/*                {item}*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*    </>}*/}
                    {/*</>*/}
                {/*}*/}
            {/*</Modal>*/}
            {/*End-Modal ------------Отправить с <Крипта>*/}

        </div>
    );
};
