export const PORTFEL_LESSON = [
  {
    id: 1,
    name: 'Портфельный инвестор. Урок 1',
    video: 'https://player.vimeo.com/video/587453451?h=8ec951f97a',
    des: '<b>Криптовалютный портфель</b><ul><li>Что такое Инвестиции</li><li>Цели</li><li>Почему криптовалюты подходят для портфеля</li><li>В чем выгода?</li></ul>',
  },
  {
    id: 2,
    name: 'Портфельный инвестор. Урок 2',
    video: 'https://player.vimeo.com/video/587454924?h=5bf2d26d0e',
    des: '<b>Механика работы с портфелем</b><ul><li>Инструменты</li><li>Работа с CoinMarketCap</li><li>Фундаментальный анализ</li><li>Как получать дивиденды?</li><li>Хэджирование портфеля</li></ul>',
  },
  {
    id: 3,
    name: 'Портфельный инвестор. Урок 3',
    video: 'https://player.vimeo.com/video/587462590?h=e342d6220a',
    des: '<b>Криптовалютный портфель</b><ul><li>Биржа Бинанс</li><li>Регистрация</li><li>Как купить криптовалюту</li><li>Как продать криптовалюту</li><li>Инструменты биржи</li></ul>',
  },
  {
    id: 4,
    name: 'Портфельный инвестор. Урок 4',
    video: 'https://player.vimeo.com/video/587464989?h=08a3db6843',
    des: '<b>Криптовалютный портфель</b><ul><li>Набор позиции</li><li>Фиксация прибыли</li><li>Риски</li><li>Хранение портфеля</li></ul>',
  },
];
