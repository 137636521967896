import { Api } from '../api';

export const checkUserAuth = async () => {
  let authToken = localStorage.getItem('authToken');

  if (window.location.search.match(/key=([,-а-яА-Я0-9a-zA-Z%]+)/)) {
    authToken = window.location.search.match(/key=([,-а-яА-Я0-9a-zA-Z%]+)/)[1];
  }

  // if (window.location.search) {
  // 	authToken = window.location.search.replace("?key=", "")
  // }

  if (authToken) {
    try {
      const profileRes = await Api.get('/profile', {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      return {
        userData: profileRes.data,
        authToken,
      };
    } catch (err) {
      return false;
    }
  }

  return false;
};
