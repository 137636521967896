import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export const PageHeader = ({ title }) => {
  return (
    <Container fluid className="md:my-7 my-4">
      <Row className="justify-between">
        <Col xs="auto">
          <div className="sm:text-2xl text-xl font-medium">{title}</div>
        </Col>
      </Row>
    </Container>
  );
};
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
