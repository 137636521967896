import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { apiPost } from '../../../../../api';
import { useFetch } from '../../../../../hooks/useFetch';
import useUrl from '../../../../../hooks/useUrl';
import { Pagination } from '../../../../common/pagination';
import { StaticBlock } from '../../../../common/staticBlock';
import { Button } from '../../../../common/ui/button';
import { Input } from '../../../../common/ui/input';
import { Modal } from '../../../../common/ui/modal';

export const SmartInvestViewPage = () => {
  const { id } = useParams();
  const { page } = useUrl();
  const { response: smartData, setResponse: smartDataUpdate } = useFetch({
    url: `/smart/view?id=${id}`,
  });
  const { response: divedentsData } = useFetch({
    url: `/smart-dividends?id=${id}&page=${page || 1}&limit=20`,
  });

  const { register, handleSubmit, watch, setValue } = useForm();

  const [editInvestModal, setEditInvestModal] = useState(false);
  const [removeInvestModal, setRemoveInvestModal] = useState(false);
  const [validate, setValidate] = useState({ sms: false, email: false });
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const handleOpenEditInvestModal = () => setEditInvestModal(true);
  const handleCloseEditInvestModal = () => setEditInvestModal(false);
  const handleOpenRemoveInvestModal = () => setRemoveInvestModal(true);
  const handleCloseRemoveInvestModal = () => setRemoveInvestModal(false);

  const handleAcceptRemoveInvestModal = () => {
    let formData = new FormData();

    if (validate.sms) {
      formData.append('code', watch('smsCode'));
    }

    if (validate.email) {
      formData.append('emailCode', watch('emailCode'));
    }

    apiPost({
      url: `/smart/close?id=${id}`,
      postData: formData,
    }).then(res => {
      if (res.error || res.status === 406) return;

      if (res.data === true) {
        handleAlertOpen('Инвестиция успешно закрыта');
        setRemoveInvestModal(false);
      } else {
        setValidate({
          sms: res.data.needPhoneConfirmation,
          email: res.data.needEmailConfirmation,
        });
      }
    });
  };

  const applySettings = data => {
    apiPost({
      url: `/smart/set-close-on-equity?id=${id}`,
      postData: { close_on_equity: data.settingValue },
    }).then(res => {
      if (res.status === 406) {
        return;
      }
      if (!res.error) {
        handleAlertOpen('Успешно применено');
      }
    });
  };

  const updateSmartName = async () => {
    const res = await apiPost({
      url: `/smart/update?id=${smartData.data.id}`,
      postData: {
        name: watch('smartNewName'),
      },
    });

    handleCloseEditInvestModal();

    if (res.error) {
      console.error(res);
      handleAlertOpen('Произошла ошибка, попробуйте позже');

      return;
    }

    smartDataUpdate({
      ...smartData,
      data: { ...smartData.data, name: watch('smartNewName') },
    });
    handleAlertOpen('Данные успешно отредактированы');
  };

  useEffect(() => {
    if (smartData?.data?.close_on_equity) {
      setValue('settingValue', smartData?.data?.close_on_equity);
    }

    if (smartData?.data) {
      setValue('smartNewName', smartData.data.name);
    }
  }, [smartData]);

  return (
    <>
      <Container fluid>
        <Row className="my-8 justify-between">
          <Col>
            <div className="md:text-2xl text-lg font-medium">
              {smartData?.data.name}
            </div>
          </Col>

          <div className="col-auto">
            <div className="row">
              
              {smartData?.data?.condition !== 2 && smartData?.data?.status !== 1 && smartData?.data?.status !== 2 && (
                <div className="col-sm-auto">
                  <Button
                    color="danger"
                    size="sm"
                    onClick={handleOpenRemoveInvestModal}
                  >
                    Закрыть
                  </Button>
                </div>
              )}

              <div className="col-sm-auto">
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleOpenEditInvestModal}
                >
                  Редактировать
                </Button>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${smartData?.data?.amount}`}
              iconName="statHouse"
              title="Размер инвестиции"
            />
          </Col>

          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#f73434"
              value={`${smartData?.data?.dividendsSum}`}
              iconName="statProfit"
              title="Получено дивидендов"
            />
          </Col>
          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${smartData?.data?.equity}%`}
              iconName="statEquity"
              title="Эквити"
            />
          </Col>
          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${smartData?.data?.createdAt}`}
              iconName="statDate"
              title="Дата инвестирования"
            />
          </Col>
          {smartData?.data?.condition !== 2 &&
            <Col xs={12}>
              <Card className="mt-4">
                <CardBody>
                  <Row className="items-end">
                    <Col>
                      <Input
                        fieldClass="_last"
                        label="Автоматически закрыть инвестицию при эквити выше"
                        type="number"
                        name="settingValue"
                        register={register}
                        groupText="%"
                      />
                    </Col>

                    <Col className="sm:mt-0 mt-2" xs="auto">
                      <Button
                        color="primary"
                        onClick={handleSubmit(applySettings)}
                      >
                        Применить
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          }
          <Col className={smartData?.data?.condition == 2 && 'mt-4'} xs={12}>
            <Card>
              <CardHeader>
                <div className="text-2xl font-medium">
                  История начислений дивидендов
                </div>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Дата начисления</th>
                        <th>Сумма начисления</th>
                        <th>Процент</th>
                      </tr>
                    </thead>
                    <tbody>
                      {divedentsData?.data?.models?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.amount}</td>
                            <td>{item.percent}%</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="text-center mt-2">
                  <Pagination
                    lastPage={divedentsData?.data?.lastPage || 1}
                    page={page}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        title="Редактировать инвестицию"
        onClose={handleCloseEditInvestModal}
        isOpen={editInvestModal}
        footer={
          <Button color="primary" size="sm" onClick={updateSmartName}>
            Сохранить
          </Button>
        }
      >
        <Input label="Название" name="smartNewName" register={register} />
      </Modal>

      <Modal
        title="Закрытие инвестиции"
        onClose={handleCloseRemoveInvestModal}
        isOpen={removeInvestModal}
        footer={
          <Row className="justify-end">
            <Col xs="auto">
              <Button
                color="primary"
                size="sm"
                onClick={handleAcceptRemoveInvestModal}
              >
                Подтвердить
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="danger"
                size="sm"
                onClick={handleCloseRemoveInvestModal}
              >
                Отменить
              </Button>
            </Col>
          </Row>
        }
      >
        <p>
          Внимание! Действующий <b>equity {smartData?.data?.equity}%</b>. При
          закрытии инвестиции на счет будет возвращено{' '}
          {((smartData?.data?.amount * smartData?.data?.equity) / 100).toFixed(
            6
          )}{' '}
          USDR. Вы уверены, что хотите закрыть инвестицию?
        </p>

        <div className="mt-2" />

        {validate.sms && (
          <Input label="Sms - код" name="smsCode" register={register} />
        )}
        {validate.email && (
          <Input label="Email - код" name="emailCode" register={register} />
        )}
      </Modal>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
