import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import avatarLogo from '../../../../assets/images/avatar.svg';
import { COLORS } from '../../../../global/colors';
import useClickOutside from '../../../../hooks/useClickOutside';
import { Icon } from '../../icon';
import { Button } from '../../ui/button';
import './index.scss';
import Spinner from '../../spinner';
// import {apiGet} from '../../../../api';

export const TreeNode = ({
  id,
  // percent,
  name,
  totalInvest,
  avatar,
  toggleChild,
  hasChild,
  onClick,
  val,
  qualifications,
  isHasChildren,
  loadChild
}) => {
  const profile = useSelector(s => s.user.profile);

  const [opened, setOpened] = useState(false);
  // const [qualification, setQualification] = useState(null);

  const ref = useRef(null);
  const navRef = useRef(null);

  useClickOutside(navRef, () => {
    ref.current.classList.remove('_active');
  });

  const handleClick = () => {
    if (hasChild !== null){
      setOpened(!opened);

      toggleChild();
    } else return;
  };

  // const toggleMenu = () => {
  //   ref.current.classList.toggle('_active');
  // };

  const getProfileInfo = () => {
    onClick(id);
  };

  useEffect(() => {
    // apiGet({
    //   url: '/linear',
    // }).then(res => {
    //   setQualification(res.data.qualifications);
    // });
  },[]);

  // const showMe = () => {
  //   console.log(qualifications);
  // };

  return (
    <div
      ref={ref}
      className="rounded-lg cmp-tree-node relative z-10 p-2 inline-block"
    >
      <div className="row -mx-2">
        <div className="pl-2 pr-0 col">
          {name !== 'Остальные' ? (
            <>
              <div className="row -mx-2">
                <div className="col-auto px-2">
                  <img
                    src={avatar || avatarLogo}
                    className="w-9 h-9 rounded-md"
                    alt=""
                  />
                </div>

                <div className="col pl-0 pr-2">
                  <div className="font-light text-xs text-purple flex items-center justify-between">
                    {id ? `ID ${id}` : null}

                    {/*{ hasChild === false ? qualifications : ''}*/}

                    {/*<button onClick={showMe}>show</button>*/}

                    {
                      qualifications !== null ?
                        <span
                          className="bg-purple px-2 leading-normal rounded-md"
                          style={{ color: '#ffffff' }}
                        >
                          {qualifications}
                        </span> : null
                    }

                    {/*{*/}
                    {/*  hasChild === true ?*/}
                    {/*    qualification !== null ?*/}
                    {/*      <span*/}
                    {/*        className="bg-purple px-2 leading-normal rounded-md"*/}
                    {/*        style={{ color: '#ffffff' }}*/}
                    {/*      >*/}
                    {/*        {qualification}*/}
                    {/*      </span> : null : ''*/}
                    {/*}*/}

                    {/*{percent ? (*/}
                    {/*  <span*/}
                    {/*    className="bg-purple px-2 leading-normal rounded-md"*/}
                    {/*    style={{ color: '#ffffff' }}*/}
                    {/*  >*/}
                    {/*    {percent}%*/}
                    {/*  </span>*/}
                    {/*) : null}*/}
                  </div>
                  {val !== 'binary' ? (
                    <div className="font-light text-xs text-purple flex items-center justify-between mt-1">
                      Инвестиции: {+Number(totalInvest).toFixed(2)}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-1 text-sm leading-tight name">{name}</div>
            </>
          ) : (
            <>
              {val !== 'binary' ? (
                <div className="font-light text-xs text-purple flex items-center justify-between  mt-1">
                  Сумма инвестиций: {Number(totalInvest).toFixed(2)}
                </div>
              ) : null}

              <div className="mt-1 text-sm leading-tight name whitespace-nowrap">
                {name}
              </div>
            </>
          )}
        </div>

        <div className="px-2 col-auto">
          <div className="flex flex-col">
            {/*{profile.user_id !== id && id !== null ? (*/}
            {id !== null ? (
              <Button
                className="p-0 z-10 relative mb-2"
                onClick={getProfileInfo}

                // onClick={e => {
                //   e.stopPropagation();
                //   toggleMenu();
                // }}
              >
                <Icon
                  name="dots"
                  size={24}
                  color={COLORS.purple}
                  className="w-6 h-6 rounded-md bg-bg"
                  style={{ pointerEvents: 'none' }}
                />

                {/*<nav ref={navRef} className="button-wrap flex-col">*/}
                {/*  <span className="button" onClick={getProfileInfo}>*/}
                {/*    Информация*/}
                {/*  </span>*/}
                {/*</nav>*/}
              </Button>
            ) : null}

            {profile.user_id !== id && id !== null && isHasChildren  ? (
              <Button
                className={cx('p-0 transform', {
                  'rotate-180': opened,
                })}
                onClick={handleClick}
              >
                {loadChild ? (
                  <Spinner style={{ margin: 'auto', width:24}} size={11}/>
                ):(
                  <Icon
                    name="arrowDown"
                    color={COLORS.purple}
                    className="w-6 h-6 rounded-md bg-bg"
                    style={{ pointerEvents: 'none' }}
                  />
                )}
              </Button>
            ) : null}
            {name === 'Остальные' && isHasChildren ? (
              <Button
                className={cx('p-0 transform', {
                  'rotate-180': opened,
                })}
                onClick={handleClick}
              >
                {loadChild ? (
                  <Spinner style={{ margin: 'auto', width:24}} size={11}/>
                ):(
                  <Icon
                    name="arrowDown"
                    color={COLORS.purple}
                    className="w-6 h-6 rounded-md bg-bg"
                    style={{ pointerEvents: 'none' }}
                  />
                )}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
TreeNode.propTypes = {
  id: PropTypes.number,
  percent: PropTypes.number,
  name: PropTypes.string,
  qualifications: PropTypes.string,
  toggleChild: PropTypes.func,
  hasChild: PropTypes.bool,
  onClick: PropTypes.func,
  totalInvest: PropTypes.any,
  avatar: PropTypes.any,
  val: PropTypes.any,
  isHasChildren: PropTypes.bool,
  loadChild: PropTypes.bool,
};
