import ACTIVE_IMG from '../assets/images/course/active.jpg';
import PORTFEL_IMG from '../assets/images/course/portfel.jpg';
import REZONABLE_IMG from '../assets/images/course/reasonable.jpg';
import { ACTIVE_LESSON } from './lessons/active';
import { PORTFEL_LESSON } from './lessons/portfelLess';
import { RESONABLE_LESSON } from './lessons/resonable';

export const LESSON_CATEGORY_LIST = [
  {
    id: 1,
    name: 'Разумный инвестор',
    img: REZONABLE_IMG,
    des: '',
    lessons: RESONABLE_LESSON,
  },
  {
    id: 3,
    name: 'Портфельный инвестор',
    img: PORTFEL_IMG,
    des: '',
    lessons: PORTFEL_LESSON,
  },
  {
    id: 2,
    name: 'Активный инвестор',
    img: ACTIVE_IMG,
    des: '',
    lessons: ACTIVE_LESSON,
  },
];
