import React from 'react';
import {Container, Row} from 'reactstrap';
import {PageHeader} from '../../../common/pageHeader';
import {IndexType} from './IndexType';
import {useFetch} from '../../../../hooks/useFetch';
import {Pagination} from '../../../common/pagination';
import useUrl from '../../../../hooks/useUrl';

export const IndexPage = () => {
  const { page } = useUrl();

  const { response: indexTypeList } =
    useFetch({
      url: `/index-type?page=${page || 1}&limit=10`,
    });

  return (
    <Container fluid>
      <Row>
        <PageHeader title="INDEX" />

        {indexTypeList?.data?.models?.map((data, index) => {
          return (
            <div key={index} className="col-12 col-md-6">
              <IndexType indexType={data} />
            </div>
          );
        })}

        <div className="text-center" style={{width: '100%'}}>
          <Pagination
            lastPage={indexTypeList?.data?.lastPage || 1}
            page={page}
          />
        </div>
      </Row>
    </Container>
  );
};