import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LESSON_CATEGORY_LIST } from '../../../../global/lessonCategory';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { LessonBlock } from '../../../common/lessonBlock/lessonBlock';
import { Modal } from '../../../common/ui/modal';
import './index.scss';

export const LessonPage = () => {
  const screenWidth = window.screen.width;

  const { id } = useParams();

  const renderLessonBlock = data => {
    return <LessonBlock key={data.id} {...{ data }} open={openVideo} />;
  };

  const renderLessons = data => {
    if (data.id === +id) {
      return data.lessons.map(renderLessonBlock);
    }

    return null;
  };

  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const modalRef = useRef(null);

  const { windowWidth } = useWindowSize();

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.height = `${
        modalRef.current.clientWidth * (9 / 16)
      }px`;
    }
  }, [modalRef, windowWidth]);

  return (
    <div className="container-fluid">
      <div className="mt-10 mb-5">
        {LESSON_CATEGORY_LIST.map(renderLessons)}
      </div>

      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src={videoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
