import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { Icon } from '../../icon';
import { Button } from '../../ui/button';
import { Modal } from '../../ui/modal';
import styles from './index.module.scss';

export const GarantDescription = ({ showFooter = true }) => {
  const screenWidth = window.screen.width;

  const [modal, setModal] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const handleToggleModal = () => setModal(!modal);

  const modalRef = useRef(null);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  return (
    <>
      <Card>
        <CardHeader className="flex items-center">
          <div className="sm:text-2xl text-lg font-medium">Garant</div>

          <Button
            color="blue"
            className="p-2 ml-3"
            onClick={() =>
              openVideo('https://www.youtube.com/embed/tI91K9fj5sw')
            }
          >
            <Icon name="playButton" color="#fff" size={16} />
          </Button>
        </CardHeader>
        <CardBody className={styles.content}>
          <p className="mb-5">
            Авторский безрисковый финансовый продукт команды RSI с фиксированной
            доходностью от 2% до 3% в месяц, основанный на отлаженном механизме
            внебиржевого обмена криптовалютных активов командой управляющих
            фонда.
          </p>

          <p className="mb-5">
            <b className="text-text">Безопасность:</b>
            <br />
            Команда управляющих умеет делать так, чтобы колебания цены на тот
            или иной актив никак не влияли на доходность для наших инвесторов.
            Данный эффект достигается методом хэджирования.
          </p>

          <p className="mb-5">
            <b className="text-text">Механизм:</b>
            <br />
            Внебиржевой обмен клиптовалюных активов на фиатные средства плюс
            обратный обмен. Доходность для инвестора достигается за счёт объёма
            сделок.
          </p>

          <p>
            <b className="text-text">Логика:</b>
            <br />
            Объем рынка криптовалют уже исчисляется миллиардами, а значит есть
            огромный спрос на обмен любого актива. То есть процесс
            предусматривает наличие свободных средств для совершения сделок.
            Отсюда, наша потребность в инвесторах, которым «Garant» предлагает
            солидную прибыль и долгосрочное сотрудничество.
          </p>
        </CardBody>

        {showFooter && (
          <CardFooter>
            <Button color="primary" onClick={handleToggleModal}>
              Инвестировать
            </Button>
          </CardFooter>
        )}
      </Card>

      <Modal
        title="Инструкция инвестиции в Garant"
        onClose={handleToggleModal}
        isOpen={modal}
        footer={
          <Link to="/garant">
            <Button color="primary">Инвестировать</Button>
          </Link>
        }
      >
        <ul>
          <li className="mb-3">
            - Инвестировать можно с USDR счета или криптовалютного кошелька.
            Если у Вас нет USDR, то Вам лучше подойдет способ инвестирования с
            криптовалютного кошелька.
          </li>
          <li className="mb-3">
            - Перед тем, как инвестировать, нужно завести криптовалюту на
            аккаунт. Для этого отправьте на криптовалютный кошелек
            соответствующую криптовалюту. Узнать адреса своих кошельков можно в
            разделе{' '}
            <Link className="link" to="/wallet">
              WALLET
            </Link>
            . Если у Вас нет криптовалюты и Вы не знаете, где можно её
            приобрести, мы рекомендуем Вам{' '}
            <a
              href="https://t.me/RSI_garantBot"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              RSI GARANT Bot
            </a>
            .
          </li>
          <li>
            - После того, как на одном из Ваших кошельков или USDR счёте будет
            сумма, которую вы готовы инвестировать, можете перейти к
            инвестированию.
          </li>
        </ul>
      </Modal>
      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src="https://www.youtube.com/embed/tI91K9fj5sw"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </>
  );
};
GarantDescription.propTypes = {
  showFooter: PropTypes.bool,
};
