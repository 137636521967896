import {Card, CardBody} from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.scss';

export const IndexType = ({indexType}) => {
  return (
    <Card>
      <Link className="relative" to={`/index/${indexType?.id}`}>
        <CardBody className="index-type-element">
          {indexType.bannerLink ? <img alt="" className="index-type-img" src={indexType.bannerLink}/> : <></>}

          <div className="index-type-title">{indexType?.name}</div>
        </CardBody>
      </Link>
    </Card>
  );
};
IndexType.propTypes = {
  indexType: PropTypes.object.isRequired,
};