/* eslint-disable indent */
import { ERROR_500, LOGIN, LOGOUT, UPDATE_LOADING_STATUS } from './types';

const initialState = {
  authToken: null,
  appTheme: null,
  appLoading: false,
  error500: false,
  error500mess:'',
};

export const appReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return { ...state, authToken: payload };
    case UPDATE_LOADING_STATUS:
      return { ...state, appLoading: payload };
    case LOGOUT:
      return initialState;
    case ERROR_500:
      return { ...state, error500: payload.error ,error500mess:payload.data};
    default:
      return state;
  }
};
