import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CardBody } from 'reactstrap';
import { apiGet } from '../../../../api';
import { useIsTouchDevice } from '../../../../hooks/useIsTouchDevice';
import { Icon } from '../../icon';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { TreeNodeInfo } from '../nodeInfo';
import './index.scss';
import { LinearTreeNode } from './node';

export const LinearTree = () => {
  const userDataRef = useRef(null);
  const { register, watch, setValue } = useForm();

  const isTouch = useIsTouchDevice();

  const ref = useRef(null);

  const [grab, setGrab] = useState(false);
  const [linearStruture, setLinearStructure] = useState(null);
  const [searchUser, setSearchUser] = useState(0);
  const [profileDataInfo, setProfileDataInfo] = useState(null);

  const [searchList, setSearchList] = useState([]);

  const getSearchList = () => {
    apiGet({
      url: `/linear/find-children?q=${watch('searchName')}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setSearchList(res.data);
        }
      }
    });
  };

  const getMembersData = () => {
    apiGet({
      url: '/linear',
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setLinearStructure([res.data]);
          setValue('searchName', '');
          setSearchUser(0);
        }
      }
    });
  };

  const openUserData = userId => {
    apiGet({
      url: `/linear/detail?id=${userId}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке profile');
          return;
        }
        if (!res.error) {
          setProfileDataInfo(res.data);
          userDataRef.current.open(userId);
        }
      }
    });
  };

  const renderItem = (i, index) => {
    return (
      <LinearTreeNode
        key={index}
        parentIndex={index}
        data={i}
        openUser={openUserData}
      />
    );
  };

  const toggleGrab = () => setGrab(!grab);

  const toggleFullScreen = () => {
    ref.current.classList.toggle('_full');
  };

  useEffect(() => {
    getMembersData();
  }, []);

  useEffect(() => {
    if (!watch('searchName')) {
      setSearchList([]);
      return;
    }

    if (watch('searchName') !== linearStruture[0].name) {
      getSearchList();
    } else setSearchList([]);
  }, [watch('searchName')]);

  const renderSearchList = (i, index) => {
    const goToPeople = () => {
      setLinearStructure([i]);
      setSearchUser(1);
      setValue('searchName', i.name || i.id);
    };
    return (
      <div key={index} className="item" onClick={goToPeople}>
        {i.name}
        <br />
        {i.id && `id: ${i.id}`}
      </div>
    );
  };

  return (
    <>
      <div className="row justify-end mb-3">
        {searchUser === 1 && (
          <div className="col-sm-auto mb-2 sm:mb-0">
            <Button color="primary" className="w-full" onClick={getMembersData}>
              Очистить поиск
            </Button>
          </div>
        )}

        <div className="col-sm-auto relative">
          <Input
            placeholder="Поиск пользователя"
            fieldClass="w-full sm:w-60 mb-0"
            name="searchName"
            autoComplete="off"
            register={register}
          />
          <div className="search-list">{searchList.map(renderSearchList)}</div>
        </div>
      </div>

      <div
        ref={ref}
        className={cx(
          'card cmp-linear-tree overflow-hidden fullscreen-tree w-full overflow-hidden pos',
          {
            _touch: isTouch,
          }
        )}
      >
        {isTouch && (
          <div
            onClick={toggleFullScreen}
            className="open-full flex items-center justify-center image-contain z-10 pos-abs text-white text-lg"
          >
            Раскрыть структуру
          </div>
        )}

        <div className="close-full p-3 justify-between font-medium">
          <span>Линейная структура</span>

          <div
            onClick={toggleFullScreen}
            className="cursor-pointer flex items-center justify-center"
          >
            <Icon name="close" size={16} />
          </div>
        </div>

        <CardBody
          className="inner-wrap p-0 relative overflow-hidden"
          style={{
            cursor: grab ? 'grabbing' : 'grab',
          }}
        >
          {linearStruture?.length > 0 && (
            <TransformWrapper
              wheel={{ step: 0.05 }}
              initialScale={0.8}
              initialPositionX={30}
              initialPositionY={30}
              minScale={0.5}
              maxScale={2}
              limitToBounds={false}
            >
              {({ zoomIn, zoomOut }) => (
                <>
                  <div className="block-translate">
                    <button onClick={() => zoomIn()}>
                      <Icon size={8} name="plus" />
                    </button>
                    <button onClick={() => zoomOut()}>
                      <Icon size={8} name="minus" />
                    </button>
                  </div>
                  <TransformComponent>
                    <div
                      className="tree-wrap flex flex-col items-start"
                      onMouseDown={toggleGrab}
                      onMouseUp={toggleGrab}
                    >
                      {linearStruture?.map(renderItem)}
                    </div>
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          )}
        </CardBody>
      </div>

      <TreeNodeInfo data={profileDataInfo} ref={userDataRef} />
    </>
  );
};
