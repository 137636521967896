import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

export const Checkbox = ({
  classNames,
  children,
  name,
  register,
  ...props
}) => {
  return (
    <label className={cx('block cursor-pointer', classNames)}>
      <Row>
        <Col xs="auto" className="pr-0">
          <input
            type="checkbox"
            {...register(name)}
            className="checkbox"
            {...props}
          />
        </Col>

        <Col>{children}</Col>
      </Row>
    </label>
  );
};
Checkbox.propTypes = {
  classNames: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.any,
  register: PropTypes.func,
};
