import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { getActiveClass } from '../../../helpers/className';
import { Input } from '../../common/ui/input';
import { PhoneInput } from '../../common/ui/input/PhoneInput';
import Spinner from '../../common/spinner';
import { Button } from '../../common/ui/button';
import { AuthLayout } from '../../layouts/auth';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { apiPost } from '../../../api';
import { Icon } from '../../common/icon';
import { useHistory } from 'react-router-dom';

export const PasswordResetPage = () => {
  const history = useHistory();

  const [requestMade, setRequestMade] = useState(false);
  const [authType, setAuthType] = useState('email');
  const [errObj, setErrObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [needConfirmation, setNeedConfirmation] = useState({
    sms: false,
    email: false,
  });

  const { handleSubmit, register, control } = useForm();

  const goBack = e => {
    e.preventDefault();

    history.goBack();
  };

  const onSubmit = async data => {
    try {
      setLoading(true);

      if (data.phone && authType === 'phone') {
        delete data.email;
      }

      if (data.email && authType === 'email') {
        delete data.phone;
      }

      const res = await apiPost({
        url: '/auth/recovery-password',
        postData: data,
      });

      console.log(res);

      if (res.error) {
        if (res.status === 406) {
          setErrObj(res.data);
        }

        return;
      }

      if (res.data === true) {
        alert('Пароль успешно восстановлен. Авторизуйтесь');
        history.push('/login');
      } else {
        setNeedConfirmation({
          sms: res.data.needPhoneConfirmation,
          email: res.data.needEmailConfirmation,
        });
      }

      setRequestMade(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <Container>
        <Row className="justify-center">
          <Col md="5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className="mt-20">
                <CardHeader className="py-3 text-2xl text-center font-medium">
                  <Row className="items-center">
                    <Col xs="auto" className="pr-2">
                      <button className="transform rotate-90" onClick={goBack}>
                        <Icon name="chevronDown" />
                      </button>
                    </Col>
                    <Col xs="auto">Восстановление пароля</Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row className="mb-6 mx-0 tab-wrap rounded-md">
                    <Col className="px-0">
                      <span
                        className={`button text-center w-full ${getActiveClass(
                          'email',
                          authType
                        )}`}
                        onClick={() => setAuthType('email')}
                      >
                        E-Mail
                      </span>
                    </Col>

                    <Col className="px-0">
                      <span
                        className={`button text-center w-full ${getActiveClass(
                          'phone',
                          authType
                        )}`}
                        onClick={() => setAuthType('phone')}
                      >
                        Телефон
                      </span>
                    </Col>
                  </Row>

                  {authType === 'email' && (
                    <Input
                      name="email"
                      type="mail"
                      register={register}
                      label="E-Mail"
                      placeholder="example@mail.ru"
                      error={errObj?.email?.[0]}
                    />
                  )}

                  {authType === 'phone' && (
                    <PhoneInput
                      control={control}
                      name="phone"
                      label="Телефон"
                      error={errObj?.phone?.[0]}
                    />
                  )}

                  <Input
                    name="password"
                    register={register}
                    label="Новый пароль"
                    type="password"
                    placeholder="• • • • • • • • • • •"
                    error={errObj?.password?.[0]}
                  />

                  {needConfirmation.sms && (
                    <Input name="code" register={register} label="Код из смс" />
                  )}

                  {needConfirmation.email && (
                    <Input
                      name="emailCode"
                      register={register}
                      label="Код с почты"
                      error={errObj?.emailCode?.[0]}
                    />
                  )}
                </CardBody>

                <CardFooter>
                  {loading ? (
                    <Spinner size={70} />
                  ) : (
                    <Button type="submit" color="primary">
                      {requestMade ? 'Восстановить пароль' : 'Отправить код'}
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </AuthLayout>
  );
};
