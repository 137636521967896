import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.scss';

export const Select = ({
  fieldClass,
  label,
  error,
  name,
  register,
  list,
  thumbText,
  errors,
  ...props
}) => {
  const renderOption = ({ value, label }) => {
    return (
      <option key={value} value={value}>
        {label}
      </option>
    );
  };

  return (
    <label className={cx(styles.field, fieldClass, 'block')}>
      {label && (
        <div className={cx(styles.label, 'cursor-pointer mb-1')}>{label}</div>
      )}

      <select
        className={cx(styles.select, 'w-full')}
        {...register(name)}
        {...props}
      >
        {list && list.map(renderOption)}
      </select>

      {thumbText && <div className={cx(styles.thumb, 'mt-1')}>{thumbText}</div>}

      {error && <div className={cx(styles.error, 'mt-1')}>{error}</div>}

      {errors &&
        errors.map((item, key) => {
          return <div key={key} className={cx(styles.error, 'mt-1')}>{item}</div>;
        })}
    </label>
  );
};
Select.propTypes = {
  fieldClass: PropTypes.any,
  register: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  list: PropTypes.array,
  thumbText: PropTypes.string,
  errors: PropTypes.array,
};
