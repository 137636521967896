import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PageFooter } from '../../common/footer';
import { PageHeader } from '../../common/header';
import { MainMenu } from '../../common/mainMenu';
import styles from './index.module.scss';

export const MainLayout = ({ children }) => {
  const ref = useRef();
  const backRef = useRef();
  const location = useLocation();
  const mainMenuToggle = () => {
    ref?.current?.classList.toggle('visible');
    backRef?.current?.classList.toggle('back-active');
  };

  const closeOutside = () => {
    ref?.current?.classList.remove('visible');
    backRef?.current?.classList.remove('back-active');
  };

  useEffect(() => {
    ref?.current?.classList.remove('visible');
    backRef?.current?.classList.remove('back-active');
  }, [location]);

  return (
    <>
      <MainMenu backClose={() => closeOutside()} ref={{ ref, backRef }} />
      <div
        className={cx(
          'scroll-y min-h-full flex flex-col justify-between',
          styles.wrap
        )}
      >
        <PageHeader onPress={() => mainMenuToggle()} />

        <div className="flex-1 md:px-4">{children}</div>

        <PageFooter />
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};
