import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

export const NotifyBlock = ({
  color = null,
  textAlign = 'left',
  children,
  classList,
  ...props
}) => {
  let cls = `cmp-notify text-${textAlign} ${classList}`;

  if (color) {
    cls += ` _color-${color}`;
  }

  return (
    <div className={cls} {...props}>
      {children}
    </div>
  );
};
NotifyBlock.propTypes = {
  color: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.any,
  classList: PropTypes.string,
};
