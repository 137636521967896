/* eslint-disable */
import React, {Fragment, useEffect, useState} from 'react';
import {apiGet, apiPost} from '../../../../api';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Icon} from '../../../common/icon';
import ReactTooltip from 'react-tooltip';
import {Modal} from '../../../common/ui/modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button} from '../../../common/ui/button';
import QRCode from 'qrcode.react';
import {Input} from '../../../common/ui/input';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {BillBlock} from '../../../common/billBlock';
import {getActiveClass} from '../../../../helpers/className';
import useDebounce from '../../../../hooks/useDebounce';
import {NotifyBlock} from '../../../common/notify';
import Spinner from '../../../common/spinner';
import logo from '../../../../assets/images/logo.png'
import {EachWalletPage} from "./eachWalletPage";
import {EachFiatWalletPage} from "./eachFiatPage";
import {GarantOffices} from "../../../common/garantOffices";
import {Timer} from "../../../common/timer";
import {Select} from "../../../common/ui/select";
import cx from "classnames";
import styles from "../../../common/ui/select/index.module.scss";

const WalletPageUpdate = () => {
    const {register, setValue, watch, reset} = useForm();
    const [walletsData, setWalletsData] = useState(null);
    const [eachWalletName, setEachWalletName] = useState({});
    const [loaderSend, setLoaderSend] = useState(false);
    const [walletsForChoice, setWalletsForChoice] = useState(null);
    const [fiatRubToUsdtRate, setFiatRubToUsdtRate] = useState(null);
    const [buyCryptoViaFiatRubCard, setBuyCryptoViaFiatRubCard] = useState(null);

    // Open and close states for modals
    const [balanceSetModal, setBalanceSetModal] = useState(false);
    const [balanceOutModal, setBalanceOutModal] = useState(false);
    const [cryptoWalletsChoiceModal, setCryptoWalletsChoiceModal] = useState(false);
    const [alertView, setAlertView] = useState(false);
    const [usdrBalanceOutModal, setUsdrBalanceOutModal] = useState(false);
    const [openBuyTokenModal, setOpenBuyTokenModal] = useState(false);
    const [balanceOutUsdrModal, setBalanceOutUsdrModal] = useState(false);
    const [balanceOutSmartModal, setBalanceOutSmartModal] = useState(false);
    const [balanceOutFiatModal, setBalanceOutFiatModal] = useState(false);
    const [withdrawBalanceUsdrModal, setWithdrawBalanceUsdrModal] = useState(false);
    const [withdrawBalanceSmartModal, setWithdrawBalanceSmartModal] = useState(false);
    const [openFiatEnterModal, setOpenFiatEnterModal] = useState(false);
    const [choiceFiatInputMethodModal, setChoiceFiatInputMethodModal] = useState(false);
    const [choiceFiatOutputMethodModal, setChoiceFiatOutputMethodModal] = useState(false);
    const [officeAddressInputModal, setOfficeAddressInputModal] = useState(false);
    const [officeAddressOutputModal, setOfficeAddressOutputModal] = useState(false);
    const [buyCryptoViaFiatRubModal, setBuyCryptoViaFiatRubModal] = useState(false);
    const [cardInfoForBuyCryptoViaFiatRubModal, setCardInfoForBuyCryptoViaFiatRubModal] = useState(false);
    // Вывод средств с фиата
    const [openFiatOutPaymingModal, setOpenFiatOutPaymingModal] = useState(false);

    // Показывать Btc в блоке renderBillBlock
    const [isBtcShow, setIsBtcShow] = useState(true);

    // Текст для окна уведомления
    const [alertText, setAlertText] = useState('');
    const [recipientAddress, setRecipientAddress] = useState('');

    // Получить коммиссию по всем кошелькам
    const [commissionSend, setCommissionSend] = useState(null);

    // Скрытые инпуты показать
    const [showInputs, setShowInputs] = useState(false);
    // const [isActiveButton, setIsActiveButton] = useState({});

    const [investWallet, setInvestWallet] = useState(null);
    const [investWalletUsdr, setInvestWalletUsdr] = useState(null);
    const [smartWallet, setSmartWallet] = useState(null);
    const [tokenPrivateWallet, setTokenPrivateWallet] = useState(null);
    const [fiatWalletsData, setFiatWalletsData] = useState(null);

    const [availableWalletsToReplenish, setAvailableWalletsToReplenish] = useState(null);
    const [availableWalletsToPayout, setAvailableWalletsToPayout] = useState(null);

    const [availableWalletsToBuyToken, setAvailableWalletsToBuyToken] = useState(null);
    const [availableWalletsToSellToken, setAvailableWalletsToSellToken] = useState(null);

    const [userInfoById, setUserInfoById] = useState(null);
    const [error, setError] = useState(null);
    const [errorFromHandler, setErrorFromHandler] = useState(null);
    const [isButtonEnable, setIsButtonEnable] = useState(false);

    // Comissions
    // const [commissions, setCommissions] = useState(null);


    const [isShowEachWalletPage, setIsShowEachWalletPage] = useState(false);
    const [isShowEachFiatWalletPage, setIsShowEachFiatWalletPage] = useState(false);
    const [nameForEachWalletPage, setNameForEachWalletPage] = useState('');

    // Для обновления истории кошелька
    const [isRefreshWallet, setIsRefreshWallet] = useState(false);

    const [openFiatInputContract, setOpenFiatInputContract] = useState(false);


    const [spinnerFiatOutPay, setSpinnerFiatOutPay] = useState(false)
    const [isFiatEnterSpinner, setIsFiatEnterSpinner] = useState(false)

    const [validateSendWallet, setValidateSendWallet] = useState({
        emailCode: false,
        smsCode: false,
        gaCode: false,
    });

    const [fiatInputPaymentMethod, setFiatInputPaymentMethod] = useState(null);
    const FIAT_INPUT_PAYMENT_METHOD_ONLINE = 'Paylama_P2P';
    const FIAT_INPUT_PAYMENT_METHOD_CASH = 'Office';

    const [fiatOutputPaymentMethod, setFiatOutputPaymentMethod] = useState(null);
    const FIAT_OUTPUT_PAYMENT_METHOD_ONLINE = 'Paylama';
    const FIAT_OUTPUT_PAYMENT_METHOD_CASH = 'Office';

    const [garantOffices, setGarantOffices] = useState(null);

    const inputPaymentMethodList = {
        Paylama_P2P: 2,
        Office: 4,
    }

    const outputPaymentMethodList = {
        Paylama: 1,
        Office: 4,
    }

    const history = useHistory();

    const closeModal = (e, needRefreshWallet = false) => {
        setIsButtonEnable(false)
        setIsBtcShow(true)
        setShowInputs(false)
        setBalanceSetModal(false);
        setBalanceOutModal(false);
        setAlertView(false);
        setUsdrBalanceOutModal(false);
        setOpenBuyTokenModal(false);
        setBalanceOutUsdrModal(false);
        setBalanceOutSmartModal(false);
        setBalanceOutFiatModal(false);
        setWithdrawBalanceUsdrModal(false);
        setWithdrawBalanceSmartModal(false);
        setOpenFiatEnterModal(false);
        setError({});
        setErrorFromHandler(null);
        setOpenFiatOutPaymingModal(false);
        setOpenFiatInputContract(false)
        setInvestWallet(null);
        setCryptoWalletsChoiceModal(false);
        setChoiceFiatInputMethodModal(false);
        setChoiceFiatOutputMethodModal(false);
        setOfficeAddressInputModal(false);
        setOfficeAddressOutputModal(false);
        setBuyCryptoViaFiatRubModal(false);
        setCardInfoForBuyCryptoViaFiatRubModal(false);

        setValidateSendWallet({
            emailCode: false,
            smsCode: false,
            gaCode: false,
        })

        reset({
            incomePay: '',
            cryproToUsdrPay: '',
            usdrCounter: '',
            cryptoCounter: '',
            IdForUserUsdr: '',
            IdForUserSmart: '',
            IdForUserFiat: '',
            sumForUserUsdr: '',
            sumForUserSmart: '',
            sumForUserFiat: '',
            recipientAddress: '',
            recipientSum: '',
            recipientCommissionSum: '',
            fiatInputCommissions: '',
            fiatAmount: '',
            fiatOutAmount: '',
            bankCardFiatOut: '',
            bankCardFiatOutCommission: '',
            codeAuth: '',
            email_Code: '',
            code: '',
            sms_Code: '',
            emailCodeSmart: '',
            smsCodeSmart: '',
            gaCodeSmart: '',
            emailCodeFiat: '',
            smsCodeFiat: '',
            gaCodeFiat: '',
            emailCodeFiatOutput: '',
            smsCodeFiatOutput: '',
            gaCodeFiatOutput: '',
            tokenPrivateCount: '',
            tokenPrivatePrice: '',
            buyCryptoFiatAmount: '',
            buyCryptoCryptoAmount: '',
        });

        if (needRefreshWallet) {
            refreshWallet();
        }
    };

    // Обновление кошельков
    const refreshWallet = () => {
        apiGet({
            url: `/wallet/crypto`
        }).then(res => {
            if (res.error)
                return
            setWalletsData(res.data)
            const syncWalletsData = res.data

            apiGet({
                url: `/wallet/smart`
            }).then(res => {
                if (res.error)
                    return
                setSmartWallet(res.data)
                const walletSmartData = res.data

                apiGet({
                    url: `/wallet/usdr`
                }).then(res => {
                    if (res.error)
                        return
                    setInvestWalletUsdr(res.data)

                    let arrToReplenish = []
                    let arrToPayout = []
                    res.data.availableWalletsToReplenish.map(itemRepl => {
                        syncWalletsData.map(item => {
                            if (item.name === itemRepl)
                                arrToReplenish.push(item)
                        })
                    })
                    setAvailableWalletsToReplenish(arrToReplenish)

                    res.data.availableWalletsToPayout.map(itemRepl => {
                        syncWalletsData.map(item => {
                            if (item.name === itemRepl)
                                arrToPayout.push(item)
                        })
                    })
                    setAvailableWalletsToPayout(arrToPayout)
                });

                apiGet({
                    url: `/wallet/token`
                }).then(res => {
                    if (res.error)
                        return
                    setTokenPrivateWallet(res.data)

                    let arrToBuyTokenPrivate = []
                    let arrToSellTokenPrivate = []
                    res.data.availableWalletsToReplenish.map(itemRepl => {
                        syncWalletsData.map(item => {
                            if (item.name === itemRepl)
                                arrToBuyTokenPrivate.push(item)
                        })

                        if (itemRepl === 'SMART' && walletSmartData) {
                            arrToBuyTokenPrivate.push(walletSmartData)
                        }
                    })
                    setAvailableWalletsToBuyToken(arrToBuyTokenPrivate)

                    res.data.availableWalletsToPayout.map(itemRepl => {
                        syncWalletsData.map(item => {
                            if (item.name === itemRepl)
                                arrToSellTokenPrivate.push(item)
                        })
                    })
                    setAvailableWalletsToSellToken(arrToSellTokenPrivate)
                });
            });
        });

        apiGet({
            url: `/wallet/fiat`
        }).then(res => {
            if (res.error)
                return

            setFiatWalletsData(res.data)
        })
    };

    useEffect(() => {
        // Обновление кошельков
        refreshWallet();
    }, []);

    const openModalBalanceIncome = (walletName, walletAddress) => {
        const data = {
            name: walletName,
            address: walletAddress
        };
        setEachWalletName(data);
        if (walletName === 'USDR') {
            return setUsdrBalanceOutModal(true);
        } else if (walletName === 'RSI TOKEN PRIVATE') {
            return setOpenBuyTokenModal(true);
        } else {
            setBalanceSetModal(true);
        }
    };

    const openModalBalanceOut = (walletName, walletBalance) => {
        const data = {
            name: walletName,
            balance: walletBalance,
        };
        setEachWalletName(data);
        if (walletName === 'USDR')
            return setBalanceOutUsdrModal(true);

        if (walletName === 'SMART')
            return setBalanceOutSmartModal(true);

        if (walletName === 'FIAT RUB' || walletName === 'FIAT USD') {
            return setBalanceOutFiatModal(true);
        }

        setBalanceOutModal(true);
    };

    // Открытие модалки уведомлений
    const handleAlertOpen = (text) => {
        setAlertView(true);
        setAlertText(text);
    };

    const getToNewWallet = (walletName, walletBalance) => {
        setIsBtcShow(false)
        const data = {
            name: walletName,
            balance: walletBalance
        };
        setEachWalletName(data);
        if (walletName == 'USDR') {
            setWithdrawBalanceUsdrModal(true);
        } else if (walletName == 'SMART') {
            setWithdrawBalanceSmartModal(true);
        }
    };

    const getCommission = (value = 'ETH') => {

        apiGet({
            url: `/commissions?to=${value}`,
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            setCommissionSend(res.data);
            setShowInputs(true);
        }).catch(e => {
            console.log(e);
        });
    };

    const recipientAddressOnChange = ({target: {value}}) => {
        getCommission(value)
        setRecipientAddress(value);
        setShowInputs(false);
    };

    const howMuchSumOnChange = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('recipientSum', 0);
            setValue('recipientCommissionSum', 0);
            return;
        }

        if (v === '') {
            setValue('recipientCommissionSum', '');
            return;
        }

        setValue('recipientSum', v);

        let num = +value + commissionSend[eachWalletName.name]?.['TRANSACTION_COMMISSION']?.value;

        setValue('recipientCommissionSum', num);
    };

    const onChangeRecipientCommissionSum = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('recipientSum', 0);
            setValue('recipientCommissionSum', 0);
            return;
        }

        if (v === '') {
            setValue('recipientSum', '');
            return;
        }

        setValue('recipientCommissionSum', v);

        let num = +value - commissionSend[eachWalletName.name]?.['TRANSACTION_COMMISSION']?.value;

        setValue('recipientSum', num);
    }

    const sendOutFromWallet = () => {
        const data = {};

        data.amount = Number(watch('recipientSum'));
        data.to = watch('recipientAddress');

        if (watch('email_Code2'))
            data.emailCode = watch('email_Code2')
        if (watch('sms_Code2'))
            data.smsCode = watch('sms_Code2')
        if (watch('ga_Code2'))
            data.gaCode = watch('ga_Code2')
        setIsButtonEnable(true)
        apiPost({
            url: `/wallet/send-crypto?name=${eachWalletName.name}`,
            postData: data,
        }).then(res => {
            if (res.error) {
                setIsButtonEnable(false)
                return errorHandler(res.data);
            }
            if (res.data === true) {
                closeModal()
                refreshWallet()
                handleAlertOpen('Перевод выполнен успешно');
                setIsRefreshWallet(!isRefreshWallet)
            } else {
                setValidateSendWallet({
                    emailCode: res.data.emailCode,
                    smsCode: res.data.smsCode,
                    gaCode: res.data.gaCode,
                });
                setIsButtonEnable(false)
            }
        });
    };

    const openEachFiatWalletPage = (name, balance) => {
        const data = {name, balance};
        setEachWalletName(data);
        // setNameForEachFiatWalletPage(name)
        setIsShowEachFiatWalletPage(true);
    }

    const openEachWalletPage = (name) => {
        setNameForEachWalletPage(name)
        setIsShowEachWalletPage(true)
        closeModal();
        // history.push(`/wallet/${name}`);
    };

    const renderBillBlock = ({name, balance}) => {
        // if (name === 'USDR')
        //     return;
        // if (!isBtcShow) {
        //     if (name === 'BTC')
        //         return;
        // }
        return (
            <Col key={name} sm={4}>
                <BillBlock
                    name={name}
                    value={balance}
                    activeClass={getActiveClass(name, investWallet)}
                    onClick={handleChangeBillBlock}/>
            </Col>
        );
    };

    const handleChangeBillBlock = (value) => {
        setInvestWallet(value);
        reset({
            incomePay: '',
            cryproToUsdrPay: '',
            usdrCounter: '',
            cryptoCounter: '',
            IdForUserUsdr: '',
            IdForUserSmart: '',
            IdForUserFiat: '',
            sumForUserUsdr: '',
            sumForUserSmart: '',
            recipientAddress: '',
            recipientSum: '',
            recipientCommissionSum: '',
            code: '',
            codeAuth: '',
        });
    };

    const maxValueSum = () => {
        let value = walletsData.filter(item => item.name === investWallet);
        setValue('incomePay', value[0].balance);
        getCommission(value[0].address);
        setValue(
            'cryproToUsdrPay',
            commissionSend[investWallet]['REPLENISH_RATE'].value * value[0].balance
        );
    };

    const changeInputUsdrWithCommission = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('incomePay', 0);
            setValue('cryproToUsdrPay', 0);
            return;
        }

        if (v === '') {
            setValue('incomePay', '');
            return;
        }

        setValue('cryproToUsdrPay', v);

        setValue(
            'incomePay',
            value / commissionSend[investWallet]['REPLENISH_RATE'].value
        );
    };

    const changeInputUsdr = (e) => {
        let v = e.target.value.replace(/^0+/, '');
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('incomePay', 0);
            setValue('cryproToUsdrPay', 0);
            return;
        }

        if (v === '') {
            setValue('cryproToUsdrPay', '');
            return;
        }

        setValue('incomePay', v);

        setValue(
            'cryproToUsdrPay',
            commissionSend[investWallet]['REPLENISH_RATE'].value * e.target.value);
    };

    const getTokenPrivateCount = (value) => {
        let price = 1;
        if (value >= 21000) {
            price = 0.7;
        } else if (value >= 12750) {
            price = 0.85;
        }

        return Math.floor(value / price);
    }

    const getTokenPrivatePrice = (value) => {
        let price = 1;
        if (value >= 30000) {
            price = 0.7;
        } else if (value >= 15000) {
            price = 0.85;
        }

        return (value * price).toFixed(2);
    }

    const changeTokenPrivateCount = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('tokenPrivateCount', 0);
            setValue('tokenPrivatePrice', 0);
            return;
        }

        if (v === '') {
            setValue('tokenPrivatePrice', '');
            return;
        }

        setValue('tokenPrivateCount', v);

        setValue('tokenPrivatePrice', getTokenPrivatePrice(value));
    }

    const changeTokenPrivatePrice = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('tokenPrivateCount', 0);
            setValue('tokenPrivatePrice', 0);
            return;
        }

        if (v === '') {
            setValue('tokenPrivateCount', '');
            return;
        }

        setValue('tokenPrivatePrice', v);

        setValue('tokenPrivateCount', getTokenPrivateCount(value));
    }

    const buyTokenAllIn = () => {
        let wallets = walletsData.filter(item => item.name === investWallet);
        let balance = wallets[0].balance;
        let tokenPrivateCount = getTokenPrivateCount(balance);
        setValue('tokenPrivateCount', tokenPrivateCount);
        setValue('tokenPrivatePrice', getTokenPrivatePrice(tokenPrivateCount));
    }

    const buyTokenSendForm = () => {
        let amount = watch('tokenPrivateCount');

        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/buy-token?name=${investWallet}`,
            postData: {amount: amount},
        }).then(res => {
            if (res.error) {
                setIsButtonEnable(false)
                return setError(res.data);
            }
            refreshWallet();
            closeModal();
            handleAlertOpen('Покупка успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        });
    }

    const getUserInfoById = id => {
        apiGet({
            url: `/user/user-info-by-id?id=${id}`
        }).then(res => {
            if (res.error)
                return console.log(res.data)
            setUserInfoById(res.data);
        });
    };

    const handleUsdrOutCryptoChangeWithComission = ({target: {value}}) => {
        if (commissionSend === null)
            getCommission(value);

        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('usdrCounter', 0);
            setValue('cryptoCounter', 0);
            return;
        }

        if (v === '') {
            setValue('cryptoCounter', '');
            return;
        }

        setValue('usdrCounter', v);

        let commiss = (value * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);
        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            commiss = commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;
            setValue('commissionCryptoCounter', `${commiss}USDR`);
        }

        const res = (value - commiss) / commissionSend[investWallet]['PAYOUT_RATE'].value;

        if (res < 0 || value < 0)
            return;
        setValue('cryptoCounter', res);
    };

    const handleSmartOutCryptoChangeWithComission = ({target: {value}}) => {
        const res = value / 5;

        if (res < 0 || value < 0)
            return;
        setValue('cryptoCounterSmart', res);
    };

    const handleCryptoFromUsdr = ({target: {value}}) => {
        if (commissionSend === null)
            getCommission(value);

        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('usdrCounter', 0);
            setValue('cryptoCounter', 0);
            return;
        }

        if (v === '') {
            setValue('usdrCounter', '');
            return;
        }

        setValue('cryptoCounter', v);

        let source = (value * commissionSend[investWallet]['PAYOUT_RATE'].value) /
            ((100 - commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100);
        let commiss = (source * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);

        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            source = value * commissionSend[investWallet]['PAYOUT_RATE'].value +
                commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;

            setValue('commissionCryptoCounter',
                `${commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value}USDR`);
        }

        if (source < 0 || value < 0)
            return;
        setValue('usdrCounter', source);
    };

    const handleCryptoFromSmart = ({target: {value}}) => {
        setValue('smartCounter', value * 5);
    };

    const windrawFromUsdr = () => {
        setLoaderSend(true);
        let sum = 0;

        if (investWallet)
            sum = watch('usdrCounter');

        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/payout?name=${investWallet}`,
            postData: {amount: sum},
        }).then(res => {
            if (res.error) {
                setIsButtonEnable(false)
                return setError(res.data);
            }
            closeModal();
            refreshWallet();
            handleAlertOpen('Вывод выполнен успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        }).then(() => setLoaderSend(false))
            .catch(e => {
                console.log(e);
            });
    };

    const windrawFromSmart = () => {
        setLoaderSend(true);
        let sum = 0;

        if (investWallet)
            sum = watch('smartCounter');

        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/smart-payout?name=${investWallet}`,
            postData: {amount: sum},
        }).then(res => {
            if (res.error) {
                setIsButtonEnable(false)
                return setError(res.data);
            }
            closeModal();
            refreshWallet();
            handleAlertOpen('Вывод выполнен успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        }).then(() => setLoaderSend(false))
            .catch(e => {
                console.log(e);
            });
    };

    const maxUsdrValueSum = () => {
        // setValue('sumForUser', walletsData[0].balance);
        setValue('sumForUserUsdr', eachWalletName.balance);
    };

    const maxSmartValueSum = () => {
        // setValue('sumForUser', walletsData[0].balance);
        setValue('sumForUserSmart', eachWalletName.balance);
    };

    const maxFiatValueSum = () => {
        // setValue('sumForUser', walletsData[0].balance);
        setValue('sumForUserFiat', eachWalletName.balance);
    };

    const errorHandler = (data) => {
        let arr = []
        if (typeof data === 'string')
            arr.push(data)
        else {
            for (let key in data) {
                if (key === 'to')
                    return setError(data)
                if (key === 'amount')
                    return setError(data)
                if (key === 'sum')
                    return setError(data)
                data[key].map(item => {

                    arr.push(item)
                })
            }
        }
        setErrorFromHandler(arr)
    }

    const sendUsdrToUser = () => {
        setError({})
        const recipient_id = watch('IdForUserUsdr');
        const sum = watch('sumForUserUsdr');

        let formData = new FormData();

        if (recipient_id)
            formData.append('recipient_id', recipient_id);

        if (sum)
            formData.append('sum', sum);

        // Авторизация email / ga / sms
        if (validateSendWallet.emailCode)
            formData.append('emailCode', watch('email_Code'));

        if (validateSendWallet.smsCode)
            formData.append('smsCode', watch('sms_Code'));

        if (validateSendWallet.gaCode)
            formData.append('gaCode', watch('ga_Code'));
        setIsButtonEnable(true)
        apiPost({
            url: '/wallet/send-usdr',
            postData: formData,
        }).then(res => {
                if (res.error) {
                    setIsButtonEnable(false)
                    return errorHandler(res.data);
                }
                // return setError(res.data);
                if (res.data === true) {
                    setValidateSendWallet({sms: false, email: false, ga: false});
                    handleAlertOpen('Средства успешно отправлены');
                    closeModal();
                    refreshWallet();
                } else {
                    setValidateSendWallet({
                        smsCode: res.data.smsCode,
                        emailCode: res.data.emailCode,
                        gaCode: res.data.gaCode,
                    });
                    setIsButtonEnable(false)
                }
            }
        );
    };

    const sendSmartToUser = () => {
        setError({})
        const recipient_id = watch('IdForUserSmart');
        const sum = watch('sumForUserSmart');

        let formData = new FormData();

        if (recipient_id)
            formData.append('recipient_id', recipient_id);

        if (sum)
            formData.append('sum', sum);

        // Авторизация email / ga / sms
        if (validateSendWallet.emailCode)
            formData.append('emailCode', watch('emailCodeSmart'));

        if (validateSendWallet.smsCode)
            formData.append('smsCode', watch('smsCodeSmart'));

        if (validateSendWallet.gaCode)
            formData.append('gaCode', watch('gaCodeSmart'));
        setIsButtonEnable(true)
        apiPost({
            url: '/wallet/send-smart',
            postData: formData,
        }).then(res => {
                if (res.error) {
                    setIsButtonEnable(false)
                    return errorHandler(res.data);
                }
                // return setError(res.data);
                if (res.data === true) {
                    setValidateSendWallet({sms: false, email: false, ga: false});
                    handleAlertOpen('Средства успешно отправлены');
                    closeModal();
                    refreshWallet();
                } else {
                    setValidateSendWallet({
                        smsCode: res.data.smsCode,
                        emailCode: res.data.emailCode,
                        gaCode: res.data.gaCode,
                    });
                    setIsButtonEnable(false)
                }
            }
        );
    };

    const sendFiatToUser = (wallet) => {
        setError({})
        const recipient_id = watch('IdForUserFiat');
        const sum = watch('sumForUserFiat');

        let formData = new FormData();

        if (recipient_id)
            formData.append('recipient_id', recipient_id);

        if (sum)
            formData.append('sum', sum);

        // Авторизация email / ga / sms
        if (validateSendWallet.emailCode)
            formData.append('emailCode', watch('emailCodeFiat'));

        if (validateSendWallet.smsCode)
            formData.append('smsCode', watch('smsCodeFiat'));

        if (validateSendWallet.gaCode)
            formData.append('gaCode', watch('gaCodeFiat'));
        setIsButtonEnable(true)
        apiPost({
            url: `/wallet/send-fiat?wallet=${wallet}`,
            postData: formData,
        }).then(res => {
              if (res.error) {
                  setIsButtonEnable(false)
                  return errorHandler(res.data);
              }
              // return setError(res.data);
              if (res.data === true) {
                  setValidateSendWallet({sms: false, email: false, ga: false});
                  handleAlertOpen('Средства успешно отправлены');
                  closeModal();
                  refreshWallet();
              } else {
                  setValidateSendWallet({
                      smsCode: res.data.smsCode,
                      emailCode: res.data.emailCode,
                      gaCode: res.data.gaCode,
                  });
                  setIsButtonEnable(false)
              }
          }
        );
    };

    const maxSendValueSum = () => {
        let value = walletsData.filter(item => item.name === eachWalletName.name);
        // getCommission(value[0].address);
        setValue('recipientSum', value[0].balance - commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value);
        setValue(
            'recipientCommissionSum',
            // commissionSend[investWallet]['REPLENISH_RATE'].value * value[0].balance
            // +value[0].balance + commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value * value[0].balance
            value[0].balance
        );
    }

    const maxOutUsdrValueSum = () => {
        setValue('usdrCounter', eachWalletName.balance)

        let commiss = (eachWalletName.balance * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);
        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            commiss = commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;
            setValue('commissionCryptoCounter', `${commiss}USDR`);
        }

        const res = (eachWalletName.balance - commiss) / commissionSend[investWallet]['PAYOUT_RATE'].value;

        if (res < 0 || eachWalletName.balance < 0)
            return;
        setValue('cryptoCounter', res);
    }

    const maxOutSmartValueSum = () => {
        setValue('smartCounter', eachWalletName.balance)

        const res = (eachWalletName.balance) / 5;

        if (res < 0 || eachWalletName.balance < 0)
            return;
        setValue('cryptoCounterSmart', res);
    }

    const windrawUsdrWallet = () => {
        let sum = 0;
        sum = watch('cryproToUsdrPay');
        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/replenish?name=${investWallet}`,
            postData: {amount: sum},
        }).then(res => {
            if (res.error) {
                setIsButtonEnable(false)
                return setError(res.data);
            }
            refreshWallet();
            closeModal();
            handleAlertOpen('Пополнение успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        });
    };

    const throttledUserIdUsdr = useDebounce(watch('IdForUserUsdr'), 300);
    const throttledUserIdSmart = useDebounce(watch('IdForUserSmart'), 300);
    const throttledUserIdFiat = useDebounce(watch('IdForUserFiat'), 300);

    useEffect(() => {
        if (commissionSend === null)
            getCommission();
        getUserInfoById(throttledUserIdUsdr);
    }, [throttledUserIdUsdr]);

    useEffect(() => {
        if (commissionSend === null)
            getCommission();
        getUserInfoById(throttledUserIdSmart);
    }, [throttledUserIdSmart]);

    useEffect(() => {
        if (commissionSend === null)
            getCommission();
        getUserInfoById(throttledUserIdFiat);
    }, [throttledUserIdFiat]);

    const openModalFiatEnterOrder = (name, address) => {
        const data = {name, address};
        setEachWalletName(data);
        setOpenFiatEnterModal(true);
    }

    const fiatEnterOrder = () => {
        const data = {};

        let officeId = watch('officeIdInput');
        let communicationMethod = watch('communicationMethodInput');

        data.amount = watch('fiatAmount');
        data.currency = eachWalletName.name
        data.method = inputPaymentMethodList[fiatInputPaymentMethod];
        if (officeId) {
            data.officeId = officeId;
        }

        if (communicationMethod) {
            data.communicationMethod = communicationMethod;
        }

        setIsFiatEnterSpinner(true)
        apiPost({
            url: `/wallet/fiat-input`,
            postData: data,
        }).then(res => {
            if(res.error){
                setIsFiatEnterSpinner(false);
                return setError(res.data);
            }
            setIsFiatEnterSpinner(false)
            // setOpenFiatInputContract(true)
            const dataOfFiat = {
                isHas: true,
                link: res.data.link
            }
            setOpenFiatInputContract(dataOfFiat)
            // window.open(res.data.link, '_blank');
        })
    }

    const openFiatEnterOrder = () => {
        window.open(openFiatInputContract.link, '_blank');
    }

    const openModalFiatOutPayming = (name, address) => {
        const data = {name, address};
        setEachWalletName(data);
        setOpenFiatOutPaymingModal(true);
    }

    const fiatOutPay = () => {
        const formData = new FormData();

        let bankCardFiatOut = watch('bankCardFiatOut');
        let officeId = watch('officeIdOutput');
        let communicationMethod = watch('communicationMethodOutput');

        formData.append('amount', watch('fiatOutAmount'));
        formData.append('currency', eachWalletName.name);
        formData.append('method', outputPaymentMethodList[fiatOutputPaymentMethod]);

        if (bankCardFiatOut)
            formData.append('card', bankCardFiatOut);

        if (officeId)
            formData.append('officeId', officeId);

        if (communicationMethod)
            formData.append('communicationMethod', communicationMethod);

        // Авторизация email / ga / sms
        if (validateSendWallet.emailCode)
            formData.append('emailCode', watch('emailCodeFiatOutput'));

        if (validateSendWallet.smsCode)
            formData.append('smsCode', watch('smsCodeFiatOutput'));

        if (validateSendWallet.gaCode)
            formData.append('gaCode', watch('gaCodeFiatOutput'));

        setSpinnerFiatOutPay(true)

        apiPost({
            url: `/wallet/fiat-output`,
            postData: formData,
        }).then(res => {
            if (res.error) {
                setSpinnerFiatOutPay(false)
                return setError(res.data)
            }

            if (res.data.id) {
                setOpenFiatOutPaymingModal(false)
                if (fiatOutputPaymentMethod === FIAT_INPUT_PAYMENT_METHOD_ONLINE) {
                    setAlertText('Заявка на вывод успешно отправлена. Средства поступят на карту после прохождения проверки или будут возвращены на фиатный счет в случае неудачи.');
                } else {
                    setAlertText('Заявка на вывод успешно сформирована. Мы свяжемся с Вами в ближайшее время.');
                }
                setAlertView(true);
                setSpinnerFiatOutPay(false);
                refreshWallet();
            } else {
                setValidateSendWallet({
                    smsCode: res.data.smsCode,
                    emailCode: res.data.emailCode,
                    gaCode: res.data.gaCode,
                });
                setSpinnerFiatOutPay(false);
            }
        })
    }

    const onChangeFiatInput = (e) => {
        let v = e.target.value.replace(/^0+/, '');
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('fiatAmount', 0);
            setValue('fiatInputCommissions', 0);
            return;
        }

        if (v === '') {
            setValue('fiatInputCommissions', '');
            return;
        }

        setValue('fiatAmount', v);

        let wallet = null;
        fiatWalletsData.map(item => {
            if (item.name === eachWalletName.name) {
                wallet = item;
            }
        })

        if (wallet === null) {
            console.log('Wallet ' + eachWalletName.name + ' is not supported');
            return;
        }

        const commission = wallet.inputMethodsInfo[fiatInputPaymentMethod].commission;
        setValue('fiatInputCommissions', Number(e.target.value) + ((Number(e.target.value) * commission) / 100))
    }

    const onChangeFiatInputCommission = (e) => {
        let v = e.target.value.replace(/^0+/, '');
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('fiatAmount', 0);
            setValue('fiatInputCommissions', 0);
            return;
        }

        if (v === '') {
            setValue('fiatAmount', '');
            return;
        }

        setValue('fiatInputCommissions', v);

        let wallet = null;
        fiatWalletsData.map(item => {
            if (item.name === eachWalletName.name) {
                wallet = item;
            }
        })

        const commission = wallet.inputMethodsInfo[fiatInputPaymentMethod].commission;
        setValue('fiatAmount', Number(e.target.value) / (1 + commission / 100))
    }

    const onChangeInputFiatOutPay = (e) => {
        let v = e.target.value.replace(/^0+/, '');
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('fiatOutAmount', 0);
            setValue('bankCardFiatOutCommission', 0);
            return;
        }

        if (v === '') {
            setValue('bankCardFiatOutCommission', '');
            return;
        }

        setValue('fiatOutAmount', v);

        let wallet = null;
        fiatWalletsData.map(item => {
            if (item.name === eachWalletName.name) {
                wallet = item;
            }
        })

        const commission = wallet.outputMethodsInfo[fiatOutputPaymentMethod].commission;
        setValue('bankCardFiatOutCommission', Number(e.target.value) - Number(e.target.value) * commission / 100)
    }

    const  onChangeFiatOutCommission = (e) => {
        let v = e.target.value.replace(/^0+/, '');
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('fiatOutAmount', 0);
            setValue('bankCardFiatOutCommission', 0);
            return;
        }

        if (v === '') {
            setValue('fiatOutAmount', '');
            return;
        }

        setValue('bankCardFiatOutCommission', v);

        let wallet = null;
        fiatWalletsData.map(item => {
            if (item.name === eachWalletName.name) {
                wallet = item;
            }
        })

        const commission = wallet.outputMethodsInfo[fiatOutputPaymentMethod].commission;
        setValue('fiatOutAmount', Number(e.target.value) / (1 - commission / 100))
    }

    const openModalWalletsChoice = () => {
        apiGet({
            url: `/wallet/status`
        }).then(res => {
            if (res.error)
                return

            setWalletsForChoice(res.data);

            setCryptoWalletsChoiceModal(true);
        })
    }

    const choiceWalletSetStatus = (wallet) => {
        apiPost({
            url: `/wallet/set-status?wallet=${wallet.wallet}`,
            postData: {status: !wallet.status},
        }).then(res => {
            wallet.status = !wallet.status;
            if (res.data !== true) {
                closeModal();
                handleAlertOpen('Произошла ошибка. Обратитесь в поддержку!');
            }
        });
    }

    const inputFiat = (wallet) => {
        closeModal();
        setEachWalletName(wallet);
        setChoiceFiatInputMethodModal(true);
    }

    const sendFiatInputPaymentMethod = () => {
        closeModal();
        openModalFiatEnterOrder(eachWalletName?.name, eachWalletName?.address);
    }

    const outputFiat = (wallet) => {
        closeModal();
        setEachWalletName(wallet);
        setChoiceFiatOutputMethodModal(true);
    }

    const sendFiatOutputPaymentMethod = () => {
        closeModal();
        openModalFiatOutPayming(eachWalletName?.name, eachWalletName?.address);
    }

    const buyCryptoViaFiatRub = () => {
        apiGet({
            url: '/buy-crypto/rate'
        }).then(res => {
            if (res.error !== false) {
                setAlertView(true);
                setAlertText('Произошла ошибка при определении курса');
            }

            setFiatRubToUsdtRate(res.data);
        })

        closeModal();
        setBuyCryptoViaFiatRubModal(true);
    }

    const onChangeBuyCryptoFiatAmount = (e) => {
        if (fiatRubToUsdtRate !== null) {
            if (e.target.value >= 100) {
                apiGet({
                    url: `/buy-crypto/rate?amount=${e.target.value}`
                }).then(res => {
                    if (res.error !== false) {
                        setAlertView(true);
                        setAlertText('Произошла ошибка при определении курса');
                    }

                    setFiatRubToUsdtRate(res.data);

                    setValue('buyCryptoCryptoAmount', (e.target.value * res.data).toFixed(2))
                })
            } else {
                setValue('buyCryptoCryptoAmount', null)
            }
        }
    }

    const onChangeBuyCryptoCryptoAmount = (e) => {
        if (fiatRubToUsdtRate !== null) {
            if (e.target.value >= 1) {
                apiGet({
                    url: `/buy-crypto/rate?amount=${(e.target.value / fiatRubToUsdtRate)}`
                }).then(res => {
                    if (res.error !== false) {
                        setAlertView(true);
                        setAlertText('Произошла ошибка при определении курса');
                    }

                    if (fiatRubToUsdtRate < res.data) {
                        apiGet({
                            url: `/buy-crypto/rate?amount=${(e.target.value / res.data)}`
                        }).then(res => {
                            if (res.error !== false) {
                                setAlertView(true);
                                setAlertText('Произошла ошибка при определении курса');
                            }

                            setFiatRubToUsdtRate(res.data);
                            setValue('buyCryptoFiatAmount', (e.target.value / res.data).toFixed(2));
                        })
                    } else {
                        setFiatRubToUsdtRate(res.data);
                        setValue('buyCryptoFiatAmount', (e.target.value / res.data).toFixed(2));
                    }
                })
            } else {
                setValue('buyCryptoFiatAmount', null);
            }
        }
    }

    const buyCryptoViaFiatRubSendForm = () => {
        apiPost({
            url: '/wallet/fiat-input',
            postData: {amount: watch('buyCryptoFiatAmount'), currency: 'FIAT RUB', 'method': 3},
        }).then(res => {
            if (res.error === true) {
                setIsButtonEnable(false)
                return setError(res.data);
            }

            closeModal();
            setBuyCryptoViaFiatRubCard(res.data);
            setCardInfoForBuyCryptoViaFiatRubModal(true);
        });
    }

    const onChangeUsdrSend = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = value.replace(/,/g, '.');

        if (v < 0) {
            setValue('sumForUserUsdr', 0);
            return;
        }

        if (v === '') {
            return;
        }

        setValue('sumForUserUsdr', v);
    }

    const onChangeFiatSend = ({target: {value}}) => {
        // let v = value.replace(/^0+/, '');
        let v = value;
        v = v.replace(/,/g, '.');

        if (v < 0) {
            setValue('sumForUserFiat', 0);
            return;
        }

        if (v === '') {
            return;
        }

        setValue('sumForUserFiat', v);
    }

    const getGarantOffices = () => {
        if (garantOffices !== null) {
            return garantOffices;
        }

        apiGet({
            url: '/garant/office-list'
        }).then(res => {
            if (res.error !== false) {
                setAlertView(true);
                setAlertText('Произошла ошибка при получении списка офисов');
            }

            let offices = [];
            res.data.map(item => {
                const data = {};
                data.label = item.name;
                data.value = item.id;
                offices.push(data);
            })

            setGarantOffices(offices);
            return offices;
        })
    }

    return (
        <div>
            {(!isShowEachWalletPage && !isShowEachFiatWalletPage) &&
                <div>
                    <div className="row">
                        <div className="col-12">
                            <Card className="mt-5">
                                <CardBody
                                    className="p-5 flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                                    <Icon name="info" size={34} color="#7c61bd"/>
                                    <div className="ml-4" style={{maxWidth: '750px'}}>
                                        Если у Вас нет криптовалюты и Вы не знаете, где можно её
                                        приобрести, мы рекомендуем Вам{' '}
                                        <a
                                            href="https://t.me/rsi_garant"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="link">
                                            RSI GARANT
                                        </a>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <Card className="mt-5">
                                <CardBody
                                    className="p-5 flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                                    <Icon name="info" size={34} color="#7c61bd"/>
                                    <div className="ml-4" style={{maxWidth: '750px'}}>
                                        Уважаемые пользователи!
                                        В настоящий момент все вводы криптовалюты приостановлены. Выводы работают в ручном режиме,
                                        поэтому возможны задержки. Приносим извинения за доставленные неудобства.
                                        Если у Вас есть вопросы, пожалуйста, обратитесь в поддержку.
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div className={'overflow-x-auto m-2'}>
                        {investWalletUsdr === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                            <div
                                className={'transition delay-80 h-20 flex w-full justify-between items-center bg-white hover:bg-grey p-2'}>
                                <div className={'flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full'}
                                     onClick={() => openEachWalletPage(investWalletUsdr?.name)}>
                                    {/*<div className={'whitespace-nowrap cursor-pointer hidden md:block'}*/}
                                    <div className={'whitespace-nowrap cursor-pointer'}
                                         onClick={() => openEachWalletPage(investWalletUsdr?.name)}>
                                        <img alt={investWalletUsdr?.name} className={'h-6 md:h-10 sm:h-8'} src={logo}/>
                                    </div>

                                    <div>
                                        <strong className={'mr-1'}>
                                            {investWalletUsdr?.name}
                                        </strong>
                                    </div>
                                </div>

                                <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                    <div className={'cursor-pointer'}
                                         onClick={() => openEachWalletPage(investWalletUsdr?.name)}>
                                        <div
                                            className={'mr-9 sm:mr-14 md:mr-28 cursor-pointer'}
                                            onClick={() => openEachWalletPage(investWalletUsdr?.name)}>
                                            {parseFloat(investWalletUsdr?.balance).toFixed(2)}
                                        </div>
                                    </div>
                                    <button
                                        data-for="setBalance"
                                        data-tip={'Вывести'}
                                        onClick={() => getToNewWallet(investWalletUsdr?.name, investWalletUsdr?.balance)}
                                        className="items-center sm:text-text text-sm">
                                        <div className='sm:hidden'>
                                            <Icon name="walletOut" size={15}/>
                                        </div>
                                        <div className='hidden sm:block'>
                                            <Icon name="walletOut" size={22}/>
                                        </div>
                                    </button>

                                    <button
                                        data-for="setBalance"
                                        data-tip={'Пополнить'}
                                        onClick={() => openModalBalanceIncome(investWalletUsdr?.name, investWalletUsdr?.address)}
                                        className="items-center">

                                        <div className='sm:hidden'>
                                            <Icon name="walletIn" size={15}/>
                                        </div>
                                        <div className='hidden sm:block'>
                                            <Icon name="walletIn" size={22}/>
                                        </div>
                                    </button>

                                    <button
                                        data-for="setBalance"
                                        data-tip={'Отправить'}
                                        onClick={() => openModalBalanceOut(investWalletUsdr?.name, investWalletUsdr?.balance)}
                                        className="items-center sm:text-text text-sm">

                                        <div className='sm:hidden'>
                                            <Icon name="walletSend" size={15}/>
                                        </div>
                                        <div className='hidden sm:block'>
                                            <Icon name="walletSend" size={22}/>
                                        </div>
                                    </button>
                                </div>
                                <ReactTooltip
                                    id="setBalance"
                                    globalEventOff="click"
                                    className="tooltip-modal"
                                    effect="solid"/>
                            </div>}

                        {tokenPrivateWallet === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                            process.env.REACT_APP_MODE == 'default' ? <div
                            className={'transition delay-80 h-20 flex w-full justify-between items-center bg-white hover:bg-grey p-2'} style={{marginTop: '10px'}}>
                              <div className={'flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full'}
                                   onClick={() => openEachWalletPage(tokenPrivateWallet?.name)}>
                                  {/*<div className={'whitespace-nowrap cursor-pointer hidden md:block'}*/}
                                  <div className={'whitespace-nowrap cursor-pointer'}
                                       onClick={() => openEachWalletPage(tokenPrivateWallet?.name)}>
                                      <img alt={tokenPrivateWallet?.name} className={'h-6 md:h-10 sm:h-8'} src={logo}/>
                                  </div>

                                  <div>
                                      <strong className={'mr-1'}>
                                          {tokenPrivateWallet?.name}
                                      </strong>
                                  </div>
                              </div>

                              <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                  <div className={'cursor-pointer'}
                                       onClick={() => openEachWalletPage(tokenPrivateWallet?.name)}>
                                      <div
                                        className={'mr-9 sm:mr-14 md:mr-28 cursor-pointer'}
                                        onClick={() => openEachWalletPage(tokenPrivateWallet?.name)}>
                                          {parseFloat(tokenPrivateWallet?.balance).toFixed(2)}
                                      </div>
                                  </div>

                                  <div className={'mr-4 sm:mr-6'}></div>

                                  <button
                                    data-for="setBalance"
                                    data-tip={'Купить'}
                                    onClick={() => openModalBalanceIncome(tokenPrivateWallet?.name, tokenPrivateWallet?.address)}
                                    className="items-center">

                                      <div className='sm:hidden'>
                                          <Icon name="walletIn" size={15}/>
                                      </div>
                                      <div className='hidden sm:block'>
                                          <Icon name="walletIn" size={22}/>
                                      </div>
                                  </button>

                                  <div className={'mr-4 sm:mr-6'}></div>

                              </div>
                              <ReactTooltip
                                id="setBalance"
                                globalEventOff="click"
                                className="tooltip-modal"
                                effect="solid"/>
                          </div> : ''}


                        {smartWallet === null || process.env.REACT_APP_MODE != 'default' ? '' :
                            <div
                                className={'transition delay-80 h-20 flex w-full justify-between items-center bg-white hover:bg-grey p-2'} style={{marginTop: '10px'}}>
                                <div className={'flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full'}
                                     onClick={() => openEachWalletPage(smartWallet?.name)}>
                                    {/* <div className={'whitespace-nowrap cursor-pointer hidden md:block'} */}
                                    <div className={'whitespace-nowrap cursor-pointer'}
                                         onClick={() => openEachWalletPage(smartWallet?.name)}>
                                        <img alt={smartWallet?.name} className={'h-6 md:h-10 sm:h-8'} src={logo}/>
                                    </div>

                                    <div>
                                        <strong>
                                            {smartWallet?.name}
                                        </strong>
                                    </div>
                                </div>

                                <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                    <div className={'cursor-pointer'}
                                         onClick={() => openEachWalletPage(smartWallet?.name)}>
                                        <div
                                            className={'mr-9 sm:mr-14 md:mr-28 cursor-pointer'}
                                            onClick={() => openEachWalletPage(smartWallet?.name)}>
                                            {parseFloat(smartWallet?.balance).toFixed(2)}
                                        </div>
                                    </div>

                                    <button
                                        data-for="setBalance"
                                        data-tip={'Вывести на USDT'}
                                        onClick={() => getToNewWallet(smartWallet?.name, smartWallet?.balance)}
                                        className="items-center sm:text-text text-sm">
                                        <div className='sm:hidden'>
                                            <Icon name="walletOut" size={15}/>
                                        </div>
                                        <div className='hidden sm:block'>
                                            <Icon name="walletOut" size={22}/>
                                        </div>
                                    </button>

                                    <div className={'mr-4 sm:mr-6'}></div>

                                    <button
                                        data-for="setBalance"
                                        data-tip={'Отправить'}
                                        onClick={() => openModalBalanceOut(smartWallet?.name, smartWallet?.balance)}
                                        className="items-center sm:text-text text-sm">

                                        <div className='sm:hidden'>
                                            <Icon name="walletSend" size={15}/>
                                        </div>
                                        <div className='hidden sm:block'>
                                            <Icon name="walletSend" size={22}/>
                                        </div>
                                    </button>
                                </div>
                                <ReactTooltip
                                    id="setBalance"
                                    globalEventOff="click"
                                    className="tooltip-modal"
                                    effect="solid"/>
                            </div>}


                        {/*Start ------- Fiat wallet data*/}
                        <div>
                            <div className='bg-bgDark my-2 p-3'><strong>Фиатные кошельки</strong></div>
                            {walletsData === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                                <>
                                    {fiatWalletsData?.map(wallet =>
                                        <div key={wallet?.name}>
                                            <div
                                                className={'transition delay-80 h-16 flex w-full justify-between items-center bg-white hover:bg-grey p-2 my-2'}>
                                                <div onClick={() => openEachFiatWalletPage(wallet?.name, wallet?.balance)}
                                                     className='flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full mr-1'>
                                                    <div
                                                        className='whitespace-nowrap cursor-pointer'>
                                                        <img alt={wallet?.name}
                                                             className={'h-6 w-6 md:h-10 md:w-10 sm:h-8 sm:w-8'}
                                                             src={wallet?.icon === null ? logo : wallet?.icon }/>
                                                    </div>

                                                    <div onClick={() => openEachFiatWalletPage(wallet?.name, wallet?.balance)}
                                                         className="whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                                        <strong>
                                                            {wallet?.name}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                                    <div className={'cursor-pointer'}
                                                         onClick={() => openEachFiatWalletPage(wallet?.name, wallet?.balance)}>
                                                        <div
                                                            onClick={() => openEachFiatWalletPage(wallet?.name, wallet?.balance)}
                                                            className="sm:block mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer">
                                                            {wallet?.balance}
                                                        </div>
                                                        {/*<div*/}
                                                        {/*    data-for="setBalanceFiat"*/}
                                                        {/*    data-tip={wallet?.balance}*/}
                                                        {/*    onClick={() => openEachWalletPage(wallet.name)}*/}
                                                        {/*    className="sm:hidden mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer ">*/}
                                                        {/*    {parseFloat(wallet?.balance).toFixed(5)}*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className={'mr-4 sm:mr-6'}></div>
                                                    {/*<button*/}
                                                    {/*    data-for="setBalanceFiat"*/}
                                                    {/*    data-tip={'Запросить ввод на кошелек'}*/}
                                                    {/*    // onClick={() => openModalFiatEnterOrder(wallet?.name, wallet?.address)}*/}
                                                    {/*    onClick={() => inputFiat(wallet)}*/}
                                                    {/*    className="items-center">*/}
                                                    {/*    <div className='sm:hidden'>*/}
                                                    {/*        <Icon name="walletIn" size={15}/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className='hidden sm:block'>*/}
                                                    {/*        <Icon name="walletIn" size={22}/>*/}
                                                    {/*    </div>*/}
                                                    {/*</button>*/}

                                                    <button
                                                        data-for="setBalance"
                                                        data-tip={'Отправить'}
                                                        onClick={() => openModalBalanceOut(wallet?.name, wallet?.balance)}
                                                        className="items-center sm:text-text text-sm">

                                                        <div className='sm:hidden'>
                                                            <Icon name="walletSend" size={15}/>
                                                        </div>
                                                        <div className='hidden sm:block'>
                                                            <Icon name="walletSend" size={22}/>
                                                        </div>
                                                    </button>

                                                    <div className={'mr-4 sm:mr-6'}></div>
                                                    {/*<button*/}
                                                    {/*    data-for="setBalanceFiat"*/}
                                                    {/*    data-tip={'Вывод фиата'}*/}
                                                    {/*    // onClick={() => openModalFiatOutPayming(wallet?.name, wallet?.address)}*/}
                                                    {/*    onClick={() => outputFiat(wallet)}*/}
                                                    {/*    className="items-center">*/}
                                                    {/*    <div className='sm:hidden'>*/}
                                                    {/*        <Icon name="walletOut" size={15}/>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className='hidden sm:block'>*/}
                                                    {/*        <Icon name="walletOut" size={22}/>*/}
                                                    {/*    </div>*/}
                                                    {/*</button>*/}
                                                </div>
                                            </div>

                                            <ReactTooltip
                                                id="setBalanceFiat"
                                                globalEventOff="click"
                                                className="tooltip-modal"
                                                effect="solid"/>
                                        </div>)}
                                </>}
                        </div>
                        {/*End ------- Fiat wallet data*/}

                        {/*Start ------Crypto wallets*/}
                        <div>
                            <div className='bg-bgDark my-2 p-3'>
                                <strong>Криптовалютные кошельки</strong>
                                <button
                                  data-for="cryptoWalletsChoice"
                                  data-tip={'Выбрать'}
                                  onClick={() => openModalWalletsChoice()}
                                  className="items-center"
                                  style={{marginLeft: '15px'}}
                                >
                                    Выбрать
                                    {/*<div className='sm:hidden'>*/}
                                    {/*    <Icon name="wallet" size={15}/>*/}
                                    {/*</div>*/}
                                    {/*<div className='hidden sm:block'>*/}
                                    {/*    <Icon name="wallet" size={22}/>*/}
                                    {/*</div>*/}
                                </button>
                            </div>
                            {walletsData === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                                <>
                                    {walletsData?.map(wallet =>
                                        <div key={wallet?.name}>
                                            <div
                                                className={'transition delay-80 h-16 flex w-full justify-between items-center bg-white hover:bg-grey p-2 my-2'}>
                                                <div onClick={() => openEachWalletPage(wallet?.name)}
                                                     className='flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full mx-1'>
                                                    <div
                                                        className='whitespace-nowrap cursor-pointer'>
                                                        <img alt={wallet?.name}
                                                             className={'w-6 md:w-10 sm:w-8'}
                                                             src={wallet?.icon}/>
                                                    </div>

                                                    <div onClick={() => openEachWalletPage(wallet?.name)}
                                                         className="whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                                        <strong>
                                                            {wallet?.name}
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                                    <div className={'cursor-pointer'}
                                                         onClick={() => openEachWalletPage(wallet?.name)}>
                                                        <div
                                                            onClick={() => openEachWalletPage(wallet?.name)}
                                                            className="hidden sm:block mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer">
                                                            {wallet?.balance}
                                                        </div>
                                                        <div
                                                            data-for="setBalance"
                                                            data-tip={wallet?.balance}
                                                            onClick={() => openEachWalletPage(wallet?.name)}
                                                            className="sm:hidden mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer ">
                                                            {parseFloat(wallet?.balance).toFixed(5)}
                                                        </div>
                                                    </div>

                                                    <button
                                                        data-for="setBalance"
                                                        data-tip={'Пополнить'}
                                                        onClick={() => openModalBalanceIncome(wallet?.name, wallet?.address)}
                                                        className="items-center">
                                                        <div className='sm:hidden'>
                                                            <Icon name="walletIn" size={15}/>
                                                        </div>
                                                        <div className='hidden sm:block'>
                                                            <Icon name="walletIn" size={22}/>
                                                        </div>
                                                    </button>

                                                    {wallet?.isCanSend ?
                                                        <button
                                                            data-for="setBalance"
                                                            data-tip={'Отправить'}
                                                            onClick={() => openModalBalanceOut(wallet?.name, wallet?.balance)}
                                                            className="items-center  sm:text-text text-sm">
                                                            <div className='sm:hidden'>
                                                                <Icon name="walletSend" size={15}/>
                                                            </div>
                                                            <div className='hidden sm:block'>
                                                                <Icon name="walletSend" size={22}/>
                                                            </div>
                                                        </button> : <div className={'mr-4 sm:mr-6'}></div>}
                                                    <CopyToClipboard
                                                        onCopy={() => handleAlertOpen('Счет успешно скопирован')}
                                                        text={wallet?.address}>
                                                        <button
                                                            // onClick={copyAddress}
                                                            data-for="setBalance"
                                                            data-tip={'Скопировать счет'}
                                                            className="items-center">
                                                            <div className='sm:hidden'>
                                                                <Icon name="copy" size={15}/>
                                                            </div>
                                                            <div className='hidden sm:block'>
                                                                <Icon name="copy" size={22}/>
                                                            </div>
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>

                                            <ReactTooltip
                                                id="setBalance"
                                                globalEventOff="click"
                                                className="tooltip-modal"
                                                effect="solid"/>
                                        </div>)}
                                    {/*<ReactTooltip*/}
                                    {/*    id="setBalance"*/}
                                    {/*    globalEventOff="click"*/}
                                    {/*    className="tooltip-modal"*/}
                                    {/*    effect="solid"/>*/}
                                </>}
                        </div>
                        {/*End ------Crypto wallets*/}
                    </div>
                </div>}

            {/*Start-Modal ------------Пополнение кошелька*/}
            <Modal
                title={'Пополнить ' + eachWalletName?.name}
                onClose={closeModal}
                isOpen={balanceSetModal}
                footer={
                    <>
                        <CopyToClipboard
                            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
                            text={eachWalletName?.address}>
                            <Button color="primary" style={{margin: "10px", width: '235px', height: '37px'}}>Скопировать номер счета</Button>
                        </CopyToClipboard>
                        {/*{eachWalletName?.name === 'USDT (TRC20)'*/}
                        {/*    ? <Button style={{margin: "10px", width: '235px', height: '37px'}} color="primary" onClick={buyCryptoViaFiatRub}>Пополнить через FIAT RUB</Button>*/}
                        {/*    : <></>}*/}
                    </>
                }>
                <div className={'font-bold'}>Номер счета</div>
                <div className={'mb-3 flex flex-wrap'}>{eachWalletName?.address}</div>
                {eachWalletName?.address && <QRCode value={eachWalletName?.address}/>}
            </Modal>
            {/*End-Modal ------------Пополнение кошелька*/}

            {/*Start ------- Открыть окно уведомления*/}
            <Modal
                title={alertText}
                onClose={closeModal}
                isOpen={alertView}
                headerStyles={{border: 'none', color: '#000000'}}
                footerStyles={{border: 'none', padding: '10px'}}
                settings="alert"
                bodyStyles={{padding: '0px'}}
                modalStyles={{margin: 'auto'}}
                footer={
                    <div className="flex justify-center">
                        <Col xs={'auto'}>
                            <Button onClick={closeModal} color="primary">
                                Ок
                            </Button>
                        </Col>
                    </div>
                }/>
            {/*End ------- Открыть окно уведомления*/}

            {/*Start-Modal ------------Отправить с <Крипта>*/}
            <Modal
                title={`Отправить с ${eachWalletName?.name}`}
                onClose={closeModal}
                isOpen={balanceOutModal}
                footer={!isButtonEnable ?
                    <Button onClick={sendOutFromWallet} color="primary">Вывести</Button> : <></>}>
                <Input
                    label="Адрес получателя"
                    name="recipientAddress"
                    onChange={recipientAddressOnChange}
                    register={register}/>

                <div className='error mb-3'>
                    {error?.to && error?.to[0]}
                </div>

                {showInputs && recipientAddress?.length >= 15 &&
                    <>
                        <div className={'flex'}>
                            <Input
                                fieldClass={'w-full mt-auto mb-0'}
                                label="Сумма отправки"
                                name="recipientSum"
                                type="number"
                                onChange={howMuchSumOnChange}
                                register={register}/>

                            <div className={'mt-auto mb-0'}>
                                <Button
                                    className={'ml-2 h-10'}
                                    onClick={maxSendValueSum}
                                    color="primary">Все</Button>
                            </div>
                        </div>
                        <div className='error mt-1 mb-3'>
                            {/*<button onClick={() => console.log(error)}>show</button>*/}
                            {error?.amount && error?.amount[0]}
                        </div>
                        <div className={(watch('recipientSum') < 0) ? 'mb-3 mt-1 text-red' : 'mb-3 mt-1'}>
                            <strong>На счете:</strong> {eachWalletName?.balance}
                        </div>

                        <Input
                            label="С учетом комиссии"
                            name="recipientCommissionSum"
                            type="number"
                            onChange={onChangeRecipientCommissionSum}
                            register={register}/>

                        {showInputs &&
                            <div className='mb-2'>
                                Приблизительная
                                комиссия: {commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value} {eachWalletName.name}
                            </div>}
                        <div className="error mt-1 mb-3">
                            Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
                            цены и обьема "газа". Окончательная цена будет скорректирована
                            после совершения транзакции.
                        </div>

                        {validateSendWallet.emailCode &&
                            <Input
                                label='Email - код'
                                name="email_Code2"
                                register={register}/>}

                        {validateSendWallet.smsCode &&
                            <Input
                                label='Sms - код'
                                name="sms_Code2"
                                register={register}/>}

                        {validateSendWallet.gaCode &&
                            <Input
                                label='Google authenticator - код'
                                name="ga_Code2"
                                register={register}/>}

                        {errorFromHandler === null ? <></> :
                            <>
                                {errorFromHandler.map((item, i) =>
                                    <div className='error mb-2' key={i}>
                                        {item}
                                    </div>
                                )}
                            </>}
                    </>}
            </Modal>
            {/*End-Modal ------------Отправить с <Крипта>*/}

            {/*Start-Modal ------------Пополнить USDR*/}
            <Modal
                title={'Пополнить USDR'}
                onClose={closeModal}
                isOpen={usdrBalanceOutModal}
                footer={!isButtonEnable ? <Button onClick={windrawUsdrWallet}
                                                  color="primary">Пополнить</Button> : <></>}>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Откуда пополнить</div>
                    </Col>
                    {availableWalletsToReplenish !== null && availableWalletsToReplenish.map(renderBillBlock)}
                </Row>
                {investWallet &&
                    <>
                        <div className={'flex'}>
                            <Input
                                fieldClass={'w-full mt-auto mb-0'}
                                label={investWallet}
                                name="incomePay"
                                type="number"
                                onChange={changeInputUsdr}
                                register={register}/>
                            <div className={'mt-auto mb-0'}>
                                <Button className={'ml-2 h-10'} onClick={maxValueSum} color="primary">Все</Button>
                            </div>
                        </div>

                        <span>Комиссия - {' '}
                            {commissionSend[investWallet] ? commissionSend[investWallet]['REPLENISH_COMMISSION'].value : ''}%</span>
                        <Input fieldClass={'my-5'}
                               label='USDR'
                               onChange={changeInputUsdrWithCommission}
                               placeholder='Количество USDR'
                               name="cryproToUsdrPay"
                               type="number"
                               register={register}/>
                    </>}
                {error?.amount && <div className="error">{error?.amount}</div>}
            </Modal>
            {/*End-Modal ------------Пополнить USDR*/}

            {/*Start-Modal ------------Отправить USDR*/}
            <Modal
                title={'Отправить ' + eachWalletName.name}
                onClose={closeModal}
                isOpen={balanceOutUsdrModal}
                footer={!isButtonEnable ?
                    <Button onClick={sendUsdrToUser} color="primary">Отправить</Button> : <></>
                }>
                {userInfoById && (
                    <NotifyBlock classList="mb-4 _color-primary">
                        Получатель: {userInfoById}
                    </NotifyBlock>)}

                <Input
                    label="ID получателя"
                    type="number"
                    name="IdForUserUsdr"
                    register={register}/>

                <div className={'flex'}>
                    <Input
                        fieldClass={'w-full mt-auto mb-0'}
                        label={'USDR'}
                        type="number"
                        name="sumForUserUsdr"
                        onChange={onChangeUsdrSend}
                        register={register}/>
                    <div className={'mt-auto mb-0'}>
                        <Button className={'ml-2 h-10'} onClick={maxUsdrValueSum} color="primary">Все</Button>
                    </div>
                </div>
                <div className='error mt-1 mb-3'>
                    {error?.sum && error.sum[0]}
                </div>

                <div className="col-auto mt-1 mb-3">
                    {/*Доступно: {parseFloat(walletsData?.[0]?.balance).toFixed(6)}*/}
                    Доступно: {eachWalletName.balance}
                </div>

                {validateSendWallet.emailCode &&
                    <Input
                        label='Email код'
                        name="email_Code"
                        register={register}/>}

                {validateSendWallet.smsCode &&
                    <Input
                        label='Sms код'
                        name="sms_Code"
                        register={register}/>}

                {validateSendWallet.gaCode &&
                    <Input
                        label='Google authenticator код'
                        name="ga_Code"
                        register={register}/>}

                {errorFromHandler === null ? <></> :
                    <>
                        {errorFromHandler.map((item, i) =>
                            <div className='error mb-2' key={i}>
                                {item}
                            </div>
                        )}
                    </>}

            </Modal>
            {/*End-Modal ------------Отправить c USDR*/}

            {/*Start-Modal ------------Вывести c USDR*/}
            <Modal
                title={'Вывести с USDR'}
                onClose={closeModal}
                isOpen={withdrawBalanceUsdrModal}
                footer={
                    !investWallet ? <></> : !isButtonEnable ?
                        <Button onClick={windrawFromUsdr} color="primary">Вывести</Button> :
                        <></>

                    // (loaderSend ? <Spinner size={40}/> :
                    //         <Button onClick={windrawFromUsdr} color="primary">Вывести</Button>
                    // )
                }>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Куда вывести</div>
                    </Col>
                    {availableWalletsToPayout !== null && availableWalletsToPayout.map(renderBillBlock)}
                </Row>
                {investWallet !== null &&
                    <>
                        <div className={'flex'}>
                            <Input
                                fieldClass={'w-full mt-auto mb-0'}
                                label={'USDR'}
                                type="number"
                                name="usdrCounter"
                                onChange={handleUsdrOutCryptoChangeWithComission}
                                placeholder='Количество USDR'
                                register={register}/>
                            <div className={'mt-auto mb-0'}>
                                <Button className={'ml-2 h-10'} onClick={maxOutUsdrValueSum}
                                        color="primary">Все</Button>
                            </div>
                        </div>

                        <div className={'mb-3'}>
                            На счете {eachWalletName.balance} USDR
                        </div>

                        {watch('commissionCryptoCounter') && (
                            <span>Комиссия - {watch('commissionCryptoCounter')}</span>
                        )}
                        <Input
                            label={investWallet}
                            type="number"
                            name="cryptoCounter"
                            onChange={handleCryptoFromUsdr}
                            placeholder={`Количество ${investWallet}`}
                            register={register}/>
                        {error?.amount && <div className="error">{error.amount}</div>}
                    </>}
            </Modal>
            {/*End-Modal ------------Вывести c USDR*/}

            {/*Start-Modal ------------Вывести c SMART*/}
            <Modal
                title={'Вывести с SMART'}
                onClose={closeModal}
                isOpen={withdrawBalanceSmartModal}
                footer={
                    !investWallet ? <></> : !isButtonEnable ?
                        <Button onClick={windrawFromSmart} color="primary">Вывести</Button> :
                        <></>

                    // (loaderSend ? <Spinner size={40}/> :
                    //         <Button onClick={windrawFromUsdr} color="primary">Вывести</Button>
                    // )
                }>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Куда вывести</div>
                    </Col>
                    {availableWalletsToPayout !== null && availableWalletsToPayout.map(renderBillBlock)}
                </Row>
                {investWallet !== null &&
                <>
                    <div className={'flex'}>
                        <Input
                            fieldClass={'w-full mt-auto mb-0'}
                            label={'SMART'}
                            type="number"
                            name="smartCounter"
                            onChange={handleSmartOutCryptoChangeWithComission}
                            placeholder='Количество SMART'
                            register={register}/>
                        <div className={'mt-auto mb-0'}>
                            <Button className={'ml-2 h-10'} onClick={maxOutSmartValueSum}
                                    color="primary">Все</Button>
                        </div>
                    </div>

                    <div className={'mb-3'}>
                        На счете {eachWalletName.balance} SMART BONUS
                    </div>

                    <Input
                        label={investWallet}
                        type="number"
                        name="cryptoCounterSmart"
                        onChange={handleCryptoFromSmart}
                        placeholder={`Количество ${investWallet}`}
                        register={register}/>
                    {error?.amount && <div className="error">{error.amount}</div>}
                </>}
            </Modal>
            {/*End-Modal ------------Вывести c SMART*/}

            {/*Start-Modal ------------Отправить SMART*/}
            <Modal
                title={'Отправить ' + eachWalletName.name}
                onClose={closeModal}
                isOpen={balanceOutSmartModal}
                footer={!isButtonEnable ?
                    <Button onClick={sendSmartToUser} color="primary">Отправить</Button> : <></>
                }>
                {userInfoById && (
                    <NotifyBlock classList="mb-4 _color-primary">
                        Получатель: {userInfoById}
                    </NotifyBlock>)}

                <Input
                    label="ID получателя"
                    type="number"
                    name="IdForUserSmart"
                    register={register}/>

                <div className={'flex'}>
                    <Input
                        fieldClass={'w-full mt-auto mb-0'}
                        label={'SMART'}
                        type="number"
                        name="sumForUserSmart"
                        register={register}/>
                    <div className={'mt-auto mb-0'}>
                        <Button className={'ml-2 h-10'} onClick={maxSmartValueSum} color="primary">Все</Button>
                    </div>
                </div>
                <div className='error mt-1 mb-3'>
                    {error?.sum && error.sum[0]}
                </div>

                <div className="col-auto mt-1 mb-3">
                    {/*Доступно: {parseFloat(walletsData?.[0]?.balance).toFixed(6)}*/}
                    Доступно: {eachWalletName.balance}
                </div>

                {validateSendWallet.emailCode &&
                <Input
                    label='Email код'
                    name="emailCodeSmart"
                    register={register}/>}

                {validateSendWallet.smsCode &&
                <Input
                    label='Sms код'
                    name="smsCodeSmart"
                    register={register}/>}

                {validateSendWallet.gaCode &&
                <Input
                    label='Google authenticator код'
                    name="gaCodeSmart"
                    register={register}/>}

                {errorFromHandler === null ? <></> :
                    <>
                        {errorFromHandler.map((item, i) =>
                            <div className='error mb-2' key={i}>
                                {item}
                            </div>
                        )}
                    </>}

            </Modal>
            {/*End-Modal ------------Отправить c SMART*/}

            {/*Start-Modal ------------Отправить FIAT*/}
            <Modal
              title={'Отправить ' + eachWalletName.name}
              onClose={closeModal}
              isOpen={balanceOutFiatModal}
              footer={!isButtonEnable ?
                <Button onClick={() => sendFiatToUser(eachWalletName.name)} color="primary">Отправить</Button> : <></>
              }>
                {userInfoById && (
                  <NotifyBlock classList="mb-4 _color-primary">
                      Получатель: {userInfoById}
                  </NotifyBlock>)}

                <Input
                  label="ID получателя"
                  type="number"
                  name="IdForUserFiat"
                  register={register}/>

                <div className={'flex'}>
                    <Input
                      fieldClass={'w-full mt-auto mb-0'}
                      label={eachWalletName.name}
                      type="number"
                      name="sumForUserFiat"
                      onChange={onChangeFiatSend}
                      register={register}/>
                    <div className={'mt-auto mb-0'}>
                        <Button className={'ml-2 h-10'} onClick={maxFiatValueSum} color="primary">Все</Button>
                    </div>
                </div>
                <div className='error mt-1 mb-3'>
                    {error?.sum && error.sum[0]}
                </div>

                <div className="col-auto mt-1 mb-3">
                    {/*Доступно: {parseFloat(walletsData?.[0]?.balance).toFixed(6)}*/}
                    Доступно: {eachWalletName.balance}
                </div>

                {validateSendWallet.emailCode &&
                  <Input
                    label='Email код'
                    name="emailCodeFiat"
                    register={register}/>}

                {validateSendWallet.smsCode &&
                  <Input
                    label='Sms код'
                    name="smsCodeFiat"
                    register={register}/>}

                {validateSendWallet.gaCode &&
                  <Input
                    label='Google authenticator код'
                    name="gaCodeFiat"
                    register={register}/>}

                {errorFromHandler === null ? <></> :
                  <>
                      {errorFromHandler.map((item, i) =>
                        <div className='error mb-2' key={i}>
                            {item}
                        </div>
                      )}
                  </>}

            </Modal>
            {/*End-Modal ------------Отправить c FIAT*/}

            {isShowEachWalletPage &&
                <EachWalletPage
                    // balanceOutUsdrModal={balanceOutUsdrModal}
                    // balanceOutModal={balanceOutModal}
                    isRefreshWallet={isRefreshWallet}
                    getToNewWallet={getToNewWallet}
                    handleAlertOpen={handleAlertOpen}
                    nameForEachWalletPage={nameForEachWalletPage}
                    setIsShowEachWalletPage={setIsShowEachWalletPage}
                    openModalBalanceIncome={openModalBalanceIncome}
                    openModalBalanceOut={openModalBalanceOut}/>}

            {isShowEachFiatWalletPage &&
                <EachFiatWalletPage
                    openModalFiatOutPayming={openModalFiatOutPayming}
                    isRefreshWallet={isRefreshWallet}
                    eachWalletName={eachWalletName}
                    // getToNewWallet={getToNewWallet}
                    // handleAlertOpen={handleAlertOpen}
                    nameForEachWalletPage={nameForEachWalletPage}
                    setIsShowEachFiatWalletPage={setIsShowEachFiatWalletPage}
                    openModalFiatEnterOrder={openModalFiatEnterOrder}
                    openModalBalanceOut={openModalBalanceOut}
                    inputFiat={inputFiat}
                    outputFiat={outputFiat}
                />}



            {/*Start-Modal ------------Создать заявку на ввод фиата*/}
            <Modal
                title={`Создать заявку на ввод фиата - ${eachWalletName.name}`}
                onClose={closeModal}
                isOpen={openFiatEnterModal}
                footer={isFiatEnterSpinner ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                    !openFiatInputContract.isHas ?
                    <Button onClick={fiatEnterOrder} color="primary">Создать заявку</Button> :
                        (fiatInputPaymentMethod === FIAT_INPUT_PAYMENT_METHOD_ONLINE ? <>
                            {/*<Button onClick={fiatEnterOrder} color="primary">Создать заявку</Button>*/}
                            <div className='mb-2'>Счёт на оплату  сформирован, Вы можете перейти к оплате</div>
                            <Button onClick={openFiatEnterOrder} color="primary">Перейти к оплате</Button>
                        </> : <div className='mb-2'>Заявка сформирована. Мы скоро с Вами свяжемся.</div>)
                }>
                <div>{fiatWalletsData?.map(item => {
                    if (item?.name === eachWalletName?.name && fiatInputPaymentMethod) {
                        return (
                            <div key={item?.name}>
                                Допустимые суммы ввода: от {item?.inputMethodsInfo[fiatInputPaymentMethod].minAmount} до {item?.inputMethodsInfo[fiatInputPaymentMethod].maxAmount}
                            </div>
                        )
                    }})
                }
                </div>
                <br/>
                <Input
                    fieldClass={'w-full mt-auto mb-0'}
                    label={'Сумма ввода'}
                    type="number"
                    name="fiatAmount"
                    onChange={(e) => onChangeFiatInput(e)}
                    placeholder='Введите сумму'
                    register={register}/>

                <div className='error mt-1'>{error?.amount && error?.amount}</div>

                <div>{fiatWalletsData?.map(item => {
                    if (item?.name === eachWalletName?.name && fiatInputPaymentMethod) {
                        return (
                            <div key={item?.name}>
                                Комиссия: {item?.inputMethodsInfo[fiatInputPaymentMethod].commission}%
                            </div>
                        )
                    }})
                }
                </div>
                <div>
                    <Input
                        fieldClass={'w-full mt-4 mb-0'}
                        label={'К оплате с учетом комиссии'}
                        type="number"
                        onChange={(e) => onChangeFiatInputCommission(e)}
                        name="fiatInputCommissions"
                        placeholder='К оплате с учетом комиссии'
                        register={register}
                    />
                </div>
                {fiatInputPaymentMethod === FIAT_INPUT_PAYMENT_METHOD_CASH &&
                    <div>
                        <Select
                            fieldClass='w-full mt-4 mb-0'
                            label={'Офис'}
                            list={getGarantOffices()}
                            // onChange={(e) => console.log(e.target.value)}
                            name={'officeIdInput'}
                            register={register}/>
                    </div>
                }

                <div>
                    <label className={'w-full mt-4 mb-0 block'}>
                        <div className={'cursor-pointer mb-1'}>Способ связи</div>
                        <textarea
                            rows="10"
                            {...register('communicationMethodInput')}
                            className="w-full mb-0 appeal-message"
                        />

                        {error?.communicationMethod &&
                            error.communicationMethod.map(item =>
                                <div key={item} className='error mt-1'>{item}</div>
                            )
                        }
                    </label>
                </div>


                <br/>
                <Button onClick={() => inputFiat(eachWalletName)} color="primary">Назад</Button>

            </Modal>
            {/*End-Modal ------------Создать заявку на ввод фиата*/}

            {/*Start-Modal ------------Вывод фиата*/}
            <Modal
                title={`Вывод фиата - ${eachWalletName.name}`}
                onClose={closeModal}
                isOpen={openFiatOutPaymingModal}
                footer={spinnerFiatOutPay ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> : <Button onClick={fiatOutPay} color="primary">Вывести</Button>}>
                {/*<button onClick={() => console.log(error)}>show</button>*/}
                <div>{fiatWalletsData?.map(item => {
                    if (item?.name === eachWalletName?.name && fiatOutputPaymentMethod) {
                        return (
                            <div key={item?.name}>
                                Допустимые суммы вывода: от {item?.outputMethodsInfo[fiatOutputPaymentMethod].minAmount} до {item?.outputMethodsInfo[fiatOutputPaymentMethod].maxAmount}
                            </div>
                        )
                    }})
                }
                </div>
                <div>{fiatWalletsData?.map(item => {
                    if (item?.name === eachWalletName?.name) {
                        return (
                            <div key={item?.name}>
                                Текущий баланс: {item?.balance} {item?.name}
                            </div>
                        )
                    }})
                }
                </div>
                <br/>

                <Input
                    fieldClass={'w-full mt-auto mb-1'}
                    label={'Сумма вывода'}
                    type="number"
                    onChange={(e) => onChangeInputFiatOutPay(e)}
                    name="fiatOutAmount"
                    placeholder='Введите сумму'
                    register={register}/>
                <div className='mb-4'>{fiatWalletsData?.map(item => {
                    if (item?.name === eachWalletName?.name && fiatOutputPaymentMethod) {
                        return (
                            <div key={item?.name}>
                                Комиссия: {item?.outputMethodsInfo[fiatOutputPaymentMethod].commission}%
                            </div>
                        )
                    }})
                }
                </div>
                {error?.amount &&
                    <>
                    {error.amount.map(item =>
                    <div key={item} className='error'>{item}</div>
                        )}
                    </>}

                {fiatOutputPaymentMethod === FIAT_OUTPUT_PAYMENT_METHOD_ONLINE &&
                    <>
                        <Input
                            fieldClass={'w-full mt-auto mb-0'}
                            label={'Номер банковской карты'}
                            type="number"
                            name="bankCardFiatOut"
                            placeholder='Введите номер карты'
                            register={register}
                        />
                        {error?.card &&
                            error.card.map(item =>
                                <div key={item} className='error mt-1'>{item}</div>
                            )
                        }
                    </>
                }

                <Input
                    fieldClass={'w-full mt-4 mb-0'}
                    label='К выплате с учетом комиссии'
                    type="number"
                    name="bankCardFiatOutCommission"
                    onChange={(e) => onChangeFiatOutCommission(e)}
                    placeholder='К выплате с учетом комиссии'
                    register={register}
                />

                {fiatOutputPaymentMethod === FIAT_OUTPUT_PAYMENT_METHOD_CASH &&
                    <div>
                        <Select
                            fieldClass='w-full mt-4 mb-0'
                            label={'Офис'}
                            list={getGarantOffices()}
                            // onChange={(e) => console.log(e.target.value)}
                            name={'officeIdOutput'}
                            register={register}/>
                    </div>
                }

                <div>
                    <label className={'w-full mt-4 mb-0 block'}>
                        <div className={'cursor-pointer mb-1'}>Способ связи</div>
                        <textarea
                            rows="10"
                            {...register('communicationMethodOutput')}
                            className="w-full mb-0 appeal-message"
                        />

                        {error?.communicationMethod &&
                            error.communicationMethod.map(item =>
                                <div key={item} className='error mt-1'>{item}</div>
                            )
                        }
                    </label>
                </div>

                {validateSendWallet.emailCode &&
                    <Input
                        fieldClass={'w-full mt-4 mb-0'}
                        label='Email код'
                        name="emailCodeFiatOutput"
                        register={register}/>}
                {error?.emailCode &&
                    <>
                        {error.emailCode.map(item =>
                            <div key={item} className='error mt-1'>{item}</div>
                        )}
                    </>}

                {validateSendWallet.smsCode &&
                    <Input
                        fieldClass={'w-full mt-4 mb-0'}
                        label='Sms код'
                        name="smsCodeFiatOutput"
                        register={register}/>}
                {error?.smsCode &&
                    <>
                        {error.smsCode.map(item =>
                            <div key={item} className='error mt-1'>{item}</div>
                        )}
                    </>}

                {validateSendWallet.gaCode &&
                    <Input
                        fieldClass={'w-full mt-4 mb-0'}
                        label='Google authenticator код'
                        name="gaCodeFiatOutput"
                        register={register}/>}
                {error?.gaCode &&
                    <>
                        {error.gaCode.map(item =>
                            <div key={item} className='error mt-1'>{item}</div>
                        )}
                    </>}

                <br/>
                <Button onClick={() => outputFiat(eachWalletName)} color="primary">Назад</Button>

            </Modal>
            {/*End-Modal ------------Вывод фиата*/}

            {/*Start-Modal ------------Покупка RSI TOKEN PRIVATE*/}
            <Modal
              title={'Покупка RSI TOKEN PRIVATE'}
              onClose={closeModal}
              isOpen={openBuyTokenModal}
              footer={!isButtonEnable ? <Button onClick={buyTokenSendForm}
                                                color="primary">Купить</Button> : <></>}>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Выберите кошелек для покупки</div>
                    </Col>
                    {availableWalletsToBuyToken !== null && availableWalletsToBuyToken.map(renderBillBlock)}
                </Row>
                {investWallet &&
                  <>
                      <Input
                        fieldClass={'my-5'}
                        label='Количество токенов'
                        name="tokenPrivateCount"
                        onChange={changeTokenPrivateCount}
                        type="number"
                        register={register}/>

                    <div className={'flex'} style={{paddingBottom: '15px'}}>
                      <Input fieldClass={'w-full mt-auto mb-0 my-5'}
                             label={investWallet}
                             onChange={changeTokenPrivatePrice}
                             placeholder='Стоимость токенов'
                             name="tokenPrivatePrice"
                             type="number"
                             register={register}/>
                      <div className={'mt-auto mb-0'}>
                          <Button className={'ml-2 h-10'} onClick={buyTokenAllIn} color="primary">Все</Button>
                      </div>
                    </div>
                  </>}
                {error?.amount && <div className="error">{error?.amount}</div>}
            </Modal>
            {/*End-Modal ------------Покупка RSI TOKEN PRIVATE*/}

            {/*Start-Modal ------------Выбор крипто-кошельков*/}
            <Modal
              title={'Криптовалютные кошельки'}
              onClose={() => closeModal(this, true)}
              isOpen={cryptoWalletsChoiceModal}>

                {walletsForChoice === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                  <>
                      {walletsForChoice?.map(wallet =>
                        <div key={wallet?.wallet}>
                            <div
                              className={'transition delay-80 h-16 flex w-full justify-between items-center bg-white hover:bg-grey p-2 my-2'}>
                                <div onClick={() => openEachWalletPage(wallet?.wallet)}
                                     className='flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full mx-1'>
                                    <div
                                      className='whitespace-nowrap cursor-pointer'>
                                        <img alt={wallet?.wallet}
                                             className={'w-6 md:w-10 sm:w-8'}
                                             src={wallet?.icon}/>
                                    </div>

                                    <div onClick={() => openEachWalletPage(wallet?.wallet)}
                                         className="whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                        <strong>
                                            {wallet?.wallet}
                                        </strong>
                                    </div>
                                </div>
                                <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                    <div className={'cursor-pointer'}>
                                        <div
                                          className="hidden sm:block mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer">
                                            <input
                                              name="checkbox"
                                              type="checkbox"
                                              className="checkbox"
                                              value={wallet?.status}
                                              defaultChecked={wallet?.status}
                                              onClick={() => choiceWalletSetStatus(wallet)}
                                              {...register('checkbox')}
                                            />
                                        </div>
                                        <div
                                          data-for="setStatus"
                                          data-tip={wallet?.balance}
                                          className="sm:hidden mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer ">
                                            <input
                                              name="checkbox"
                                              type="checkbox"
                                              className="checkbox"
                                              value={wallet?.status}
                                              defaultChecked={wallet?.status}
                                              onClick={() => choiceWalletSetStatus(wallet)}
                                              {...register('checkbox')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ReactTooltip
                              id="setBalance"
                              globalEventOff="click"
                              className="tooltip-modal"
                              effect="solid"/>
                        </div>)}
                      {/*<ReactTooltip*/}
                      {/*    id="setBalance"*/}
                      {/*    globalEventOff="click"*/}
                      {/*    className="tooltip-modal"*/}
                      {/*    effect="solid"/>*/}
                  </>}

            </Modal>
            {/*End-Modal ------------Выбор крипто-кошельков*/}

            {/*Start-Modal ------------Выбор способа ввода фиата*/}
            <Modal
              title={'Способ ввода'}
              onClose={closeModal}
              isOpen={choiceFiatInputMethodModal}
              footer={fiatInputPaymentMethod ? <Button onClick={sendFiatInputPaymentMethod}
                                                color="primary">Пополнить</Button> : <></>}>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Выберите способ</div>
                    </Col>
                    <Col key={FIAT_INPUT_PAYMENT_METHOD_ONLINE} xs={12} sm={6}>
                        <div
                          className={'cmp-bill-block-usdr mb-5' + (fiatInputPaymentMethod === FIAT_INPUT_PAYMENT_METHOD_ONLINE ? ' _active' : '')}
                          onClick={() => setFiatInputPaymentMethod(FIAT_INPUT_PAYMENT_METHOD_ONLINE)}
                        >
                            <div className="sm:text-lg text-md font-medium">Картой онлайн</div>
                            {fiatWalletsData?.map(item => {
                                if (item?.name === eachWalletName?.name) {
                                    return (
                                        <span key={item?.name} className="text-price">
                                            Комиссия: {item?.inputMethodsInfo[FIAT_INPUT_PAYMENT_METHOD_ONLINE].commission}%
                                        </span>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col key={FIAT_INPUT_PAYMENT_METHOD_CASH} xs={12} sm={6}>
                        <div
                          className={'cmp-bill-block-usdr mb-5' + (fiatInputPaymentMethod === FIAT_INPUT_PAYMENT_METHOD_CASH ? ' _active' : '')}
                          onClick={() => setFiatInputPaymentMethod(FIAT_INPUT_PAYMENT_METHOD_CASH)}
                        >
                            <div className="sm:text-lg text-md font-medium">В офисе наличными</div>
                            {fiatWalletsData?.map(item => {
                                if (item?.name === eachWalletName?.name) {
                                    return (
                                        <span key={item?.name} className="text-price">
                                            Комиссия: {item?.inputMethodsInfo[FIAT_INPUT_PAYMENT_METHOD_CASH].commission}%
                                        </span>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                </Row>
            </Modal>
            {/*End-Modal ------------Выбор способа ввода фиата*/}

            {/*Start-Modal ------------Выбор способа вывода фиата*/}
            <Modal
              title={'Способ вывода'}
              onClose={closeModal}
              isOpen={choiceFiatOutputMethodModal}
              footer={fiatOutputPaymentMethod ? <Button onClick={sendFiatOutputPaymentMethod}
                                                color="primary">Вывести</Button> : <></>}>
                <Row>
                    <Col xs={12}>
                        <div className="text-lg font-medium mb-2">Выберите способ</div>
                    </Col>
                    <Col key={FIAT_OUTPUT_PAYMENT_METHOD_ONLINE} xs={12} sm={6}>
                        <div
                          className={'cmp-bill-block-usdr mb-5' + (fiatOutputPaymentMethod === FIAT_OUTPUT_PAYMENT_METHOD_ONLINE ? ' _active' : '')}
                          onClick={() => setFiatOutputPaymentMethod(FIAT_OUTPUT_PAYMENT_METHOD_ONLINE)}
                        >
                            <div className="sm:text-lg text-md font-medium">Картой онлайн</div>
                            {fiatWalletsData?.map(item => {
                                if (item?.name === eachWalletName?.name) {
                                    return (
                                        <span key={item?.name} className="text-price">
                                            Комиссия: {item?.outputMethodsInfo[FIAT_OUTPUT_PAYMENT_METHOD_ONLINE].commission}%
                                        </span>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col key={FIAT_OUTPUT_PAYMENT_METHOD_CASH} xs={12} sm={6}>
                        <div
                          className={'cmp-bill-block-usdr mb-5' + (fiatOutputPaymentMethod === FIAT_OUTPUT_PAYMENT_METHOD_CASH ? ' _active' : '')}
                          onClick={() => setFiatOutputPaymentMethod(FIAT_OUTPUT_PAYMENT_METHOD_CASH)}
                        >
                            <div className="sm:text-lg text-md font-medium">В офисе наличными</div>
                            {fiatWalletsData?.map(item => {
                                if (item?.name === eachWalletName?.name) {
                                    return (
                                        <span key={item?.name} className="text-price">
                                            Комиссия: {item?.outputMethodsInfo[FIAT_OUTPUT_PAYMENT_METHOD_CASH].commission}%
                                        </span>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                </Row>
            </Modal>
            {/*End-Modal ------------Выбор способа вывода фиата*/}

            {/*Start-Modal ------------Модалка с формой для покупки крипты через фиат*/}
            <Modal
                title={`Покупка - ${eachWalletName.name} через FIAT RUB`}
                onClose={closeModal}
                isOpen={buyCryptoViaFiatRubModal}
                footer={<Button onClick={buyCryptoViaFiatRubSendForm} color="primary">Купить</Button>}>
                {fiatRubToUsdtRate === null ? <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> : <div>
                    Курс покупки: {(1 / fiatRubToUsdtRate).toFixed(2)} FIAT RUB
                </div>}

                <br/>
                <Input
                    fieldClass={'w-full mt-auto mb-0'}
                    label={'Сумма в FIAT RUB'}
                    type="number"
                    name="buyCryptoFiatAmount"
                    onChange={(e) => onChangeBuyCryptoFiatAmount(e)}
                    placeholder='Введите сумму'
                    register={register}/>

                <div className='error mt-1'>{error?.amount && error?.amount}</div>

                <div>{fiatWalletsData?.map(item => {
                    if(item?.name === eachWalletName?.name){
                        return (
                            <div key={item?.name}>
                                Комиссия: {item?.commissions?.input}%
                            </div>
                        )
                    }})
                }
                </div>
                <div>
                    <Input
                        fieldClass={'w-full mt-4 mb-0'}
                        label={`Расчетная сумма ${eachWalletName.name}`}
                        type="number"
                        onChange={(e) => onChangeBuyCryptoCryptoAmount(e)}
                        name="buyCryptoCryptoAmount"
                        placeholder='К зачислению'
                        register={register}
                    />
                </div>
                <br/>
            </Modal>
            {/*End-Modal ------------Модалка с формой для покупки крипты через фиат*/}

            {/*Start-Modal ------------Модалка с информацией для отправки средств на карту для покупки крипты через фиат*/}
            <Modal
                title={`Покупка - ${eachWalletName.name} через FIAT RUB`}
                onClose={closeModal}
                isOpen={cardInfoForBuyCryptoViaFiatRubModal}
                footer={<Button onClick={closeModal} color="primary">Средства отправлены</Button>}>

                {buyCryptoViaFiatRubCard ?
                    <div>
                        Отправьте {buyCryptoViaFiatRubCard.amount} FIAT RUB на карту:
                        <div>{buyCryptoViaFiatRubCard.card}
                            <CopyToClipboard
                                // onCopy={() => handleAlertOpen('Номер карты успешно скопирован')} @todo
                                onCopy={() => alert('Номер карты успешно скопирован')}
                                text={buyCryptoViaFiatRubCard.card}>
                                <button
                                    // onClick={copyAddress}
                                    data-for="setBalance"
                                    data-tip={'Скопировать номер карты'}
                                    className="items-center">
                                    <div className='sm:hidden'>
                                        <Icon name="copy" size={15}/>
                                    </div>
                                    <div className='hidden sm:block'>
                                        <Icon name="copy" size={22}/>
                                    </div>
                                </button>
                            </CopyToClipboard>
                        </div>
                        <br/>

                        <span>Оплатите в течение: </span>
                        <div><Timer millisecondsCount={25 * 60 * 1000} /></div>
                    </div>
                    : <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/>
                }
            </Modal>
            {/*End-Modal ------------Модалка с информацией для отправки средств на карту для покупки крипты через фиат*/}
        </div>
    );
    // }
};

export default WalletPageUpdate;
