import React from 'react';
import { useHistory } from 'react-router';
import { Container, Row } from 'reactstrap';

export const page500 = () => {
  const history = useHistory();
  return (
    <Container fluid className="flex justify-center mt-32">
      <Row className="flex justify-center m-auto">
        <div className="items-center justify-between font-bold text-xl">
          Произошла ошибка,
          <span
            className="text-purple cursor-pointer"
            onClick={() => history.goBack()}
          >
            вернитесь назад
          </span>{' '}
          и попробуйте снова.
          <br /> Если ошибка сохраняется, обратитесь в{' '}
          <span
            className="text-purple cursor-pointer"
            onClick={() => history.push('/appeal')}
          >
            поддержку
          </span>
          .
        </div>
      </Row>
    </Container>
  );
};
