import PropTypes from 'prop-types';
import React from 'react';
import { apiGet } from '../../../api';
import { downloadBase64File } from '../../../utils/downloadBase64File';
import { Icon } from '../icon';
import './index.scss';

export const FileView = ({
  download = false,
  fieldClass,
  data,
  deleteFile,
  upload = false,
}) => {
  const downloadFile = () => {
    if (!download) return;

    apiGet({ url: `/support/file?id=${data.id}` }).then(res => {
      if (res.error) {
        console.error('Скачивание файла (сообщение)');
        return;
      }

      downloadBase64File(res.data.asBase64, res.data.name);
    });
  };

  return (
    <div
      onClick={downloadFile}
      className={`p-2 rounded-md relative ${fieldClass ? fieldClass : ''}`}
    >
      {upload && (
        <div
          onClick={deleteFile}
          className="bg-red w-4 h-4 rounded-full absolute right-0 top-0 cursor-pointer"
        >
          <span className="pos-abs">
            <Icon name="close" size={8} color="#fff" />
          </span>
        </div>
      )}

      <div className="file-content flex items-center justify-center">
        <Icon name="file" size={20} />
      </div>
      <div className="text-xs text-textFade file-text text-center mt-2">
        {data.name}
      </div>
    </div>
  );
};

FileView.propTypes = {
  data: PropTypes.any,
  deleteFile: PropTypes.func,
  upload: PropTypes.bool,
  fieldClass: PropTypes.string,
  download: PropTypes.any,
};
