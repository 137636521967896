import React from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { Pagination } from '../../../common/pagination';

export const NotificationsPage = () => {
  const { page } = useUrl();
  const { response: notificationsList } = useFetch({
    url: `/notification?page=${page || 1}&limit=20`,
  });

  return (
    <Container fluid>
      <Card className="mt-7">
        <CardHeader>
          <div className="sm:text-2xl text-xl font-medium">Уведомления</div>
        </CardHeader>

        <CardBody>
          <div className="scroll-x">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Дата</th>
                  <th>Сообщение</th>
                </tr>
              </thead>
              <tbody>
                {notificationsList?.data?.models?.map((i, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="whitespace-pre">{i.createdAt}</td>
                    <td>{i.text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <Pagination
              lastPage={notificationsList?.data?.lastPage || 1}
              page={page}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};
