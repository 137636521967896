// import copy from 'copy-to-clipboard';
// import QRCode from 'qrcode.react';
// import { Link } from 'react-router-dom';
// import { useFetch } from '../../../../hooks/useFetch';
// import { Icon } from '../../../common/icon';
// import Spinner from '../../../common/spinner';
import { Modal } from '../../../common/ui/modal';
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container } from 'reactstrap';
import { Button } from '../../../common/ui/button';
import { apiGet, apiPost } from '../../../../api';
import './index.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Icon} from '../../../common/icon';

export const TradingWithRsi = () => {
  const [telegramStatus, setTelegramStatus] = useState(false);

  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isFetch, setIsFetch] = useState(false);
  const [inviteToken, setInviteToken] = useState('');

  // const { response: dataTradingWithRsi, loading: loadingData } = useFetch({
  //   url: '/club/view',
  // });

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };

  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  // const copyLink = () => {
  //   copy(dataTradingWithRsi?.data?.inviteToChannelLink);
  //   handleAlertOpen('Ссылка на приватный канал успешно скопирована');
  // };

  const copySecretTelegram = () => {
    apiGet({
      url: '/club/request-telegram-invite-link',
    })
      .then((res) => {
        if (res.data === 'Вы не подключены к Telegram Bot')
          console.log('Сначала нужно подключить телеграм бота');
        // handleAlertOpen('Сначала нужно подключить телеграм бота');
        else
          handleAlertOpen('ссылка на канал отправлена в телеграм бот');
      })
      .catch(e => {
        console.log(e);
      });
  };

  const checkTelegramBot = () => {
    if (telegramStatus === true) {
      apiPost({
        url: '/telegram/disable',
      }).catch(e => {
        console.log(e);
      });
    } else {
      apiGet({
        url: '/telegram/invite-link',
      })
        .then(res => {
          window.location.assign(res.data);
          // window.open(res.data, '');
        })
        .catch(e => {
          console.log(e);
        });
    }

    apiGet({
      url: '/telegram',
    }).then(res => {
      if (res.data === false) {
        apiGet({
          url: '/telegram/invite-token',
        }).then(res => {
          setInviteToken(res.data);
        }).catch(e =>{
          console.log(e);
        });
        setTelegramStatus(false);
      }
      else return setTelegramStatus(true);
    });
    console.log(inviteToken);
  };

  useEffect( () => {
    apiGet({
      url: '/telegram',
    }).then( res => {
      if (res.data === false) {
        setIsFetch(true);
        setTelegramStatus(false);

        apiGet({
          url: '/telegram/invite-token',
        }).then(res => {
          setInviteToken(res.data);
        }).catch(e =>{
          console.log(e);
        });
      }else {
        setTelegramStatus(true);
      }
    });
    console.log(inviteToken);
  }, []);

  return (
    <>
      <Container fluid>
        <div className='md:flex justify-between'>
          <div className='sm:text-2xl my-5 md:my-10 text-lg font-medium flex '>
            Торговля с RSI
          </div>
          {/*<div className='flex items-center mb-2 md:mb-0'></div>*/}
        </div>

        <Card>
          <CardBody className='overflow-auto'>
            {/* {loadingData ? (
              <Spinner styles={{ margin: 'auto' }} />
            ) : ( */}
            {/* <div className="row">
              {dataTradingWithRsi?.data?.inviteToChannelLink === null ? (
                <div className="flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                  <Icon name="warning" size={34} color="#F73434" />
                  <div className="ml-4" style={{ maxWidth: '700px' }}>
                    Необходимо{' '}
                    <Link
                      className="text-purple font-bold"
                      to={'/business-package'}
                    >
                      купить
                    </Link>{' '}
                    подписку на год, для отображения контента
                  </div>
                </div>
              ) : ( */}
            <>
              <Col md={6} className='text-xl'>
                Поздравляем!
                <br />
                Ваш уникальный доступ на закрытый канал сообщества "Клуб RSI"{' '}
                <b>АКТИВИРОВАН*</b>.
                <br />
                <br />
                <div className='font-italic'>
                  *Доступ предоставлен только вашему ID и является одноразовым,
                  в случае передачи ссылки третьим лицам, ключ будет
                  деактивирован без права восстановления.{' '}
                </div>
                <div className='sm:text-s text-sm font-small my-5'>
                  Для получения доступа к закрытому каналу - подключите телеграм бот (если возникли сложности с
                  подключением попробуйте с мобильного устройства)
                  {/* <Button color="primary" onClick={copyLink}>
                    Скопировать ссылку
                  </Button> */}









                  <div>
                    {telegramStatus ?
                      <div>
                        <Button
                          onClick={copySecretTelegram}
                          className='my-2 mx-0'
                          color={'primary'}
                          size='m'
                        >Секретный телеграм канал</Button>
                      </div>
                      :
                      <>
                        { isFetch ?
                          <div>
                            <div>
                              <Button
                                onClick={checkTelegramBot}
                                className='my-5 mx-0'
                                color={'primary'}
                                size='m'
                              >Подключиться к телеграм боту
                              </Button>
                            </div>
                            <div className='sm:text-s text-sm font-small my-5'>
                              <strong>
                          Если Вам не удается совершить подключение к боту, воспользуйтесь следующей инструкцией:
                              </strong>
                              <ul>
                                <li className={'my-2'}>
                          1. Найдите бота по имени пользователя в приложении Telegram - @RSIfundBot
                                </li>
                                <li className={'my-2'}>
                          2. Нажмите на кнопку "старт", "начать", "start", "запустить" (кнопка в разных версиях называется по-разному) или просто напишите боту "/start" (без кавычек), если кнопки нет.
                                </li>
                                <li className={'my-2'}>
                          3. Бот должен попросить Вас отправить ему токен.
                                </li>
                                <li className={'mb-3'}>
                                  <strong className={'mr-3'}>Вот Ваш токен:</strong>
                                  {inviteToken}
                                  <CopyToClipboard 
                                    onCopy={() => handleAlertOpen('Токен успешно скопирован')}
                                    text={inviteToken}>
                                    <span>
                                      <button className={'mx-2'}>
                                        <Icon name="copy" size={22} color={'#7c61bd'} />
                                      </button>
                                    </span>
                                  </CopyToClipboard>
                                </li>

                                <li className={'my-2'}>
                          4. Если всё в порядке, бот ответит, что Вы успешно авторизовались.
                                </li>
                              </ul>
                            </div>
                          </div> : <></>
                        }</>
                    }
                  </div>
                </div>










              </Col>
              <Col
                md={6}
                className='flex flex-col items-center justify-center mt-2 md:mt-0'
              >
                {/* <QRCode
                  style={{ minWidth: 200, minHeight: 200 }}
                   className="mb-3"
                   value={dataTradingWithRsi?.data?.inviteToChannelLink}
                /> */}
              </Col>
            </>
            {/* )}
            </div> */}
            {/* )} */}
          </CardBody>
        </Card>
      </Container>
      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings='alert'
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className='flex justify-center'>
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color='primary'>
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
