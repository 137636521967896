import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { funcError500 } from '../../../store/app/actions';
import { Icon } from '../icon';
import './index.scss';
import PropTypes from 'prop-types';

const PopUpError = ({error500,error500mess}) => {
  const dispatch = useDispatch();

  // const stateApp = useSelector(s => s.app);

  const closePopUp = () => {
    dispatch(funcError500(false));
  };

  return (
    <div
      onClick={closePopUp}
      className={`pop-up-error  p-4 absolute  bg-red rounded-xl z-50 cursor-pointer  left-10 max-w-xl right-10 ml-auto ${
        error500 && 'view-pop-up'
      }`}
    >
      <div className="flex justify-end">
        <div>
          <Icon name="close" color="#fff" />
        </div>
      </div>
      <span className="text-md font-bold text-white">
        {error500mess}
      </span>
    </div>
  );
};

const mapStateToProps = state => ({
  error500: state.app.error500,
  error500mess:state.app.error500mess
});

PopUpError.propTypes = {
  error500: PropTypes.bool,
  error500mess:PropTypes.string
};

export default connect(mapStateToProps)(PopUpError);