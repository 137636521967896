import cx from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

export const PhoneInput = ({
  fieldClass,
  inputClass,
  label,
  error,
  name,
  thumbText,
  readOnly,
  max,
  handleChange,
  control,
  ...props
}) => {
  return (
    <label className={cx(fieldClass, 'form-field block')}>
      {label && <div className="label cursor-pointer mb-1">{label}</div>}

      <span className={cx(inputClass, 'input-group flex')}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const onChange = e => {
              const { value } = e.target;
              let val;

              if (!value.length) {
                val = `+${new AsYouType().input(value)}`;
              } else {
                val = new AsYouType().input(value);
              }

              field.onChange(val);

              if (handleChange) {
                handleChange(name, val);
              }
            };

            const onFocus = () => {
              if (!field.value?.length) {
                field.onChange('+');
              }
            };

            const onBlur = () => {
              if (field.value == '+') {
                field.onChange('');
              }
            };

            return (
              <input
                type="tel"
                value={field.value || ''}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                className="input w-full"
                readOnly={readOnly}
                {...props}
                max={max ? max : ''}
              />
            );
          }}
        />
      </span>

      {thumbText && <div className="thumb mt-1">{thumbText}</div>}

      {error &&
        (Array.isArray(error) ? (
          error.map((e, index) => (
            <div key={index} className="error mt-1">
              {e}
            </div>
          ))
        ) : (
          <div className="error mt-1">{error}</div>
        ))}
    </label>
  );
};
PhoneInput.propTypes = {
  fieldClass: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.any,
  thumbText: PropTypes.string,
  readOnly: PropTypes.any,
  max: PropTypes.string,
  inputClass: PropTypes.string,
  handleChange: PropTypes.any,
  control: PropTypes.any,
};
