import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
// import { Input } from '../../../common/ui/input';
import { Select } from '../../../common/ui/select';
import { useForm } from 'react-hook-form';
import { apiGet, apiPost } from '../../../../api';
import { Input } from '../../../common/ui/input';
import { Button } from '../../../common/ui/button';
import cx from 'classnames';
import { Modal } from '../../../common/ui/modal';
import './index.scss';

export const InternationalPaymentsPage = () => {
  const { register, watch, setValue } = useForm();
  const [countryList, setCountryList] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [validateErrors, setValidateErrors] = useState(null);
  const [notification, setNotification] = useState(null);
  const [contactsModal, setContactsModal] = useState(false);
  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [tg, setTg] = useState('');

  const typeList = [
    {
      label: '',
      value: '',
    },
    {
      label: 'Юридическое лицо',
      value: 1,
    },
    {
      label: 'Физическое лицо',
      value: 2,
    },
  ];

  useEffect(() => {
    apiGet({ url: '/international-payment/country-list' }).then(res => {
      let list = res.data;
      let data = [{ label: '', value: '' }];
      for (let key in list) {
        let obj = {};
        obj.label = list[key];
        obj.value = key;
        data.push(obj);
      }

      setCountryList(data);
    });
  }, []);

  useEffect(() => {
    apiGet({ url: '/international-payment/currency-list' }).then(res => {
      let list = res.data;
      let data = [{ label: '', value: '' }];
      for (let key in list) {
        let obj = {};
        obj.label = list[key];
        obj.value = key;
        data.push(obj);
      }

      setCurrencyList(data);
    });
  }, []);

  useEffect(() => {
    apiGet({
      url: '/profile'
    }).then(res => {
      let fio = '';
      if (res?.data) {
        if (res.data?.last_name) {
          fio = res.data.last_name;
        }

        if (res.data?.first_name) {
          fio += ' ';
          fio += res.data.first_name;
        }

        if (res.data?.patronymic) {
          fio += ' ';
          fio += res.data.patronymic;
        }

        setFio(fio);

        if (res.data?.phone) {
          setPhone(res.data.phone);
        }

        if (res.data?.tg) {
          setTg(res.data.tg);
        }
      }
    });
  }, []);

  const buttonClick = () => {
    if (validateForm()) {
      setContactsModal(true);
    }
  };

  const validateForm = () => {
    setValidateErrors(null);
    let formData = getFormData();
    let validateErrorsObj = {};

    if (
      formData.senderType &&
      formData.recipientType &&
      formData.fromCountry &&
      formData.toCountry &&
      formData.fromCurrency &&
      formData.toCurrency &&
      (formData.fromAmount || formData.toAmount)
    ) {
      let amount = formData.fromAmount
        ? formData.fromAmount
        : formData.toAmount;
      if (amount > 0) {
        return true;
      } else {
        if (formData.fromAmount) {
          validateErrorsObj.fromAmount = ['Некорректное значение'];
        } else {
          validateErrorsObj.toAmount = ['Некорректное значение'];
        }
      }

      setValidateErrors(validateErrorsObj);
      return false;
    } else {
      if (!formData.fromAmount && !formData.toAmount) {
        validateErrorsObj.fromAmount = [
          'Необходимо заполнить сумму в одном из полей',
        ];
        validateErrorsObj.toAmount = [
          'Необходимо заполнить сумму в одном из полей',
        ];
      }

      if (!formData.senderType) {
        validateErrorsObj.senderType = ['Необходимо заполнить это поле'];
      }

      if (!formData.recipientType) {
        validateErrorsObj.recipientType = ['Необходимо заполнить это поле'];
      }

      if (!formData.fromCountry) {
        validateErrorsObj.fromCountry = ['Необходимо заполнить это поле'];
      }

      if (!formData.toCountry) {
        validateErrorsObj.toCountry = ['Необходимо заполнить это поле'];
      }

      if (!formData.fromCurrency) {
        validateErrorsObj.fromCurrency = ['Необходимо заполнить это поле'];
      }

      if (!formData.toCurrency) {
        validateErrorsObj.toCurrency = ['Необходимо заполнить это поле'];
      }

      setValidateErrors(validateErrorsObj);
      return false;
    }
  };

  const validateParamOnEmpty = (e, name) => {
    if (e.target.value !== '') {
      setError([name]);
    } else {
      setError([name], 'Необходимо заполнить это поле');
    }
  };

  const validateAmount = e => {
    if (e.target.value !== '') {
      setError(['fromAmount', 'toAmount']);
    } else {
      setError(
        ['fromAmount', 'toAmount'],
        'Необходимо заполнить сумму в одном из полей'
      );
    }
  };

  const setError = (attributes, error = null) => {
    let validateErrorsObj = {};
    for (let key in validateErrors) {
      validateErrorsObj[key] = validateErrors[key];
    }

    attributes.map(attribute => {
      if (error === null) {
        if (validateErrorsObj?.[attribute]) {
          delete validateErrorsObj[attribute];
        }
      } else {
        validateErrorsObj[attribute] = [error];
      }
    });

    setValidateErrors(validateErrorsObj);
  };

  const onChangeFio = (e, name) => {
    setFio(e.target.value);
    validateParamOnEmpty(e, name);
  };

  const getFormData = () => {
    let formData = {
      senderType: watch('selectSenderType'),
      recipientType: watch('selectRecipientType'),
      fromCountry: watch('selectCountryFrom'),
      toCountry: watch('selectCountryTo'),
      fromCurrency: watch('selectCurrencyFrom'),
      toCurrency: watch('selectCurrencyTo'),
      fullName: fio,
      phone: phone,
      tg: tg,
    };

    let fromAmount = watch('fromAmount');
    if (fromAmount) {
      formData.fromAmount = fromAmount;
    }

    let toAmount = watch('toAmount');
    if (toAmount) {
      formData.toAmount = toAmount;
    }

    return formData;
  };

  const buttonClickStep2 = () => {
    setValidateErrors(null);
    setSubmitButtonDisabled(true);

    apiPost({
      url: '/international-payment/create',
      postData: getFormData(),
    }).then(res => {
      if (res?.error) {
        if (res?.status === 406) {
          setValidateErrors(res.data);
        }
      } else {
        setNotification('Мы получили Вашу заявку. Скоро мы с Вами свяжемся.');
        setContactsModal(false);
      }

      setSubmitButtonDisabled(false);
    });
  };

  const onChangeFromAmount = e => {
    validateAmount(e);

    setValue('toAmount', '');
  };

  const onChangeToAmount = e => {
    validateAmount(e);

    setValue('fromAmount', '');
  };

  return (
    <Row className="mt-8">
      <Col lg={6}>
        <Card>
          <CardHeader>
            <div className="sm:text-2xl text-xl font-medium">
              Отправить денежные средства
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="text-lg font-medium mb-2 international-payment-description-block">
                  Укажите данные, откуда средства будут отправляться
                </div>
              </Col>
              <Col xs={12}>
                <Select
                  label="Отправитель"
                  fieldClass="w-full"
                  list={typeList}
                  name="selectSenderType"
                  onChange={e => validateParamOnEmpty(e, 'senderType')}
                  register={register}
                  errors={validateErrors?.senderType}
                />
              </Col>
              <Col xs={12}>
                <Select
                  label="Юрисдикция"
                  fieldClass="w-full"
                  list={countryList}
                  name="selectCountryFrom"
                  onChange={e => validateParamOnEmpty(e, 'fromCountry')}
                  register={register}
                  errors={validateErrors?.fromCountry}
                />
              </Col>
              <Col xs={12}>
                <Select
                  label="Валюта"
                  fieldClass="w-full"
                  list={currencyList}
                  name="selectCurrencyFrom"
                  onChange={e => validateParamOnEmpty(e, 'fromCurrency')}
                  register={register}
                  errors={validateErrors?.fromCurrency}
                />
              </Col>
              <Col xs={12}>
                <Input
                  label="Сумма"
                  type="number"
                  name="fromAmount"
                  onChange={e => onChangeFromAmount(e)}
                  register={register}
                  error={validateErrors?.fromAmount}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Col lg={6}>
        <Card>
          <CardHeader>
            <div className="sm:text-2xl text-xl font-medium">
              Получить денежные средства
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="text-lg font-medium mb-2 international-payment-description-block">
                  Укажите данные, куда нужно отправить средства
                </div>
              </Col>
              <Col xs={12}>
                <Select
                  label="Получатель"
                  fieldClass="w-full"
                  list={typeList}
                  name="selectRecipientType"
                  onChange={e => validateParamOnEmpty(e, 'recipientType')}
                  register={register}
                  errors={validateErrors?.recipientType}
                />
              </Col>
              <Col xs={12}>
                <Select
                  label="Юрисдикция"
                  fieldClass="w-full"
                  list={countryList}
                  name="selectCountryTo"
                  onChange={e => validateParamOnEmpty(e, 'toCountry')}
                  register={register}
                  errors={validateErrors?.toCountry}
                />
              </Col>
              <Col xs={12}>
                <Select
                  label="Валюта"
                  fieldClass="w-full"
                  list={currencyList}
                  name="selectCurrencyTo"
                  onChange={e => validateParamOnEmpty(e, 'toCurrency')}
                  register={register}
                  errors={validateErrors?.toCurrency}
                />
              </Col>
              <Col xs={12}>
                <Input
                  label="Сумма"
                  type="number"
                  name="toAmount"
                  onChange={e => onChangeToAmount(e)}
                  register={register}
                  error={validateErrors?.toAmount}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <div className="flex flex-wrap items-center">
        <Button
          disabled={submitButtonDisabled}
          onClick={buttonClick}
          color="primary"
          className={cx({
            'cursor-default': submitButtonDisabled,
          }, 'ml-5')}
        >
          Отправить заявку
        </Button>
      </div>

      {/*Start ------- Открыть окно уведомления*/}
      <Modal
        title="Контактные данные"
        onClose={() => setContactsModal(false)}
        isOpen={contactsModal}
        footer={
          <Button
            disabled={submitButtonDisabled}
            onClick={buttonClickStep2}
            color={submitButtonDisabled ? 'default' : 'primary'}
            className={cx({
              'cursor-default': submitButtonDisabled,
            })}
          >
            Отправить заявку
          </Button>
        }
      >
        <Col xs={12}>
          <Input
            label="ФИО"
            name="fullName"
            value={fio}
            onChange={e => onChangeFio(e, 'fullName')}
            register={register}
            error={validateErrors?.fullName}
          />
        </Col>

        <Col xs={12}>
          <Input
            label="Номер телефона"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            register={register}
            error={validateErrors?.phone}
          />
        </Col>

        <Col xs={12}>
          <Input
            label="Telegram"
            name="tg"
            value={tg}
            onChange={e => setTg(e.target.value)}
            register={register}
            error={validateErrors?.tg}
          />
        </Col>
      </Modal>

      {/*End ------- Открыть окно уведомления*/}

      {/*Start ------- Открыть окно уведомления*/}
      <Modal
        title={notification}
        onClose={() => setNotification(null)}
        isOpen={!!notification}
        headerStyles={{ border: 'none', color: '#000000' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={() => setNotification(null)} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
      {/*End ------- Открыть окно уведомления*/}
    </Row>
  );
};
