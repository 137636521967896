import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

export const CustomCard = ({ id, img }) => {
  const history = useHistory();

  const goTo = () => {
    if (id == 4) {
      window.location = 'https://t.me/RSI21intensiveBot';
      return;
    }
    history.push(`/lesson/${id}`);
  };

  return (
    <div className="block-card mb-4 cursor-pointer" onClick={() => goTo()}>
      <div className="custom-header ">
        <img className="img" src={img} />
      </div>
    </div>
  );
};
CustomCard.propTypes = {
  name: PropTypes.string,
  img: PropTypes.any,
  id: PropTypes.string,
  // video: PropTypes.string,
};
