import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import videoPoster from '../../../../assets/images/homePoster/1.png';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Icon } from '../../../common/icon';
import { Modal } from '../../../common/ui/modal';

export const ReviewLkVideo = () => {
  const screenWidth = window.screen.width;
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const modalRef = useRef(null);

  const { windowWidth } = useWindowSize();

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.height = `${
        modalRef.current.clientWidth * (9 / 16)
      }px`;
    }
  }, [modalRef, windowWidth]);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  return (
    <>
      <Card>
        {/* <CardHeader>
          <div className="sm:text-2xl text-lg font-medium">
            Обзор личного кабинета RSI CAPITAL
          </div>
        </CardHeader> */}
        <CardBody>
          <button
            onClick={() =>
              openVideo('https://www.youtube.com/embed/LArKNaORNmc?autoplay=1')
            }
            className=" relative"
          >
            <img alt="" src={videoPoster} />

            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark w-24 h-16 md:w-40 md:h-24 rounded-xl flex items-center justify-center">
              <Icon name="playButton" size={40} color="#fff" />
            </span>
          </button>
        </CardBody>
        <Modal
          isOpen={isVideoVisible}
          modalStyles={{
            maxWidth: 1280,
            width: screenWidth < 600 ? '95%' : '60%',
            margin: 'auto',
          }}
          onClose={closeVideo}
          bodyStyles={{ padding: 6, height: '100%' }}
        >
          <div ref={modalRef}>
            {videoUrl && (
              <iframe
                className="modal-video"
                src={videoUrl}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        </Modal>
      </Card>
    </>
  );
};
ReviewLkVideo.propTypes = {
  title: PropTypes.string,
  showFooter: PropTypes.bool,
};
