// import React from "react"
// import { Link } from "react-router-dom"
// import "./index.scss"

// export const Pagination = () => {
// 	return (
// 		<div className="cmp-pagination inline-flex">
// 			<Link to="?page=1" className="flex items-center justify-center _active">
// 				1
// 			</Link>
// 			<Link to="?page=2" className="flex items-center justify-center">
// 				2
// 			</Link>
// 			<Link to="?page=3" className="flex items-center justify-center">
// 				3
// 			</Link>
// 			<Link to="?page=4" className="flex items-center justify-center">
// 				4
// 			</Link>
// 		</div>
// 	)
// }

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory, useLocation } from 'react-router-dom';
import getUrlParams from '../../../hooks/getUrlParams';
import './index.scss';

export const Pagination = ({
  nameGet = 'page',
  lastPage,
  page,
  onPageChange = () => {},
}) => {
  const { pathname, search, hash } = useLocation();
  const history = useHistory();

  const [pagePag, setPagePag] = useState('');

  const pageChanging = e => {
    const { page } = getUrlParams();
    setPagePag(page);

    let newSearch;
    const newPage = e.selected + 1;

    if (search.length) {
      if (page) {
        const regexp = new RegExp(nameGet + '=[0-9]+');
        newSearch = search.replace(regexp, `${nameGet}=${newPage}`);
      } else {
        newSearch = `${search}&${nameGet}=${newPage}`;
      }
    } else {
      newSearch = `?${nameGet}=${newPage}`;
    }

    history.replace(`${pathname}${newSearch}${hash}`);
    onPageChange();
  };

  useEffect(() => {
    const { page } = getUrlParams();
    setPagePag(page);
  }, [window.location.href]);

  useEffect(() => {
    setPagePag(page);
  }, [page]);
  if (!lastPage) {
    return null;
  }

  return (
    <ReactPaginate
      pageCount={lastPage && lastPage === 1 ? 0 : lastPage}
      pageRangeDisplayed={5}
      marginPagesDisplayed={3}
      onPageChange={pageChanging}
      activeClassName={'_active'}
      containerClassName={'cmp-pagination inline-flex'}
      pageLinkClassName={'flex items-center justify-center'}
      pageClassName={'flex items-center justify-center'}
      previousLabel={false}
      nextLabel={false}
      forcePage={pagePag ? +pagePag - 1 : 0}
    />
  );
};
Pagination.propTypes = {
  lastPage: PropTypes.number,
  page: PropTypes.string,
  onPageChange: PropTypes.func,
  nameGet: PropTypes.string,
};
