import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Badge } from '../badge';
import './index.scss';

export const GarantInvest = ({ data }) => {
  let num = +data?.amount;
  return (
    <Link to={`/garant/invest-view/${data?.id}`}>
      <Card className="cmp-garant-invest">
        <CardBody>
          <div className="sm:text-2xl text-md font-medium mb-2">
            {num.toFixed(6)} USDR
          </div>

          <Row className="justify-between items-center">
            <Col>
              <Badge color={data.status === 2 ? 'gray' : 'primary'}>
                {data?.statusText}
              </Badge>
            </Col>
            <Col className="sm:text-md text-xs" xs="auto">
              {data?.createdAt}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
};

GarantInvest.propTypes = {
  data: PropTypes.any,
};
