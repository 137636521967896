import { useEffect, useState } from 'react';
import { apiGet } from '../api';

export const useFetch = ({
  url = null,
  defaultValue = null,
  headerList = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(defaultValue);

  const fetchData = async () => {
    if (url) {
      apiGet({ url, headerList })
        .then(res => setResponse(res))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { response, loading, fetchData, setResponse };
};
