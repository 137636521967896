import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Icon } from '../icon';
import './index.scss';

export const StaticBlock = ({
  bg,
  title,
  value,
  iconName,
  classNameBlock = '',
  itemPos = 'items-center',
  btn = null,
}) => {
  return (
    <Card className="static-block h-full" style={{ background: bg }}>
      <CardBody className={`${classNameBlock} pos flex ${itemPos} h-full`}>
        {iconName && (
          <Icon name={iconName} color="#fff" className="bgIcon pos-abs-y" />
        )}

        <Row className="items-center">
          {iconName && (
            <Col className="col-auto">
              <Icon name={iconName} color="#fff" size={30} />
            </Col>
          )}

          <Col>
            <div className="font-medium">{title}</div>
            <div className="sm:text-2xl text-xl font-medium">{value}</div>
          </Col>

          <Col sm={12}>
            {btn && (
              <Button
                className="py-1 w-full mt-3 bg-white text-text"
                onClick={() => btn.func()}
                color="primary"
              >
                {btn.text}
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
StaticBlock.propTypes = {
  bg: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconName: PropTypes.string,
  classNameBlock: PropTypes.string,
  itemPos: PropTypes.string,
  btn: object,
};
