export const RESONABLE_LESSON = [
  {
    id: 1,
    name: 'Урок 1. Введение',
    video: 'https://player.vimeo.com/video/597242263?h=29d5d3adc1',
    des: '',
  },
  {
    id: 2,
    name: 'Урок 2. Критически важный навык в 21 веке.',
    video: 'https://player.vimeo.com/video/597246963?h=dcf4ed80c7',
    des: '',
  },
  {
    id: 3,
    name: 'Урок 3. Личная мотивация.',
    video: 'https://player.vimeo.com/video/597251960?h=c2e9619bbf',
    des: '',
  },
  {
    id: 4,
    name: 'Урок 4. Эффектный мани-менеджмент.',
    video: 'https://player.vimeo.com/video/597259487?h=e317ab21be',
    des: '',
  },
  {
    id: 5,
    name: 'Урок 5. Стратегия увеличения положительной дельты.',
    video: 'https://player.vimeo.com/video/597263547?h=0175ed1d17',
    des: '',
  },
  {
    id: 6,
    name: 'Урок 6. Инвестиционная "кубышка".',
    video: 'https://player.vimeo.com/video/597266051?h=5edd8a1d25',
    des: '',
  },
  {
    id: 7,
    name: 'Урок 7. Как не потерять капитал.',
    video: 'https://player.vimeo.com/video/597268487?h=2b3732467f',
    des: '',
  },
  {
    id: 8,
    name: 'Урок 8. Базовые инструменты Клуба.',
    video: 'https://player.vimeo.com/video/597271391?h=dbb0d4fa68',
    des: '',
  },
  {
    id: 9,
    name: 'Урок 9. Заключение. Подведение итогов.',
    video: 'https://player.vimeo.com/video/597275620?h=b9d6a6ef10',
    des: '',
  },
];
