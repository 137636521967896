import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { apiPost } from '../../../../../api';
import Spinner from '../../../../common/spinner';
import { Button } from '../../../../common/ui/button';

const buttonList = [
  {
    text: 'Добавить в левую ветку',
    value: 1,
  },
  {
    text: 'Добавлять автоматически',
    value: 0,
  },
  {
    text: 'Добавить в правую ветку',
    value: 2,
  },
];

export const BinaryStructureAddButtons = ({ btnConfirm }) => {
  const [side, setSide] = useState(btnConfirm);

  useEffect(() => {
    setSide(btnConfirm);
  }, [btnConfirm]);

  const changeSettings = val => {
    apiPost({
      url: '/binary/set-to-setting',
      postData: { setTo: val },
    }).then(res => {
      if (res.status === 406) {
        return;
      }
      if (!res.error) {
        setSide(val);
      }
    });
    setSide(val);
  };

  const getSideColor = v => {
    let res = 'default';

    if (side === v) {
      res = 'primary';
    }
    if (side === v) {
      res = 'primary';
    }
    if (side === v) {
      res = 'primary';
    }

    return res;
  };

  const renderButton = (i, ind) => {
    return (
      <Col
        xl={{ size: 4 }}
        sm={{
          size: ind === 1 ? 12 : 6,
          order: ind === 0 ? 0 : ind === 1 ? 2 : ind === 0 && 1,
        }}
        key={ind}
        className={'xs: mt-2 xl:mt-0'}
      >
        <Button
          color={getSideColor(i.value)}
          className="w-full"
          onClick={() => changeSettings(i.value)}
        >
          {i.text}
        </Button>
      </Col>
    );
  };

  return (
    <Row>
      {side !== null ? (
        buttonList.map(renderButton)
      ) : (
        <Spinner size={60} styles={{ margin: 'auto' }} />
      )}
    </Row>
  );
};

BinaryStructureAddButtons.propTypes = {
  btnConfirm: PropTypes.any,
};
