import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CardBody, CardHeader } from 'reactstrap';
import { apiGet } from '../../../../api';
import { useIsTouchDevice } from '../../../../hooks/useIsTouchDevice';
import { Icon } from '../../icon';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { TreeNodeInfo } from '../nodeInfo';
import './index.scss';
import { BinaryTreeNode } from './node';

export const BinaryTree = () => {
  const { register, watch, setValue } = useForm();

  const ref = useRef(null);
  const userDataRef = useRef(null);
  const [searchList, setSearchList] = useState([]);
  const [searchUser, setSearchUser] = useState(0);

  const isTouch = useIsTouchDevice();

  const [grab, setGrab] = useState(false);
  const [binaryData, setBinaryData] = useState({});
  const [profileDataInfo, setProfileDataInfo] = useState(null);

  const getSearchList = () => {
    apiGet({
      url: `/binary/find-children?q=${watch('searchName')}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setSearchList(res.data);
        }
      }
    });
  };

  const getBinaryData = () => {
    apiGet({
      url: '/binary',
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка загрузки бинарной структуры');
          return;
        }
        if (!res.error) {
          setBinaryData(res.data);
          setValue('searchName', '');
          setSearchUser(0);
        }
      }
    });
  };

  const openUserData = userId => {
    apiGet({
      url: `/binary/detail?id=${userId}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке profile');
          return;
        }
        if (!res.error) {
          setProfileDataInfo(res.data);
          userDataRef.current.open(userId);
        }
      }
    });
  };

  const toggleFullScreen = () => {
    ref.current.classList.toggle('_full');
  };

  useEffect(() => {
    getBinaryData();
  }, []);

  const renderItem = (i, ind) => {
    return (
      <BinaryTreeNode
        key={ind}
        data={i}
        parentIndex={ind}
        openUser={openUserData}
      />
    );
  };

  useEffect(() => {
    if (!watch('searchName')) {
      setSearchList([]);
      return;
    }

    if (watch('searchName') !== binaryData.name) {
      getSearchList();
    } else setSearchList([]);
  }, [watch('searchName')]);

  const toggleGrab = () => setGrab(!grab);

  const renderSearchList = (i, index) => {
    const goToPeople = () => {
      setBinaryData(i);
      setValue('searchName', i.name || i.id);
      setSearchUser(1);
    };
    return (
      <div key={index} className="item" onClick={goToPeople}>
        {i.name}
        <br />
        {i.id && `id: ${i.id}`}
      </div>
    );
  };

  return (
    <>
      <div className="row justify-end mb-3">
        {searchUser === 1 && (
          <div className="col-sm-auto mb-2 sm:mb-0">
            <Button color="primary" className="w-full" onClick={getBinaryData}>
              Очистить поиск
            </Button>
          </div>
        )}

        <div className="col-sm-auto relative">
          <Input
            placeholder="Поиск пользователя"
            fieldClass="w-full sm:w-60 mb-0"
            name="searchName"
            autoComplete="off"
            register={register}
          />
          <div className="search-list">{searchList.map(renderSearchList)}</div>
        </div>
      </div>

      <div
        ref={ref}
        className={cx(
          'card cmp-binary-tree overflow-hidden fullscreen-tree w-full overflow-hidden pos',
          {
            _touch: isTouch,
          }
        )}
      >
        {isTouch && (
          <div
            onClick={toggleFullScreen}
            className="open-full flex items-center justify-center image-contain z-10 pos-abs text-white text-lg"
          >
            Раскрыть структуру
          </div>
        )}

        <div className="close-full p-3 justify-between font-medium">
          <span>Бинарная структура</span>

          <div
            onClick={toggleFullScreen}
            className="cursor-pointer flex items-center justify-center"
          >
            <Icon name="close" size={16} />
          </div>
        </div>

        <CardHeader className="p-3 bg-purple text-white text-xs">
          <ul className="flex font-light">
            <li>
              <u className="cursor-pointer">Моя ветка</u>
            </li>
            <li>Ветка просмотра</li>
          </ul>
        </CardHeader>

        <CardBody
          className="inner-wrap p-0 relative overflow-hidden"
          style={{
            cursor: grab ? 'grabbing' : 'grab',
          }}
        >
          <TransformWrapper
            wheel={{ step: 0.05 }}
            initialScale={0.8}
            initialPositionX={30}
            initialPositionY={30}
            minScale={0.5}
            maxScale={2}
            limitToBounds={false}
          >
            {({ zoomIn, zoomOut }) => (
              <>
                <div className="block-translate">
                  <button onClick={() => zoomIn()}>
                    <Icon size={8} name="plus" />
                  </button>
                  <button onClick={() => zoomOut()}>
                    <Icon size={8} name="minus" />
                  </button>
                </div>
                <TransformComponent>
                  <div
                    className="flex flex-col items-center"
                    onMouseDown={toggleGrab}
                    onMouseUp={toggleGrab}
                  >
                    {binaryData && renderItem(binaryData)}
                  </div>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </CardBody>
      </div>

      <TreeNodeInfo data={profileDataInfo} ref={userDataRef} />
    </>
  );
};
