export const appealListThemes = [
  { label: 'Выбор', value: '0' },
  { label: 'Техническая проблема на сайте', value: '1' },
  { label: 'Проблема с выводом средств', value: '2' },
  { label: 'Проблема с пополнением средств', value: '3' },
  { label: 'Проблема с начислением дивидендов и вознаграждений', value: 4 },
  { label: 'Пожелание', value: 5 },
  { label: 'Вопрос', value: 6 },
  { label: 'Другое', value: 7 },
];
