/* eslint-disable */
import ReactPaginate from 'react-paginate';
import React, {useEffect, useRef, useState} from 'react';
// import {
//   default as ReactApexChart,
// } from 'react-apexcharts';
import {useForm} from 'react-hook-form';
import {
    Card,
    CardBody, CardFooter, CardHeader, Col, Container,
    // PaginationItem, PaginationLink, Pagination,
    Row, Table,
} from 'reactstrap';
import {apiGet, apiPost} from '../../../../api';
import {getActiveClass} from '../../../../helpers/className';
import {useFetch} from '../../../../hooks/useFetch';
import {BillBlock} from '../../../common/billBlock';
import Spinner from '../../../common/spinner';
import {Button} from '../../../common/ui/button';
import {Input} from '../../../common/ui/input';
import {Modal} from '../../../common/ui/modal';
import './index.scss';
import '../../../../components/common/pagination';
import logo from '../../../../assets/images/logo.png';
import {ChartComponent} from '../chart_light';

import {Centrifuge} from 'centrifuge';
import {Icon} from '../../../common/icon';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import { useHistory } from 'react-router';
import styles from "../../../common/smart/description/index.module.scss";

export const IndexTypePage = () => {
    const { id } = useParams();
    const history = useHistory();

    const {setValue, handleSubmit} = useForm();
    const [totalCount, setTotalCount] = useState('');
    const [averagePrice, setAveragePrice] = useState('');
    const [currentRate, setCurrentRate] = useState('');
    const [isFetch, setIsFetch] = useState(false);
    const [procentValue, setProcentValue] = useState(0);
    const [historyTable, setHistoryTable] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    const [tablePage, setTablePage] = useState(1);
    const [tableLastPage, setTableLastPage] = useState(1);
    const [isSpinner, setIsSpinner] = useState(false);
    const [currentAmmount, setCurrentAmmount] = useState('');
    const [totalInvestAmount, setTotalInvestAmount] = useState('');
    const [indexType, setIndexType] = useState(null);

    // Значения для Графика
    const [timeFrame, setTimeFrame] = useState('day');
    const [frameCount, setFrameCount] = useState(100);
    const [timeSwitcher, setTimeSwitcher] = useState(false);
    const [smartIndexGraphY, setSmartIndexGraphY] = useState([]);
    const [graphIsFetch, setGraphIsFetch] = useState(false);
    const [newDataArray, setNewDataArray] = useState(0);

    // Кнорки Д Н М Г для графика
    const [dayButton, setDayButton] = useState('mx-0.5 bg-grey text-blue px-3 py-0.5');
    const [oneMinButton, setOneMinButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    const [fiveMinButton, setFiveMinButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    const [oneHourButton, setOneHourButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    const [weekButton, setWeekButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    const [fourHourButton, setFourHourButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    const [monthButton, setMonthButton] = useState('mx-0.5 text-blue px-3 py-0.5');
    // const [yearButton, setYearButton] = useState('mx-0.5 text-blue px-3 py-0.5');

    const [investWallet, setInvestWallet] = useState(null);
    const [cryptoAmmount, setCryptoAmmount] = useState('');
    const [errObj, setErrObj] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [alertView, setAlertView] = useState(false);
    const [sellAlertView, setSellAlertView] = useState(false);
    const [changeToBonusAlertView, setChangeToBonusAlertView] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateValue, setUpdateValue] = useState(null);
    const [updateTime, setUpdateTime] = useState(null);
    const [graphPage, setGraphPage] = useState(2);
    const [visibleButton, setVisibleButton] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    const [isButtonClick, setIsButtonClick] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Данные о крипто кошельках
    const [walletDataCrypto, setWalletDataCrypto] = useState(null);

    const [centrifuge, setCentrifuge] = useState(null);

    const handleAlertClose = () => {
        setIsSpinner(false)
        setProcentValue(0);
        setCurrentAmmount('');
        setInputValue('');
        setCryptoAmmount('');
        setSuccessModal(false);
        setAlertView(false);
        setSellAlertView(false);
        setChangeToBonusAlertView(false);
        setErrObj({});
    };

    useEffect(() => {
        apiGet({url: `/index-type/view?id=${id}`})
          .then(res => {
              if (res.error)
                  return console.log(res);
              setIndexType(res);
              setInvestWallet(res.data.wallet);
          })
          .catch(e => console.log(e));

        apiGet({
            url: '/wallet/crypto?onlyForPayProduct=1'
        }).then(res => {
            if(res.error)
                return;
            setWalletDataCrypto(res.data)
        })

    }, []);

    const {response: courseCrypto} = useFetch({url: '/commissions'});

    // For Graph linear

    const handleSellAmountChange = ({target: {value}}) => {
        setInputValue(value);
    };

    const handleInvestAmountChange = ({target: {value}}) => {
        setInputValue(value);
        setProcentValue((value * currentRate));
        setCurrentAmmount(value);

        if (investWallet === 'ETH' || investWallet === 'BTC') {

            let clearAmmout = (value * currentRate) / courseCrypto.data[`${investWallet}`].REPLENISH_RATE.value;

            setCryptoAmmount(clearAmmout);
        } else {
            setCryptoAmmount('');
        }
    };

    const handleChangeBillBlock = value => {
        setErrObj({});
        if (value === 'ETH' || value === 'BTC') {
            let procent = (currentAmmount * currentRate) * 0.07 / courseCrypto.data[`${value}`].REPLENISH_RATE.value;
            let ammount = (currentAmmount * currentRate) / courseCrypto.data[`${value}`].REPLENISH_RATE.value;
            setCryptoAmmount((procent + ammount));
            setInvestWallet(value);
        } else {
            setCryptoAmmount('');
            setInvestWallet(value);
        }
    };

    const handleSellIndex = () => {
        setIsSpinner(true);

        if (inputValue === '' || inputValue === '0') {
            setIsSpinner(false);
            return setErrObj({source: ['Введите количество больше 0 для покупки']});
        }

        apiPost({
            url: `/index/sell?indexTypeId=${id}`,
            postData: {
                count: inputValue,
            },
        }).then(res => {
            if (res.error) {
                if (res.data.count[0] === 'Значение «Count» должно быть числом.')
                    setErrObj({count: 'Значение должно быть числом'});
                else
                    setErrObj(res?.data);
                return setIsSpinner(false);
            }

            apiGet({url: `/index/info?indexTypeId=${id}`})
                .then(res => {
                    if (res.error)
                        return console.log(res.data);
                    setTotalCount(res?.data?.totalCount);
                    setAveragePrice(res?.data?.averagePrice);
                    setCurrentRate(res?.data?.currentRate);
                    setTotalInvestAmount(res?.data?.totalInvestAmount)

                    // setIsFetch(true)
                })
                .catch(e => console.log(e));

            apiGet({
                url: `/index?page=${tablePage}&index_type=${id}&limit=10`,
            }).then(res => {
                if (res.error)
                    return;
                setHistoryTable(res?.data?.models);

                setTableLastPage(res.data.lastPage);
                let arr = [];
                for (let i = 1; i <= tableLastPage; i++) {
                    arr.push(i);
                }
            });

            handleAlertClose();
            refreshWalletData();
            setIsSpinner(false);
            setSuccessModal(true);
        });
    };

    const investToIndex = () => {
        setIsSpinner(true);

        if (inputValue === '' || inputValue === '0' || inputValue === 0) {
            setIsSpinner(false);
            return setErrObj({source: ['Введите количество больше 0 для покупки']});
        }
        apiPost({
            url: `/index/buy?indexTypeId=${id}`,
            postData: {
                count: inputValue,
                source: investWallet,
            },
        }).then(res => {
            if (res.error) {
                setErrObj(res?.data);
                setIsSpinner(false);
                return;
            }

            apiGet({url: `/index/info?indexTypeId=${id}`})
                .then(res => {
                    setTotalCount(res?.data?.totalCount);
                    setAveragePrice(res?.data?.averagePrice);
                    setCurrentRate(res?.data?.currentRate);
                    setTotalInvestAmount(res?.data?.totalInvestAmount)
                    // setIsFetch(true)
                }).catch(e => console.log(e));

            apiGet({
                url: `/index?page=${tablePage}&index_type=${id}&limit=10`,
            }).then(res => {
                if (res.error)
                    return;
                setHistoryTable(res?.data?.models);

                setTableLastPage(res.data.lastPage);
                let arr = [];
                for (let i = 1; i <= tableLastPage; i++) {
                    arr.push(i);
                }
            });

            setTotalCount(totalCount + res?.data?.count);
            setErrObj({});
            setSuccessModal(true);
            handleAlertClose();
            refreshWalletData();
            setIsSpinner(false);
        });
    };

    const renderBillBlock = ({name, balance}) => {
        if (indexType?.data?.wallet !== name)
            return;

        let balanced = +balance;
        return (
            <Col key={name} xs={12} sm={6}>
                <BillBlock
                    name={name}
                    value={name === 'USDT (ERC20)' || name === 'USDT (TRC20)' ? balanced.toFixed(2) : balanced}
                    activeClass={getActiveClass(name, investWallet)}
                    onClick={handleChangeBillBlock}
                />
            </Col>
        );
    };

    useEffect(() => {
        apiGet({url: `/index/info?indexTypeId=${id}`})
            .then(res => {
                if (res.error)
                    return console.log(res);
                setTotalCount(res?.data?.totalCount);
                setAveragePrice(res?.data?.averagePrice);
                setCurrentRate(res?.data?.currentRate);
                setTotalInvestAmount(res.data.totalInvestAmount)
                setIsFetch(true);
            })
            .catch(e => console.log(e));

        apiGet({
            url: '/wallet/crypto?onlyForPayProduct=1'
        }).then(res => {
            if(res.error)
                return;
            setWalletDataCrypto(res.data)
        })

    }, []);

    const handlerMaxValue = () => {
        setErrObj({});

        let value = {};

        walletDataCrypto.map(item => {
            if (item.name === investWallet) {
                value.balance = item.balance;
                value.wallet = investWallet;
            }
        });

        let indexCount = value.balance / currentRate;

        setCurrentAmmount(Math.floor(indexCount));
        setInputValue(Math.floor(indexCount));
        return setValue('investAmountCrypto', Math.floor(indexCount));
    };

    useEffect(() => {
        apiGet({
            url: `/index?page=${tablePage}&index_type=${id}&limit=10`,
        }).then(res => {
            if (res.error)
                return console.log(res);
            setHistoryTable(res?.data?.models);
            setTableLastPage(res.data.lastPage);
            let arr = [];
            for (let i = 1; i <= tableLastPage; i++) {
                arr.push(i);
            }
        });
    }, [tablePage]);

    const pageChanging = (data) => {
        setTablePage(data.selected + 1);
    };

    // Добавление старых значений на график - при нажатии на кнопку - загрузить еще
    const getPriceGraph = (getMore) => {
        if(getMore !== 'more')
            setIsLoading(true)
        setIsButtonClick(true)
        apiGet({
            url: `/index/price-graph?indexTypeId=${id}&page=${graphPage}&timeFrame=${timeFrame}&count=${frameCount}`,
        }).then(async res => {
            if (res.error){
                setIsButtonClick(false)
                return console.log(res.data);
            }
            setTimeSwitcher(true)
            setLastPage(res.data.isHasNextPage)
            let arr = smartIndexGraphY
            // let newArray = []
            res.data.points.map(item => {
                if (item.value === null){
                    return;
                }

                if(item.unixtime >= arr[0].time){
                    return;
                }

                let obj = {}
                obj.time = item.unixtime
                // if (item.value !== null)
                obj.value = item?.value;
                arr.unshift(obj)
                // newArray.unshift(obj)
            })

            // setNewDataArray(newArray.length-1)
            setNewDataArray(arr.length-1)
            setSmartIndexGraphY(arr)
            setGraphPage(graphPage + 1)
            setIsUpdate(!isUpdate)
            setIsButtonClick(false)
            setIsLoading(false)
        }).catch(e => {
            console.log(e);
        });
    }

    // Массив time / value для графика
    useEffect(() => {
        setIsLoading(true)
        apiGet({
            url: `/index/price-graph?indexTypeId=${id}&page=${1}&timeFrame=${timeFrame}&count=${frameCount}`,
        }).then(async res => {
            if (res.error)
                return console.log(res.data);
            // setUpdateTime(res.data[0].unixtimeFinish)
            // setTimeSwitcher(true)
            setLastPage(res.data.isHasNextPage)
            let arrX = [];
            // let arr = res.data;

            res.data.points.map(item => {
                let obj = {}
                obj.time = item.unixtime
                if (item.value !== null)
                    obj.value = item?.value;
                    // obj.value = (parseFloat(item?.value.toFixed(4)));
                if (item.value === null)
                    return
                arrX.unshift(obj)
            })
            setSmartIndexGraphY(arrX)
            setIsLoading(false)
            // setIsUpdate(true)
            // setGraphIsFetch(true);
            centrifugoFunc(timeFrame)
        }).catch(e => {
            console.log(e);
        });

    }, [timeFrame]);

    // WebSockets
    const centrifugoFunc = (timeframe1) => {
        if (!centrifuge) return;

        const existSub0 = centrifuge.getSubscription('INDEX_' + id + '_1m');
        if (existSub0)
            centrifuge.removeSubscription(existSub0);

        const existSub1 = centrifuge.getSubscription('INDEX_' + id + '_day');
        if (existSub1)
            centrifuge.removeSubscription(existSub1);

        const existSub2 = centrifuge.getSubscription('INDEX_' + id + '_4h');
        if (existSub2)
            centrifuge.removeSubscription(existSub2);

        const existSub3 = centrifuge.getSubscription('INDEX_' + id + '_week');
        if (existSub3)
            centrifuge.removeSubscription(existSub3);

        const existSub4 = centrifuge.getSubscription('INDEX_' + id + '_5m');
        if (existSub4)
            centrifuge.removeSubscription(existSub4);

        const existSub5 = centrifuge.getSubscription('INDEX_' + id + '_1h');
        if (existSub5)
            centrifuge.removeSubscription(existSub5);

        const existSub6 = centrifuge.getSubscription('INDEX_' + id + '_month');
        if (existSub6)
            centrifuge.removeSubscription(existSub6);

        const sub = centrifuge.newSubscription('INDEX_' + id + '_' + timeframe1);

        // Start ------------- Тут приходят значения через websocket-----------
        sub.on('publication', function (ctx) {
            console.log(ctx.data.value, ctx.data.unixtime);
            setCurrentRate(ctx.data.value)

            setUpdateTime(ctx.data.unixtime)
            setUpdateValue(ctx.data.value)
            // END ------------- Тут приходят значения через websocket-----------
        }).subscribe();
    }

    // -----Start----Переключение таймфреймов на графике
    const timeFrameHandleClick = (time, count) => {
        setTimeFrame(time);
        setFrameCount(count);

        setGraphPage(2)

        if (time === '1m') {
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === '5m') {
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === '1h') {
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === '4h') {
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === 'day') {
            setDayButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === 'week') {
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setWeekButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 text-blue px-3 py-0.5');
        }
        if (time === 'month') {
            setDayButton('mx-0.5 text-blue px-3 py-0.5');
            setWeekButton('mx-0.5 text-blue px-3 py-0.5');
            setOneMinButton('mx-0.5 text-blue px-3 py-0.5');
            setFourHourButton('mx-0.5 text-blue px-3 py-0.5');
            setMonthButton('mx-0.5 bg-grey text-blue px-3 py-0.5');
            setFiveMinButton('mx-0.5 text-blue px-3 py-0.5');
            setOneHourButton('mx-0.5 text-blue px-3 py-0.5');
        }
    };
    // -----End----Переключение таймфреймов на графике

    // Start-----------Получить токен для авторизации вебсокетов
    const getToken = () => {
        apiGet({
            url: '/socket/get-token'
        }).then(res => {
            if (res.error)
                return
            // setSessionWSToken(res.data)
            const centrifugo = new Centrifuge('wss://centrifugo.rsi-capital.com/connection/websocket', {token: res.data});
            setCentrifuge(centrifugo)
            centrifugo.connect();
        })
    }
    // End-----------Получить токен для авторизации вебсокетов

    // Сначала полчаем токен и после запускаем цинтрифугу с вебсокетами

    useEffect(() => {
        if (centrifuge === null) {
            return getToken()
        }

        centrifugoFunc('day')
    }, [centrifuge])

    // Загрузить еще значений для графика
    const uploadMore = () => {
        getPriceGraph('more')
    }

    if (!isFetch) {
        return (
            <Spinner styles={{ margin: 'auto', display: 'flex'}} />
        );
    } else {
        return (
            <Container fluid>
                <Button
                  className='mr-6 back-to'
                  onClick={() => history.push("/index")}
                  color="primary">
                    <Icon name="arrowLeft" size={20} color='white'/>
                    назад
                </Button>

                <Row className='mt-8 flex items-stretch'>
                    <Col lg={8}>
                                {isLoading ?
                                    <Spinner styles={{ margin: 'auto', display: 'flex', height: 300, alignItems: 'center'}} /> :
                        <Card>
                            <CardBody>
                                <div className='current-sale-container'>
                                    <div className={'mb-3 flex justify-between'}>
                                        <div className={'font-bold text-2xl'}>{indexType?.data?.name}</div>
                                        {/*<button onClick={showMeMore}>show</button>*/}
                                        <div className={'flex'}>
                                            <Button onClick={() => timeFrameHandleClick('1m', 100)}
                                                    className={oneMinButton}>1m</Button>
                                            <Button onClick={() => timeFrameHandleClick('5m', 100)}
                                                    className={fiveMinButton}>5m</Button>
                                            <Button onClick={() => timeFrameHandleClick('1h', 100)}
                                                    className={oneHourButton}>1h</Button>
                                            <Button onClick={() => timeFrameHandleClick('4h', 100)}
                                                    className={fourHourButton}>4ч</Button>
                                            <Button onClick={() => timeFrameHandleClick('day', 100)}
                                                    className={dayButton}>Д</Button>
                                            <Button onClick={() => timeFrameHandleClick('week', 100)}
                                                    className={weekButton}>Н</Button>
                                        </div>

                                    </div>
                                    <div>
                                        {smartIndexGraphY.length > 0 &&
                                            <div className='relative'>
                                                <div className='z-10'>

                                            <ChartComponent
                                                newDataArray={newDataArray}
                                                setNewDataArray={setNewDataArray}
                                                timeSwitcher={timeSwitcher}
                                                setTimeSwitcher={setTimeSwitcher}
                                                setVisibleButton={setVisibleButton}
                                                graphIsFetch={graphIsFetch}
                                                            setGraphIsFetch={setGraphIsFetch}
                                                            data={smartIndexGraphY} isUpdate={isUpdate}
                                                            setIsUpdate={setIsUpdate} updateValue={updateValue}
                                                            updateTime={updateTime}
                                                            getPriceGraph={getPriceGraph}/>
                                                </div>
                                                {(lastPage && visibleButton?.from < 1) &&
                                        <Button
                                            disabled={isButtonClick}
                                            color={isButtonClick ? 'gray' : 'primary'}
                                            className='absolute left-11 top-60 left-2 z-20 text-xs p-1 ml-2'
                                            onClick={uploadMore}>
                                            Загрузить еще
                                            {/*<Icon className={'mx-3'} size={30} name={'arrowLeft'} color={'#4d4d4d'} />*/}
                                        </Button>}
                                            </div>}
                                    </div>
                                    </div>
                            </CardBody>
                            <CardFooter>
                                <div className={'flex justify-center gap-3 flex-wrap'}>
                                    <div className={''}>
                                        <Button
                                            color={'primary'}
                                            className={'font-bold max-xs:w-40 xl:px-16 sm:py-3 xs:py-3 xs:px-10'}
                                            onClick={() => setAlertView(true)}>Купить</Button>
                                    </div>
                                    <div>
                                        <Button
                                            color={'primary'}
                                            className={'font-bold max-xs:w-40 xl:px-16 sm:py-3 xs:py-3 xs:px-10'}
                                            onClick={() => setSellAlertView(true)}>Продать</Button>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>}
                    </Col>
                    <Col lg={4}>
                        <Card className='stretchClassName'>
                            <div className='mb-8'>
                                <div className='items-stretch'>
                                    <div className={'flex justify-between py-5 px-5'}>
                                        <div className={'text-left text-xl font-bold mt-2'}>Статистика INDEX</div>
                                        <div className={'w-7'}>
                                            <img src={logo} alt={'logo'}/>
                                        </div>
                                    </div>
                                    <div className={'flex justify-between p-5'}>
                                        <div>Стоимость INDEX</div>

                                        <div className={'font-bold'}>$ {currentRate.toFixed(4)}</div>
                                    </div>
                                    <hr className={'opacity-10'}/>

                                    <div className={'flex justify-between p-5'}>
                                        <div>Объем инвестиций</div>
                                        <div className={'font-bold'}>
                                            $ {totalInvestAmount === null ? '0' : +totalInvestAmount.toFixed(2)}
                                        </div>
                                    </div>

                                    <hr className={'opacity-10'}/>

                                    <div className={'flex justify-between p-5'}>
                                        <div>Количество INDEX</div>
                                        <div className={'font-bold'}>{+totalCount.toFixed(2)}</div>
                                    </div>

                                    <hr className={'opacity-10'}/>

                                    <div className={'flex justify-between p-5'}>
                                        <div>Средняя цена покупки</div>
                                        <div className={'font-bold'}>
                                            $ {averagePrice === null ? '0' : averagePrice.toFixed(4)}
                                        </div>
                                    </div>

                                    <hr className={'opacity-10'}/>

                                    <div className={'flex justify-between p-5'}>
                                        <div>Стоимость инвестиций</div>
                                        <div className={'font-bold'}>
                                            {totalCount === 0 ? '$ 0' : '$ ' + (currentRate * totalCount).toFixed(4)}
                                        </div>
                                    </div>

                                    <hr className={'opacity-10'}/>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Card>
                    <CardHeader className="flex items-center">
                        <div className="sm:text-2xl text-lg font-medium">Описание</div>
                    </CardHeader>
                    <CardBody className={styles.content}>
                        <div dangerouslySetInnerHTML={{ __html: indexType?.data?.text }}></div>
                    </CardBody>
                    {/*<CardFooter>*/}
                    {/*    <div className={'flex justify-end'}>*/}
                    {/*        <a*/}
                    {/*          href="https://api.rsicrypto.com/docs/smart_index_tech.pdf"*/}
                    {/*          data-for="techLib"*/}
                    {/*          data-tip={'Техническая документация'} rel="noreferrer"*/}
                    {/*        >*/}
                    {/*            <Icon className={'mx-3'} size={30} name={'techicalBook'} color={'#4d4d4d'}/>*/}
                    {/*        </a>*/}
                    {/*        <a*/}
                    {/*          href={'https://api.rsicrypto.com/docs/smart_index.pdf'}*/}
                    {/*          data-for="present"*/}
                    {/*          data-tip={'Презентация'}*/}
                    {/*        >*/}
                    {/*            <Icon className={'mx-3'} size={30} name={'presentation'} color={'#4d4d4d'} />*/}
                    {/*        </a>*/}
                    {/*        /!*<a*!/*/}
                    {/*        /!*  data-for="memorandum"*!/*/}
                    {/*        /!*  data-tip={'Инвестиционный меморандум'}*!/*/}
                    {/*        /!*>*!/*/}
                    {/*        /!*  <Icon className={'mx-3'} size={30} name={'investMemo'} color={'#4d4d4d'} />*!/*/}
                    {/*        /!*</a>*!/*/}
                    {/*    </div>*/}
                    {/*</CardFooter>*/}
                </Card>

                {/*<ReactTooltip*/}
                {/*    id="techLib"*/}
                {/*    globalEventOff="click"*/}
                {/*    className="tooltip-modal z-20"*/}
                {/*    effect="solid"*/}
                {/*/>*/}

                <Table striped>
                    <thead>
                    <tr>
                        <th>Продажи / Покупки</th>
                        <th>Количество</th>
                        <th>Eдиницы</th>
                        <th>Общая стоимость</th>
                        <th>Дата покупки/продажи</th>
                    </tr>
                    </thead>
                    <tbody>
                    {historyTable.map(item =>
                        <tr key={item.id}>
                            <th scope='row' className={item.count < 0 ? 'text-red' : 'text-green'}>
                                {item.count < 0 ? <div className={'font-bold'}>Продано</div> :
                                    <div className={'font-bold'}>Куплено</div>}
                            </th>
                            <td align='center'>
                                {item.count < 0 ? Math.abs(item.count) : item.count}
                            </td>
                            <td>
                                {item.rate.toFixed(2)}
                            </td>
                            <td>
                                {/*{item.currentPrice.toFixed(2)}*/}
                                {+(Math.abs(item.count * item.rate)).toFixed(2)}
                            </td>
                            <td>
                                {item.createdAt}
                            </td>
                        </tr>,
                    )}
                    </tbody>

                </Table>
                <div className='mx-auto mt-2'>
                    <ReactPaginate
                        pageCount={tableLastPage === 1 ? 0 : tableLastPage}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={3}
                        onPageChange={pageChanging}
                        activeClassName={'_active'}
                        containerClassName={'cmp-pagination inline-flex'}
                        pageLinkClassName={'flex items-center justify-center'}
                        pageClassName={'flex items-center justify-center'}
                        previousLabel={false}
                        nextLabel={false}
                        forcePage={tablePage ? +tablePage - 1 : 0}
                    />
                </div>

                <Modal
                    title='Покупка INDEX'
                    onClose={handleAlertClose}
                    isOpen={alertView}
                    headerStyles={{border: 'none'}}
                    footerStyles={{border: 'none', padding: '10px'}}
                    settings='alert'
                    bodyStyles={{padding: '20px'}}
                    modalStyles={{margin: 'auto'}}
                >

                    <form onSubmit={handleSubmit(investToIndex)}>
                        <CardBody>

                            <Input
                                label={'Количество INDEX'}
                                value={inputValue}
                                // register={register}
                                name='investAmountCrypto'
                                onChange={handleInvestAmountChange}
                                error={errObj?.count}
                            />
                            {/*)}*/}
                            <div>
                                <div className='xs:block xs:text-left sm:flex mb-3 flex justify-between'>
                                    <div>
                                        <button color={'primary'} className={'mb-3'} type={'button'}
                                                onClick={() => handlerMaxValue()}>Макс
                                        </button>
                                    </div>
                                    {/*Расскоментить если нужно 7 %*/}
                                    {/*<div>*/}
                                    {/*    {currentAmmount * currentRate === 0 || isNaN(currentAmmount * currentRate) ? '' : ' Итого: ' + ((currentAmmount * currentRate) + (currentAmmount * currentRate * 0.07)).toFixed(2) + 'USDT'}*/}
                                    {/*</div>*/}
                                    {/*Расскоментить если нужно 7 %*/}
                                    <div>
                                        {/*Расскоментить если нужно 7 %*/}
                                        {/*Комиссия 7%*/}
                                        {/*Расскоментить если нужно 7 %*/}
                                        Комиссия 0%
                                        {/*Расскоментить если нужно 7 %*/}
                                        {/*{*/}
                                        {/*    procentValue === 0 ? '' :*/}
                                        {/*        ': ' + (procentValue * 0.07).toFixed(3) + ' USDT '*/}
                                        {/*}*/}
                                        {/*Расскоментить если нужно 7 %*/}
                                    </div>
                                </div>
                            </div>

                            <div className='flex m-auto flex-wrap'>
                            {walletDataCrypto !== null && walletDataCrypto?.map(renderBillBlock)}
                            </div>


                            {/*{walletData?.status === 200 && (*/}
                            {/*    <Row>*/}
                            {/*        {walletData ? (*/}
                            {/*            walletData?.data?.map(renderBillBlock)*/}
                            {/*        ) : (*/}
                            {/*            <Spinner styles={{margin: 'auto'}}/>*/}
                            {/*        )}*/}
                            {/*    </Row>*/}
                            {/*)}*/}

                            {/*<button onClick={() => console.log(walletDataCrypto)}>show</button>*/}

                            {walletDataCrypto === null ? <>
                                <Spinner styles={{ margin: 'auto'}} size={50}/>
                                </> :
                                investWallet === 'ETH' || investWallet === 'BTC' ?
                                    `Общая сумма в ${investWallet}: ` + cryptoAmmount : ''
                            }

                            {errObj?.source && (
                                <div className='error'>{errObj?.source}</div>
                            )}

                        </CardBody>

                        <CardFooter>
                            {
                                isSpinner ?
                                    <div className='flex justify-center'>
                                        <Spinner size={40}/>
                                    </div> :
                                    <Button className={'flex mx-auto'} color='primary'>Купить</Button>
                            }
                        </CardFooter>
                    </form>
                </Modal>

                <Modal
                    title='Продажа INDEX'
                    onClose={handleAlertClose}
                    isOpen={sellAlertView}
                    headerStyles={{border: 'none'}}
                    footerStyles={{border: 'none', padding: '10px'}}
                    settings='alert'
                    bodyStyles={{padding: '20px'}}
                    modalStyles={{margin: 'auto'}}
                >
                    <form onSubmit={handleSubmit(handleSellIndex)}>
                        <CardHeader
                            title={'fdf'}
                        />
                        <CardBody>
                            <Input
                                label={'Количество INDEX'}
                                value={inputValue}
                                // register={register}
                                name='amountSellIndex'
                                onChange={handleSellAmountChange}
                                error={errObj?.count}
                            />
                            <div>
                                {'Доступно: ' + totalCount + ' INDEX'}
                            </div>
                        </CardBody>
                        <CardFooter>
                            {
                                isSpinner ?
                                    <div className='flex justify-center'>
                                        <Spinner size={40}/>
                                    </div> :
                                    <Button className={'flex mx-auto'} color='primary'>Продать</Button>
                            }
                        </CardFooter>
                    </form>
                </Modal>
                <Modal
                    title='Операция прошла успешно'
                    onClose={handleAlertClose}
                    isOpen={successModal}
                    headerStyles={{border: 'none'}}
                    footerStyles={{border: 'none', padding: '10px'}}
                    settings='alert'
                    bodyStyles={{padding: '0px'}}
                    modalStyles={{margin: 'auto'}}
                    footer={
                        <div className='flex justify-center'>
                            <Button onClick={handleAlertClose} color='primary'>Ок</Button>
                        </div>
                    }
                />

            </Container>
        );
    }
};
