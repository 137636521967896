import checkListPdf from '../../assets/files/check-list.pdf';
import traderDiaryPdf from '../../assets/files/trader-diary.pdf';

export const ACTIVE_LESSON = [
  {
    id: 1,
    name: 'Урок 1. Введение. Что такое трейдинг',
    video: 'https://player.vimeo.com/video/701133201?h=bb9ff60c5c',
    // des: '<b>В данном видео вы ознакомитесь со структурой обучения и получите рекомендации по его прохождению.</b>',
  },
  {
    id: 2,
    name: 'Урок 2. Обзор платформы TradingView',
    video: 'https://player.vimeo.com/video/701135436?h=a1bb06280f',
    // des: '<b>Что такое криптовалюты?</b><ul><li>Определение криптовалют.</li><li>	Технология блокчейн.</li><li>	Истории возникновения криптовалют.</li><li>	Основные участники рынка.</li><li>	Сервисы и платформы, которые помогут нам в работе с криптовалютами.</li><li>	Где и как можно купить и обменять криптовалюты.</li><li>Ссылка на наш телеграмм-бот (обменник) - <a href="https://t.me/RSI_garantBot">t.me/RSI_garantBot</a></li></ul>',
  },
  {
    id: 3,
    name: 'Урок 3. Что такое тренды и какие они бывают',
    video: 'https://player.vimeo.com/video/701136814?h=5638d54179',
    // des: '<b>Функционал биржи бинанс</b>  <ul><li>Как выгоднее купить криптовалюту</li><li>Спотовая торговля</li><li>Маржинальная торговля</li><li>Торговля фьючерсами</li><li>Стейкинг</li><li>Стратегии</li></ul>',
  },
  {
    id: 4,
    name: 'Урок 4. Разворотно-свечные модели',
    video: 'https://player.vimeo.com/video/701137658?h=2bd22fdcd7',
    // des: '<b>Стратегия торговли по тренду</b><ul><li>Уровни</li><li>Тренды</li><li>Скользящее среднее</li><li>Объёмы</li><li>Вход в сделку</li><li>Выход из сделки</li><li>Определение риска</li></ul>',
  },
  {
    id: 5,
    name: 'Урок 5. Свечные паттерны',
    video: 'https://player.vimeo.com/video/701139364?h=cdee434734',
    // des: '<b>Децентрализованные кошельки</b><ul><li>Регистрация и установка</li><li>Кошелек MetaMask</li></ul>',
  },
  {
    id: 6,
    name: 'Урок 6. Уровни поддержки и сопративления. Как с ними работать',
    video: 'https://player.vimeo.com/video/701142665?h=7cf287dff7',
    // des: '<b>Децентрализованные биржи</b><ul><li>Регистрация </li><li>Покупка токенов</li><li>Биржа Uniswap</li><li>Биржа PancakeSwap</li></ul>',
  },
  {
    id: 7,
    name: 'Урок 7. Индикаторы и работа с ними',
    video: 'https://player.vimeo.com/video/701144153?h=4c0adde0ea',
    // des: '<b>Как зарабатывать на DEX</b><ul><li>Фарминг</li><li>Стейкинг</li><li>Стратегия на бирже PancakeSwap</li></ul>',
  },
  {
    id: 8,
    name: 'Урок 8. Анализ монеты для входа в сделку',
    video: 'https://player.vimeo.com/video/701147038?h=4b2170aaef',
    // des: '<b>Технический анализ </b><ul><li>История</li><li>Основные инструменты</li><li>Применение</li></ul><br/><br/> Вашему вниманию - дневник и важный чек-лист трейдера от команды управляющих RSI. Сохраняйте себе, чтобы не потерять',
    files: [
      {
        name: 'Чек-лист трейдера',
        path: checkListPdf,
      },
      {
        name: 'Дневник трейдера',
        path: traderDiaryPdf,
      },
    ],
  },
  {
    id: 9,
    name: 'Урок 9. Спотовая торговля, анализ монет',
    video: 'https://player.vimeo.com/video/701148803?h=a6a7cddddc',
    // des: '<b>Риск-менеджмент</b><br/><ul><li>Расчёт позиции для сделки</li><li>Риск</li><li>Соотношение риск/прибыль</li></ul>',
  },
  {
    id: 10,
    name: ' Урок 10. Что такое деривативы',
    video: 'https://player.vimeo.com/video/701149739?h=ee383907be',
    des: '',
  },
  {
    id: 11,
    name: 'Урок 11. Фьючерсы и их виды',
    video: 'https://player.vimeo.com/video/701150404?h=bee9abc79d',
    des: '',
  },
  {
    id: 12,
    name: 'Урок 12. Опционы. Как открыть сделку',
    video: 'https://player.vimeo.com/video/701151047?h=3677f884e3',
    des: '',
  },
  {
    id: 13,
    name: 'Урок 13. Простые опционные конструкции',
    video: 'https://player.vimeo.com/video/701151641?h=f2785ff4d0',
    des: '',
  },
];