import { useEffect, useState } from 'react';
import { isTouchDevice } from '../helpers/isTouchDevice';

export const useIsTouchDevice = () => {
  const [isTouch, setIsTouch] = useState(isTouchDevice());

  const listener = () => {
    setIsTouch(isTouchDevice());
  };

  useEffect(() => {
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [window.innerWidth]);

  return isTouch;
};
